import { JSON_HEADERS } from './constants'
import { PRELOGIN_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { logger } from '@dtx-company/logger'

export async function prelogin({
  email
}: {
  email: string
}): Promise<{ isSSODomain: boolean; address?: string; idpType?: string } | void> {
  try {
    const resp = await fetch(PRELOGIN_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        email
      }),
      headers: JSON_HEADERS
    })

    if (resp.status === 204) {
      // No associated account found, Frontegg normally tries to respond w/ 404
      // But 404 is an error code and is polluting logs
      // Moved to correct code, request was successful but no specific entry found
    } else if (resp.ok) {
      const data = await resp.json()
      return { isSSODomain: true, ...data }
    } else {
      await logger.logFetchError(resp, { technicalArea: 'authentication' })
      return { isSSODomain: false }
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
    //frontegg prelogin will throw if email is not SSO Enabled
    return { isSSODomain: false }
  }
}
