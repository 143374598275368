import { getGqlError } from '../utils/errors'
import { updateFlowcode } from '../utils/flowcode'

export const updateFlowcodeName = async ({
  initialValue,
  updatedNickname,
  batchId,
  token,
  onUpdateSuccess,
  onUpdateError
}: {
  initialValue: string
  updatedNickname: string
  batchId: string
  token?: string
  onUpdateSuccess?: () => void
  onUpdateError?: (errorMessage: string) => void
}): Promise<any> => {
  if (updatedNickname !== initialValue) {
    try {
      const updateFlowcodeData = await updateFlowcode(
        {
          batchId,
          batchInput: {
            nickname: updatedNickname
          },
          linkInput: {} // linkInput should be empty because we are only changing the nickname
        },
        token
      )
      if (!updateFlowcodeData.updateBatchLink.ok) throw new Error('Error updating Flowcode')
      onUpdateSuccess?.()
    } catch (e) {
      console.error('error updating flowcode name', e.message)
      const { message } = getGqlError(e, false)
      onUpdateError?.(message)
    }
  }
}
