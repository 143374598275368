import { Box } from '../Box/Box'
import { Button, ButtonProps } from '../Button/Button'
import { ErrorOutline, LightbulbOutlined, WarningAmber } from '@mui/icons-material'
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import { IconComponent } from '../../types/util'
import { Stack } from '../Stack/Stack'
import { Typography } from '@mui/material'

export interface BannerProps {
  color?: 'error' | 'info' | 'warning'
  title?: string | ReactNode
  description?: string | ReactNode
  link?: ReactNode
  icon?: IconComponent
  alignContent?: 'left' | 'center' //it will only be respected if there is no buttonText
  buttonProps?: Pick<
    ButtonProps,
    'onClick' | 'disabled' | 'loading' | 'ref' | 'component' | 'startIcon' | 'endIcon' | 'variant'
  >
  buttonText?: string
  dataTestId?: string
}

interface BannerStyles {
  color: 'error' | 'info' | 'warning'
  icon: IconComponent
  backgroundColor: string
}

const DescriptionLinkComponent: FC<Pick<BannerProps, 'link' | 'description'>> = ({
  link,
  description
}) => {
  return (
    <Typography variant="bodySmall" display="block" component="span">
      {description && <>{description}&nbsp;</>}
      {link && (
        <Box component="span" sx={{ textDecoration: 'underline' }}>
          {link}
        </Box>
      )}
    </Typography>
  )
}

export const Banner: FC<BannerProps> = ({
  title,
  description,
  link,
  alignContent = 'left',
  color,
  icon,
  buttonProps,
  buttonText,
  dataTestId = 'banner'
}) => {
  const bannerStyles: Readonly<BannerStyles> = useMemo(() => {
    switch (color) {
      case 'error':
        return {
          color: 'error',
          icon: ErrorOutline,
          backgroundColor: 'container.error'
        }
      case 'warning':
        return {
          color: 'warning',
          icon: WarningAmber,
          backgroundColor: 'container.warning'
        }
      default:
        return {
          color: 'info',
          icon: LightbulbOutlined,
          backgroundColor: 'container.info'
        }
    }
  }, [color])

  const BannerIcon = icon ?? bannerStyles.icon
  const alignBannerContents = buttonText ? 'left' : alignContent
  const textCtrRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const textBreakpoint = alignBannerContents === 'center' ? 28 : 24

  useEffect(() => {
    if (textCtrRef.current && titleRef.current && title) {
      const handleResize = (): void => {
        if (textCtrRef.current && titleRef.current) {
          titleRef.current.style.marginBottom =
            textCtrRef.current.clientHeight > textBreakpoint ? '0.25rem' : '0rem'
        }
      }

      window.addEventListener('resize', handleResize)

      handleResize()
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [textBreakpoint, textCtrRef?.current?.clientHeight, title])

  return (
    <Stack
      sx={theme => ({
        backgroundColor: bannerStyles.backgroundColor,
        padding: buttonText ? { xs: 4, md: theme.spacing(2.5, 4) } : 4,
        minHeight: theme.spacing(12),
        alignItems: 'start',
        justifyContent: alignBannerContents,
        flexDirection: 'row',
        borderRadius: theme.spacing(1),
        maxWidth: '100%'
      })}
      data-testid={dataTestId}
    >
      {alignBannerContents === 'left' && (
        <BannerIcon
          color={bannerStyles.color}
          sx={theme => ({
            mr: 2,
            height: theme.spacing(6),
            width: theme.spacing(6)
          })}
          role="presentation"
        />
      )}
      <Stack
        sx={{
          alignItems: { xs: 'flex-start', md: 'center' },
          justifyContent: { xs: 'left', md: 'space-between' },
          width: buttonText ? '100%' : 'max-content',
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        <Box>
          {(title || description || link) && (
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: alignBannerContents,
                textAlign: alignBannerContents,
                '& > h3': {
                  textWrap: 'wrap'
                }
              }}
              ref={textCtrRef}
            >
              <Typography
                fontWeight={600}
                variant="bodySmall"
                component={'h3'}
                lineHeight={1.25}
                display="inline-flex"
                ref={titleRef}
              >
                {alignBannerContents === 'center' ? (
                  <Box
                    component={'span'}
                    sx={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      flexWrap: 'wrap'
                    }}
                  >
                    <BannerIcon
                      color={bannerStyles.color}
                      sx={theme => ({
                        mr: 2,
                        height: theme.spacing(6),
                        width: theme.spacing(6)
                      })}
                      role="presentation"
                    />
                    {title && <>{title}&nbsp;</>}
                  </Box>
                ) : (
                  <>{title && <>{title}&nbsp;</>}</>
                )}
              </Typography>
              <Box
                component="p"
                sx={{
                  display: 'inline-block',
                  margin: 0,
                  lineHeight: 1.25
                }}
              >
                {(description || link) && (
                  <DescriptionLinkComponent link={link} description={description} />
                )}
              </Box>
            </Stack>
          )}
        </Box>
        {buttonText && (
          <Box sx={{ ml: { md: 3 }, mt: { xs: 3, md: 0 } }}>
            <Button size="small" {...buttonProps}>
              {buttonText}
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

Banner.displayName = 'Banner'
