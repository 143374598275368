import { ElementType, forwardRef } from 'react'
import { default as MuiLink, LinkProps as MuiLinkProps } from '@mui/material/Link'

export type LinkProps = Pick<
  MuiLinkProps,
  | 'children'
  | 'href'
  | 'ref'
  | 'onClick'
  | 'sx'
  | 'target'
  | 'underline'
  | 'variant'
  | 'aria-current'
  | 'rel'
> & {
  component?: ElementType
}

/**
 * The Link component allows you to easily customize anchor elements
 * with your theme colors and typography styles.
 */

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return (
    <MuiLink
      {...props}
      sx={theme => ({
        cursor: 'pointer',
        color: theme.palette.tertiary.main,
        textDecorationColor: theme.palette.tertiary.main
      })}
      ref={ref}
    />
  )
})
Link.displayName = 'Link'
