import {
  DEFAULT_FLOWCODE_DESTINATION,
  DEFAULT_FLOWCODE_LOGO_DIMENSIONS,
  DEFAULT_LOGO_OUTER_PADDING
} from '@app/code/src/constants/flowcode'
import { DEFAULT_FLOWCODE_OPTIONS } from '../LoggedOutCodeGenerator.constants'
import { FcGeneratorOptions, FcShape } from '@dtx-company/flowcode-generator-browser'
import { createContainerText } from '../GeneratorSections/CodeDesign/FrameAndText/utils/createContainerText'
import { getFlowcodeLogoDimensions } from '@app/code/src/utils/flowcode'
import { getFrameOptions } from '../GeneratorSections/CodeDesign/FrameAndText/utils/getFrameOptions'
import { getSquareShapeOptions } from '@app/code/src/containers/create-flowcode-fullpage/utils/getSquareShapeOptions'
import { logger } from '@dtx-company/logger'
import {
  selectAutogeneratedOptions,
  selectBottomRimText,
  selectColor,
  selectFrame,
  selectLogo,
  selectShape,
  selectTopRimText
} from '@app/code/src/machines/locg/locgSelectors'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { useEffect, useMemo, useState } from 'react'
import { useGlobalServices } from '@app/common/src/hooks/useGlobalServices'
import { useSelector } from '@xstate/react'
import useTranslation from 'next-translate/useTranslation'

const DEFAULT_CENTER_IMAGE_DATA = {
  previewUrl: '',
  height: DEFAULT_FLOWCODE_LOGO_DIMENSIONS.HEIGHT,
  width: DEFAULT_FLOWCODE_LOGO_DIMENSIONS.WIDTH
}

export const useFlowcodeOptions = (): FcGeneratorOptions => {
  const { locgService } = useGlobalServices()
  const selectedShape = useSelector(locgService, selectShape)
  const selectedFrame = useSelector(locgService, selectFrame)
  const topRimText = useSelector(locgService, selectTopRimText)
  const bottomRimText = useSelector(locgService, selectBottomRimText)
  const selectedColor = useSelector(locgService, selectColor)?.color
  const logo = useSelector(locgService, selectLogo)
  const autogeneratedOptions = useSelector(locgService, selectAutogeneratedOptions)
  const [centerImageData, setCenterImageData] = useState(DEFAULT_CENTER_IMAGE_DATA)
  const { t } = useTranslation('logged-out-generator')

  useEffect(() => {
    if (logo && logo instanceof File) {
      getFlowcodeLogoDimensions(logo)
        .then(data => {
          setCenterImageData({
            ...data,
            previewUrl: URL.createObjectURL(logo)
          })
        })
        .catch(err => {
          sendErrorNotification(t('LoggedOutGenerator.files.errors.logo'))
          logger.logError(err, {
            team: 'templates'
          })
        })
    } else {
      setCenterImageData(DEFAULT_CENTER_IMAGE_DATA)
    }
  }, [logo, t])

  return useMemo(() => {
    return autogeneratedOptions
      ? autogeneratedOptions
      : {
          ...DEFAULT_FLOWCODE_OPTIONS,
          // apply color
          defaultColor: selectedColor,
          containerOuterPatternColor: selectedColor,
          containerBorderColor: selectedColor,
          gridModuleColor: selectedColor,
          ...(selectedFrame &&
            getFrameOptions(topRimText, bottomRimText, selectedColor, selectedShape)),
          ...(selectedShape === FcShape.SQUARE && !selectedFrame && getSquareShapeOptions('')),
          ...(centerImageData.previewUrl && {
            logoImageUrl: centerImageData.previewUrl,
            logoHeight: centerImageData.height,
            logoWidth: centerImageData.width,
            logoOuterPadding: DEFAULT_LOGO_OUTER_PADDING
          }),
          data: DEFAULT_FLOWCODE_DESTINATION,
          containerText: createContainerText(selectedShape, selectedFrame, selectedColor),
          positionElementTopRight: {
            innerShapeColor: selectedColor
          },
          positionElementTopLeft: {
            innerShapeColor: selectedColor
          },
          positionElementBottomLeft: {
            innerShapeColor: selectedColor
          }
        }
  }, [
    selectedColor,
    selectedFrame,
    topRimText,
    bottomRimText,
    selectedShape,
    centerImageData.previewUrl,
    centerImageData.height,
    centerImageData.width,
    autogeneratedOptions
  ])
}
