// NOTE: app config - use joi to verify if necessary
import { AppEnvironmentType, Config, ConfigEnvironments } from '../types/config'
import {
  FLOWCODE_API_ENDPOINT,
  FLOWPAGE_API_ENDPOINT,
  SUBSCRIPTION_SERVICE_ENDPOINT
} from './endpoints'
import { FLOWCODE_ROOT } from './root'

const flowcodeRoot = FLOWCODE_ROOT

const configEnvironments: ConfigEnvironments = {
  development: {
    flowcodeRoot,
    uri: FLOWCODE_API_ENDPOINT, // for FC API
    uriFlowpage: FLOWPAGE_API_ENDPOINT,
    uriGenerator: 'https://stg-generator.flowcode.com',
    subscriptionServiceUri: SUBSCRIPTION_SERVICE_ENDPOINT,
    optimizelyAPIKey: 'WhH8n8d7bSW464SXepggc8',
    templateIds: {
      POSTER: '286d38ba-9887-4dc5-bc18-276b485280a1',
      POSTCARD: '85219a4e-9a48-461d-9f1e-67d7c26f0298',
      STICKER: 'e87d704b-2fc1-4bb0-a3b6-0b84c4bf51f1',
      TENT_5_BY_7: 'd0e425a2-9f32-42e3-bfb0-ab1e329a7c6f'
    },
    http: {
      keepAlive: true,
      keepAliveMsecs: 10000
    }
  },
  staging: {
    uri: FLOWCODE_API_ENDPOINT, // for FC API
    uriFlowpage: FLOWPAGE_API_ENDPOINT,
    uriGenerator: 'https://stg-generator.flowcode.com',
    flowcodeRoot,
    subscriptionServiceUri: SUBSCRIPTION_SERVICE_ENDPOINT,
    optimizelyAPIKey: 'WhH8n8d7bSW464SXepggc8',
    templateIds: {
      POSTER: '286d38ba-9887-4dc5-bc18-276b485280a1',
      POSTCARD: '85219a4e-9a48-461d-9f1e-67d7c26f0298',
      STICKER: 'e87d704b-2fc1-4bb0-a3b6-0b84c4bf51f1',
      TENT_5_BY_7: 'd0e425a2-9f32-42e3-bfb0-ab1e329a7c6f'
    },
    http: {
      keepAlive: true,
      keepAliveMsecs: 10000
    }
  },
  preprod: {
    uri: FLOWCODE_API_ENDPOINT,
    uriFlowpage: FLOWPAGE_API_ENDPOINT,
    uriGenerator: 'https://preprod-generator.flowcode.com',
    flowcodeRoot,
    subscriptionServiceUri: SUBSCRIPTION_SERVICE_ENDPOINT,
    optimizelyAPIKey: 'BrDFm3451Hi7xQRrcT6Pb',
    templateIds: {
      POSTER: '286d38ba-9887-4dc5-bc18-276b485280a1',
      POSTCARD: '85219a4e-9a48-461d-9f1e-67d7c26f0298',
      STICKER: 'e87d704b-2fc1-4bb0-a3b6-0b84c4bf51f1',
      TENT_5_BY_7: 'd0e425a2-9f32-42e3-bfb0-ab1e329a7c6f'
    },
    http: {
      keepAlive: true,
      keepAliveMsecs: 10000
    }
  },
  production: {
    uri: FLOWCODE_API_ENDPOINT,
    uriFlowpage: FLOWPAGE_API_ENDPOINT,
    uriGenerator: 'https://generator.flowcode.com',
    flowcodeRoot,
    subscriptionServiceUri: SUBSCRIPTION_SERVICE_ENDPOINT,
    optimizelyAPIKey: '6aHXzmMT8eypKwwTfh623M',
    templateIds: {
      POSTER: '496a5bdf-10ff-4943-a775-0573e9bd94d3',
      POSTCARD: '71e0cdd3-83a6-4104-a381-ac24bd4039eb',
      STICKER: '1380d87b-2163-426d-a02d-beef0e60765d',
      TENT_5_BY_7: 'c1cb9232-89d7-4187-8bd4-72ae1e4bdc4e'
    },
    http: {
      keepAlive: true,
      keepAliveMsecs: 10000
    }
  }
}

const env = (process.env.APP_ENV || 'development') as AppEnvironmentType
const configuration: Config = {
  ...configEnvironments[env]
}
export default configuration
