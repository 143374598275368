export const ASSIGN_STUDIO_CONFIG = /* GraphQL */ `
  mutation assignStudioConfiguration($configId: UUID!) {
    assignStudioConfiguration(configId: $configId) {
      ok
    }
  }
`
export const CREATE_STUDIO_CONFIG_MUTATION = /* GraphQL */ `
  mutation createStudioConfig($configuration: JSONString, $name: String, $source: SourceType) {
    createStudioConfig(configuration: $configuration, name: $name, source: $source) {
      ok
      studioConfig {
        configuration
        id
      }
    }
  }
`
