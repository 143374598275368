import { EventErrorsType, GetLandingPageDestinationType } from '../types/flowcode.types'
import {
  EventType,
  FileDestinations,
  LandingPageDestination,
  LandingPageFormValuesType
} from '@dtx-company/inter-app/src/types/flowcode'
import {
  FACEBOOK_PATTERNS,
  FB_DEEP_LINK_PATTERN,
  GOOGLE_DEEP_LINK_PATTERN,
  GOOGLE_DOCS_PATTERN,
  HTTPS_PATTERN,
  HTTP_PATTERN,
  INSTAGRAM_DEEP_LINK_PATTERN,
  INSTAGRAM_PATTERNS,
  LINKEDIN_DEEP_LINK_PATTERN,
  LINKEDIN_PATTERNS,
  PINTEREST_DEEP_LINK_PATTERN,
  SNAPCHAT_DEEP_LINK_PATTERN,
  TUMBLR_DEEP_LINK_PATTERN,
  TWITTER_DEEP_LINK_PATTERN,
  TWITTER_PATTERNS,
  VENMO_DEEP_LINK_PATTERN,
  YELP_DEEP_LINK_PATTERN,
  YOUTUBE_DEEP_LINK_PATTERN,
  YOUTUBE_PATTERNS
} from '../constants/patterns'
import { SOCIAL_MEDIA_DESTINATIONS } from '../constants/landing-page'
import {
  WiFi,
  constructEmailLink,
  constructPhoneLink,
  constructSMSLink,
  constructVenmoLink,
  constructWiFiLink,
  isEmail,
  isPhone,
  isSMS,
  isVenmo,
  isWiFi
} from '@dtx-company/flowcode-utils/src'
import { getVcardApiRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { isAfter } from 'date-fns'
import { isValidRestrictedURLForClient } from '../components/utils/landing-page'
import { isValidUrl } from '@dtx-company/inter-app/src/utils/isValidUrl/isValidUrl'
import { vcardHasValidProperties } from '../components/landing-page/destination-form/VCardDestinationForm/utils'

// TODO: pull all types from utils once updated

const validateEvent = (event: EventType): [boolean, EventErrorsType] => {
  const errors: EventErrorsType = {}

  if (!event.name) errors.name = 'Name is required'
  if (!event.location)
    // TODO: gmaps integration?
    errors.location = 'Location is required'
  if (event.url && !isValidUrl(event.url)) errors.url = 'Url is invalid'
  if (!event.startTime) errors.startTime = 'Start time is required'
  if (!event.endTime) errors.endTime = 'End time is required'
  else if (!isAfter(new Date(event.endTime), new Date(event.startTime)))
    errors.endTime = 'End time should be greater than the start time'
  const isValid = !Object.values(errors).length
  return [isValid, errors]
}

export const isDeepLink = (url: string): boolean => {
  if (
    FB_DEEP_LINK_PATTERN.test(url) ||
    TWITTER_DEEP_LINK_PATTERN.test(url) ||
    GOOGLE_DEEP_LINK_PATTERN.test(url) ||
    PINTEREST_DEEP_LINK_PATTERN.test(url) ||
    INSTAGRAM_DEEP_LINK_PATTERN.test(url) ||
    YOUTUBE_DEEP_LINK_PATTERN.test(url) ||
    YELP_DEEP_LINK_PATTERN.test(url) ||
    LINKEDIN_DEEP_LINK_PATTERN.test(url) ||
    SNAPCHAT_DEEP_LINK_PATTERN.test(url) ||
    TUMBLR_DEEP_LINK_PATTERN.test(url) ||
    VENMO_DEEP_LINK_PATTERN.test(url)
  ) {
    return true
  }

  return false
}

// find a better name
export const isLandingPageValid = (
  id: LandingPageDestination,
  values: LandingPageFormValuesType,
  restrictedLinks?: string[]
): boolean => {
  let url = values.link

  if (id in FileDestinations) {
    return !!values.file && values.file instanceof File && values.file.size > 0
  }

  if (id === LandingPageDestination.EVENT) {
    return validateEvent(values.event)[0]
  }

  if (id === LandingPageDestination.VENMO) {
    return isVenmo({
      ...values.venmo,
      recipients: values?.venmo?.recipients?.replace(/@/gi, ''),
      amount: Number(values.venmo.amount) || undefined
    })
  }

  if (id === LandingPageDestination.SMS) {
    return isSMS(values.sms)
  }

  if (id === LandingPageDestination.PHONE) {
    return isPhone({
      phoneNumber: values.phone
    })
  }

  if (id === LandingPageDestination.WIFI) {
    const v = {
      ...values.wifi,
      hidden: values.wifi ? 'true' : undefined
    }
    return isWiFi(v as WiFi)
  }

  if (id === LandingPageDestination.EMAIL) {
    const emails = values.email?.email?.split(',')
    return emails
      ? emails.every(e =>
          isEmail({ email: e.trim(), subject: values.email.subject, body: values.email.body })
        )
      : false
  }

  if (id === LandingPageDestination.SCAN_TO_OWN) {
    return true
  }

  if (id === LandingPageDestination.GOOGLE_DOC) {
    return GOOGLE_DOCS_PATTERN.test(values.link)
  }

  if (id === LandingPageDestination.LINKEDIN) {
    return LINKEDIN_PATTERNS.some(pattern => pattern.test(values.link))
  }

  if (id === LandingPageDestination.FACEBOOK) {
    return FACEBOOK_PATTERNS.some(pattern => pattern.test(values.link))
  }

  if (id === LandingPageDestination.YOUTUBE) {
    return YOUTUBE_PATTERNS.some(pattern => pattern.test(values.link))
  }

  const social = SOCIAL_MEDIA_DESTINATIONS.find(({ id: cur }) => cur === values.subtypeId)
  if (social?.prefix) {
    if (!url) return false
    url = social.prefix + url
  }

  if (id === LandingPageDestination.INSTAGRAM) {
    return INSTAGRAM_PATTERNS.some(pattern => pattern.test(url))
  }
  if (id === LandingPageDestination.TWITTER) {
    return TWITTER_PATTERNS.some(pattern => pattern.test(url))
  }
  if (!!url && restrictedLinks) {
    return (
      isValidRestrictedURLForClient(url, restrictedLinks) && (isValidUrl(url) || isDeepLink(url))
    )
  }
  if (id === LandingPageDestination.VCARD) {
    return vcardHasValidProperties(values.vcard)
  }
  return !!url && (isValidUrl(url) || isDeepLink(url))
}

export const addProtocolToLink = (url: string): string => {
  if (HTTPS_PATTERN.test(url)) {
    return url.replace(HTTPS_PATTERN, 'https://')
  } else if (HTTP_PATTERN.test(url)) {
    return url.replace(HTTP_PATTERN, 'http://')
  }

  return `https://${url}`
}

export const getLandingPageDestination = ({
  type,
  values
}: GetLandingPageDestinationType): string | File => {
  if (type in FileDestinations) return values.file || ''
  if (type === LandingPageDestination.SMS) return constructSMSLink(values.sms)
  if (type === LandingPageDestination.PHONE)
    return constructPhoneLink({ phoneNumber: values.phone })
  if (type === LandingPageDestination.EMAIL) {
    const emailString = values.email.email
      .split(',')
      .map(e => e.trim())
      .join(',')
    return constructEmailLink({
      email: emailString,
      subject: values.email.subject,
      body: values.email.body
    })
  }
  if (type === LandingPageDestination.VENMO)
    return constructVenmoLink({
      ...values.venmo,
      recipients: values?.venmo?.recipients?.replace(/@/gi, '')
    })
  // TODO: remove event - DF
  if (type === LandingPageDestination.EVENT) {
    return ''
  }
  if (type === LandingPageDestination.WIFI) {
    const v = {
      ...values.wifi,
      hidden: values.wifi ? 'true' : ''
    }
    return constructWiFiLink(v as WiFi)
  }
  if (values.subtypeId) {
    const social = SOCIAL_MEDIA_DESTINATIONS.find(({ id: cur }) => cur === values.subtypeId)
    const handle = values.link.includes('.com/') ? values.link.split('.com/')[1] : values.link
    if (social?.prefix) {
      return social.prefix + handle
    }
  }
  if (isDeepLink(values.link)) return values.link
  if (type === LandingPageDestination.SCAN_TO_OWN) return ''
  if (type === LandingPageDestination.VCARD) {
    return `${getVcardApiRootUrl()}/api/v1/card/${values?.vcard?.id}`
  }
  return addProtocolToLink(values.link)
}
