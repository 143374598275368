import { Plan, PlanExtra, PlanExtraType } from '@dtx-company/true-common/src/types/Plan'
import { PlanInterval } from '@dtx-company/true-common/src/types/PlanInterval'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { SubscriptionServiceGetPlansJSON } from './types/jsons/SubscriptionServiceGetPlansJSON'
import { SubscriptionServicePlan } from './types/models/SubscriptionServicePlan'
import { SubscriptionServicePlanExtra } from './types/models/SubscriptionServicePlanExtra'
import { fetchSubscriptionServiceUrl } from './fetchSubscriptionServiceUrl'

const GET_PLANS_URL = '/v2/plans'

class InvalidPlanTypeError extends Error {}
class InvalidPlanIntervalError extends Error {}
class InvalidPlanExtraTypeError extends Error {}

export interface GetPlansOptionsFilter {
  groupEq?: string
  planId?: string[]
}

export interface GetPlansOptions {
  filter?: GetPlansOptionsFilter
}

function toInterval(jsonInterval: SubscriptionServicePlan['interval']): PlanInterval | undefined {
  if (!jsonInterval) return undefined
  switch (jsonInterval) {
    case 'MONTHLY':
      return PlanInterval.MONTHLY
    case 'YEARLY':
      return PlanInterval.YEARLY
  }
  throw new InvalidPlanIntervalError()
}

function toPlanType(jsonPlanType: SubscriptionServicePlan['type']): PlanType {
  switch (jsonPlanType) {
    case 'BASIC':
      // NOTE: we historically have had a "FREE" plan already (flowcode) that technically
      // is a PRO plan but marked as FREE so we're using "BASIC" as the indicator on the
      // BE to indicate the plan that users on a reverse trial "subscribe to" in order to
      // go back to the default free plan.
      return PlanType.FREE
    case 'PRO':
      return PlanType.PRO
    case 'PRO_PLUS_ORG':
      return PlanType.PRO_PLUS_ORG
    case 'PRO_FLEX':
      return PlanType.PRO_FLEX
    case 'PILOT':
      return PlanType.PILOT
  }
  throw new InvalidPlanTypeError()
}

function toLevel(jsonPlanLevel: SubscriptionServicePlan['planLevel']): Plan['planLevel'] {
  // API currently sets -1 internally, it plans to update to return null
  if (jsonPlanLevel === -1) return undefined
  // keep 0, convert null to undefined
  return jsonPlanLevel ?? undefined
}

function toPlanExtraType(
  jsonPlanExtraType: SubscriptionServicePlanExtra['planExtraType']
): PlanExtraType {
  switch (jsonPlanExtraType) {
    case 'codeapi':
      return 'codeapi'
    case 'seats':
      return 'seats'
  }
  throw new InvalidPlanExtraTypeError()
}

function toPlanExtra(jsonPlanExtra: SubscriptionServicePlanExtra): PlanExtra {
  return {
    ...jsonPlanExtra,
    planExtraType: toPlanExtraType(jsonPlanExtra.planExtraType)
  }
}

export function migrateSubscriptionPlan(jsonPlan: SubscriptionServicePlan): Plan {
  return {
    id: jsonPlan.planId,
    type: toPlanType(jsonPlan.type),
    interval: toInterval(jsonPlan.interval),
    group: jsonPlan.pricingGroup || undefined,
    planLevel: toLevel(jsonPlan.planLevel),
    autoEnroll: jsonPlan.autoEnroll || false,
    priceInCents: jsonPlan.priceInCents ?? undefined,
    startingPriceInCents: jsonPlan.startingPriceInCents ?? undefined,
    discountPercent: jsonPlan.discountPercent ?? undefined,
    discountAmountInCents: jsonPlan.discountAmountInCents ?? undefined,
    complementaryPlanId: jsonPlan.complementaryPlanId || undefined,
    planExtras: jsonPlan.planExtras.map(jsonPlanExtra => toPlanExtra(jsonPlanExtra))
  }
}

/**
 * Calls the subscription service GET /v2/plans endpoint.
 *
 * May be used as a fetcher for useSWR or by itself for SSR.
 *
 * @docs https://backstage.use1.dev.services.flowcode.systems/catalog/default/api/subscription-service-rest-api/definition#/Plans/get_v2_plans
 */

export async function getPlans(options: GetPlansOptions): Promise<Plan[]> {
  const response = await fetchSubscriptionServiceUrl<SubscriptionServiceGetPlansJSON>({
    url: GET_PLANS_URL,
    query: {
      planId: options?.filter?.planId,
      pricingGroup: options?.filter?.groupEq
    }
  })
  if (!response.ok) {
    throw new Error(`Received http status ${response.status} from ${GET_PLANS_URL} endpoint`)
  }
  if (!response.json) {
    throw new Error(`The ${GET_PLANS_URL} endpoint did not return a json payload`)
  }

  if (response.json.message !== 'success-retrieval') {
    throw new Error(`The ${GET_PLANS_URL} endpoint did not return a "success-retrieval"`)
  }

  const plans = response.json.plans
  if (!Array.isArray(plans)) {
    throw new Error(`The ${GET_PLANS_URL} endpoint failed to return a .plans property array.`)
  }

  return plans
    .filter(jsonPlan => {
      if (jsonPlan.planId === 'flowcode') {
        // This plan still exists on the BE but is not used by the FE.
        // Historically we've ignored this by ignoring its plan type, but adding an explicit
        // ignore for clarity until we can move users off it and remove it.
        return false
      }
      return true
    })
    .reduce<Plan[]>((newPlans, jsonPlan) => {
      try {
        const newPlan = migrateSubscriptionPlan(jsonPlan)
        newPlans.push(newPlan)
      } catch (error) {
        // For plans, we have some old historical data that's never used anymore but still exists
        // and doesn't parse well.
        // We might also add new plan types or intervals or other bits of data that the FE doesn't
        // support yet.
        // For both of these cases, log the issue to raise visibility but allow the app to continue
        // with the plans it does know about.
        if (error instanceof InvalidPlanTypeError) {
          // Stop logging the flowcode plan warning, unless filtering to it
          if (!options.filter?.planId && jsonPlan.planId === 'flowcode') return newPlans
          console.warn(
            `Unable to convert ${jsonPlan.type} to a known plan type for plan ${jsonPlan.planId}, skipping...`
          )
        } else if (error instanceof InvalidPlanIntervalError) {
          console.warn(
            `Unable to convert ${jsonPlan.interval} to a known plan interval for plan ${jsonPlan.planId}, skipping...`
          )
        } else {
          throw error
        }
      }
      return newPlans
    }, [])
}

getPlans.swrKey = 'getPlans'
