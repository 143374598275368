import { OnSubmitType } from '@dtx-company/inter-app/src/components/Authentication/AuthenticationForm.types'
import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import { User } from '../types/user.types'
import { checkUserHasReachedMaxPages } from '@dtx-company/inter-app/src/redux/slices/utils/pageLimit'
import { useCallback } from 'react'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useDispatch } from 'react-redux'
import events from '@dtx-company/inter-app/src/event-tracking/events/account'

export const useHandleSSOSubmit = (isSignUp?: boolean): OnSubmitType => {
  const curPage = useCurrentPage()
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async (user: User, __: SocialProvider, _flowpageSlugInput?: string): Promise<void> => {
      if (user) {
        if (curPage) {
          if (isSignUp) {
            events.userCreatedFlowpageAccount(user?.email || '')
          } else {
            await dispatch(checkUserHasReachedMaxPages())
          }
        }
      }
    },
    [curPage, isSignUp, dispatch]
  )

  return onSubmit
}
