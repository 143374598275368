import { updateFlowcodeName } from '../../../../../../hooks/updateFlowcodeName'

interface HandleSingleNameCollisionArgs {
  batchId: string
  assetName: string
  onUpdateSuccess?: () => void
  onUpdateError?: (errorMessage: string) => void
}

export const NAME_COLLISION_ERROR =
  'A flowcode with this nickname already exists within this folder.'

export const handleSingleNameCollision = async ({
  batchId,
  assetName,
  onUpdateSuccess,
  onUpdateError
}: HandleSingleNameCollisionArgs): Promise<void> => {
  await updateFlowcodeName({
    initialValue: assetName,
    updatedNickname: `${assetName} (${batchId})`,
    batchId,
    onUpdateSuccess,
    onUpdateError
  })
}
