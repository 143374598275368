import { FC, PropsWithChildren, createContext, useContext } from 'react'
import { SnackbarContextType } from './Snackbar.types'

/**
 * Context for {@link Snackbar} to provide access to the {@link SnackbarContext} to
 * customizable {@link NotificationToast} components.
 */
export const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined)

/**
 * Provider for {@link SnackbarContext}. Used within {@link Snackbar} to provide access
 * to the {@link SnackbarContext} to {@link NotificationToast} components.
 */
export const SnackbarProvider: FC<PropsWithChildren<SnackbarContextType>> = ({
  handleClose,
  children
}) => <SnackbarContext.Provider value={{ handleClose }}>{children}</SnackbarContext.Provider>

/**
 * Hook to access the {@link SnackbarContext}. Used by {@link NotificationToast} to allow
 * custom notifications to be closed by the {@link Snackbar} component.
 */
export const useSnackbarContext = (): SnackbarContextType => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbarContext must be used within a SnackbarProvider')
  }
  return context
}
