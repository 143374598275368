import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-types/src'
import { getFlowcodeUrl } from './getFlowcodeUrl'

export const generateFlowcode = async (
  flowcodeOptions: FcGeneratorOptions,
  dpi: string,
  targetFileType: string
): Promise<Response> => {
  const url = getFlowcodeUrl(flowcodeOptions, targetFileType, dpi)
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  })
}
