import {
  DownloadModalFileType,
  FlowcodeDownloadOptionMimeType
} from '@dtx-company/inter-app/src/types/flowcode'

export const DOWNLOAD_FILE_TYPES: Readonly<DownloadModalFileType[]> = [
  {
    value: FlowcodeDownloadOptionMimeType.PNG,
    label: 'High quality image, great for print and digital'
  },
  {
    value: FlowcodeDownloadOptionMimeType.JPG,
    label: "Small file size, doesn't support transparency"
  },
  {
    value: FlowcodeDownloadOptionMimeType.SVG,
    label: 'Sharp vector graphics at any size, great for sharing with vendors'
  }
]

export const PREMIUM_DOWNLOAD_FILE_TYPES: Readonly<DownloadModalFileType[]> = [
  {
    value: FlowcodeDownloadOptionMimeType.PDF,
    label: 'High quality document file, can be used for email and print'
  }
]

export const DOWNLOAD_BEST_PRACTICES_PDF_EXPERIENCE = 'download-best-practice-pdf'

export const BEST_PRACTICES_PDF_PATH = '/graphics/best-practices-pdf/flowcode_best_practices.pdf'
