import useSWR, { SWRConfiguration, SWRHook, useSWRConfig } from 'swr'

/**
 * Wraps useSWR.
 * This used to do key-tracking to enable a full cache clear but no longer needs to,
 * can still be expanded in the future
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSWRPlus: SWRHook = (...args: any) => {
  // This does "any" casting in order to appease typescript and strictly not be
  // opinionated of the types if SWR updates - so long as the first arg stays
  // roughly the same as a key arg, nothing else is opinionated about the
  // number of arguments or their types or the response - it'll all
  // match SWRHook
  // A typed approach would be (Parameters<typeof useSWR>) => ReturnType<typoef useSWR>
  // but at this time those are not able to rectify the 8 different signatures
  // that useSWR has and just picks the params and return type of one of them
  // and then complains that things don't match that particular signature.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const swr = (useSWR as any)(...args)
  return swr
}

/**
 * Wraps useSWRConfig with an additional clearCache method that will clear
 * any tracked keys from using useSWRPlus from the internal cache.
 */
export function useSWRPlusConfig(): SWRConfiguration & { clearCache: () => Promise<void> } {
  const swrConfig = useSWRConfig()
  return {
    ...swrConfig,
    async clearCache() {
      // https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
      // The clear method is not part of the Cache type, but it is documented
      // in various places and does exist on the internal implementation
      // so needs to be cast to any in order to be used.
      // The implementation may not need to be async but making it as such cause
      // a past implementation was and may be again in the future.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await swrConfig.mutate(
        () => true, // which cache keys are updated
        undefined, // update cache data to `undefined`
        { revalidate: false } // do not revalidate
      )
    }
  }
}
