import * as React from 'react'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@dtx-company/design-system/src'
import {
  AuthFormContextValue,
  AuthenticationFormFields,
  AuthenticationFormProps
} from './AuthenticationForm.types'
import { AuthenticationPageHeader } from './PageHeader/AuthenticationPageHeader'
import { CodeConfigurationField } from '../../types/flowcode'
import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  FLOWCODE_TERMS_OF_USE_LINK,
  FLOWCODE_TRIALS_TERMS_OF_SERVICE
} from '../../constants/PrivacyTermsOfService'
import { MAX_EMAIL_CHARS } from '@dtx-company/true-common/src/constants/auth'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { RootState } from '../../redux/types'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SSORedirect } from '../singleSignOn/SSORedirect'
import { SocialLoginButtons } from './SocialLogins/SocialLoginButtons'
import {
  auth_newform_signin_sso_started,
  auth_signin_form_emailpassword_started,
  auth_signin_form_emailpassword_toggled_eyeicon,
  auth_signup_form_emailpassword_started,
  auth_signup_form_emailpassword_toggled_eyeicon
} from '../../constants/authHeapEvents'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import {
  selectError,
  selectScanDestinationIsValid
} from '@dtx-company/app/shared/code/src/machines/locg/locgSelectors'
import { setAuthFormState } from '../../redux/slices/auth-form'
import {
  useAuthFormSubmitButtonLabel,
  useAuthenticationFieldsValidationSchema,
  useCodeGenerator
} from './AuthenticationForm.hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useGlobalServices } from '@dtx-company/app/shared/common/src/hooks/useGlobalServices'
import { useHubSpotUserIthacaRoleSync } from '@dtx-company/app/components/_app/hooks/useHubSpotUserSync'
import { useIsEU, useIsUS, useIsUSLoaded } from '../../redux/selectors/currentSessionSelectors'
import { useOnAuthFormSubmit } from './useFormSubmit'
import { useOnce } from '@dtx-company/true-common/src/hooks/useOnce'
import { usePrelogin } from '../../hooks/auth/usePrelogin'
import { useRouter } from 'next/router'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'
import { useSelector as useXSelector } from '@xstate/react'
import { validateEmail } from '../../utils/notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import DownloadIcon from '@mui/icons-material/Download'
import Link from 'next/link'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MuiIconButton from '@mui/material/IconButton'
import Trans from 'next-translate/Trans'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'

const defaultValue: AuthFormContextValue = {
  action: 'sign in',
  product: 'flowcode'
}

const AuthFormContext = createContext(defaultValue)

export const Provider: FC<{ children: ReactNode; value: AuthFormContextValue }> = ({
  children,
  value
}) => <AuthFormContext.Provider value={value}>{children}</AuthFormContext.Provider>

export function useAuthFormContext(): AuthFormContextValue {
  return useContext(AuthFormContext)
}

const AuthDisclaimer = dynamic(() => import('./Disclaimer').then(mod => mod.AuthDisclaimer)) as FC<{
  isUS: boolean
  ssoMode: boolean
  isNonTrialPlan?: boolean
  versionEmailFieldsAboveTheFold?: boolean
}>

export const AuthenticationForm: FC<AuthFormContextValue & AuthenticationFormProps> = ({
  onSubmit,
  planTypeQueryParams,
  inviteState,
  validateOnFormFocus,
  onSubmitError,
  ...rest
}) => {
  return (
    <Provider value={rest}>
      <Form
        onSubmit={onSubmit}
        planTypeQueryParams={planTypeQueryParams}
        inviteState={inviteState}
        validateOnFormFocus={validateOnFormFocus}
        onSubmitError={onSubmitError}
      />
    </Provider>
  )
}

const Form: FC<
  Pick<
    AuthenticationFormProps,
    'inviteState' | 'onSubmit' | 'planTypeQueryParams' | 'validateOnFormFocus' | 'onSubmitError'
  >
> = ({ onSubmit, planTypeQueryParams, inviteState, validateOnFormFocus, onSubmitError }) => {
  const [revealPassword, setRevealPassword] = useState(false)
  const invitationDomain = inviteState?.ssoDomain

  const { action, product } = useAuthFormContext()
  const isSignIn = action === 'sign in'
  const isSignUp = !isSignIn
  const fromFlowpage = product === 'flowpage'

  const { emailIsSSOEnabled, checkIfEmailSSOEnabled, ssoRedirectHtml, startSSORedirect } =
    usePrelogin()
  const mode = emailIsSSOEnabled || invitationDomain ? 'sso' : 'emailAndPassword'

  const router = useRouter()
  const isFreeQrGeneratorPage = router.pathname === Routes.FREE_QR_CODE_GENERATOR
  const authFormSubmitButtonLabel = useAuthFormSubmitButtonLabel({
    isSignIn,
    isFreeQrGeneratorPage,
    mode
  })

  const isEU = useIsEU()
  const isUS = useIsUS()
  const isUSLoaded = useIsUSLoaded()
  const authenticationFieldsValidation = useAuthenticationFieldsValidationSchema({
    isSignIn,
    isUS,
    invitationDomain,
    mode
  })
  const initialAuthFormValues = useSelector((state: RootState) => state.appReducer.authFormReducer)
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { isSubmitting, errors },
    trigger
  } = useForm<AuthenticationFormFields>({
    resolver: yupResolver(authenticationFieldsValidation),
    defaultValues: {
      ...initialAuthFormValues,
      terms: false,
      // We automatically opt users into marketing emails by having the noMarketingEmails checkbox unchecked,
      // For EU users we cannot auto opt them in so the checkbox should start as checked.
      noMarketingEmails: Boolean(isEU)
    },
    reValidateMode: 'onSubmit'
  })

  const dispatch = useDispatch()
  const onAuthFormSubmit = useOnAuthFormSubmit()

  const checkboxRef = useRef<HTMLDivElement>(null)
  const values = watch()
  const { t } = useTranslation('authentication')
  const { t: tLocg } = useTranslation('logged-out-generator')
  const { setLocgFormValuesInAuthCache, handleGeneratorCodeCreation } = useCodeGenerator()

  const [errorAlert, setErrorAlert] = useState('')
  const [showActivationBanner, setShowActivationBanner] = useState(false)
  const [socialTermsError, setSocialTermsError] = useState<string>()
  const { isMobile } = useScreenSizeBreakpoints()
  const versionEmailFieldsAboveTheFold = !isMobile && isFreeQrGeneratorPage
  const { locgService } = useGlobalServices()

  const error = useXSelector(locgService, selectError)(CodeConfigurationField.SCAN_DESTINATION)
  const scanDestinationIsNotEmpty = useXSelector(locgService, selectScanDestinationIsValid)
  const formIsValid = !error && scanDestinationIsNotEmpty

  const isNonTrialPlan = planTypeQueryParams
    ? [PlanType.PRO, PlanType.PRO_FLEX, PlanType.PRO_PLUS_ORG].includes(planTypeQueryParams)
    : false

  const onFormSubmit = async (): Promise<void> => {
    if (isSignUp) {
      const validate = await validateEmail(values.email)
      if (!validate.valid_email_address) {
        setError('email', {
          message: validate?.suggested_correction
            ? t('Email.suggestedCorrection', {
                suggestedCorrection: validate.suggested_correction
              })
            : t('Email.enterValidEmail')
        })
        return
      }

      if (!isUS && !values.terms) {
        setErrorAlert(t('SignUpPage.errors.termsCheckbox'))
        return
      }
    }

    if (isFreeQrGeneratorPage) {
      if (!formIsValid) {
        onSubmitError?.()
        return
      }

      // we do this now even w/ email + password
      setLocgFormValuesInAuthCache()
    }

    // SSO
    if (mode === 'sso') {
      fireAnalyticsEvent(auth_newform_signin_sso_started)
      await startSSORedirect()
      return
    }

    // EMAIL/PW
    fireAnalyticsEvent(
      isSignIn ? auth_signin_form_emailpassword_started : auth_signup_form_emailpassword_started
    )
    clearErrors()
    setErrorAlert('')

    const needsActivation = await onAuthFormSubmit({
      values,
      setError,
      onSubmit,
      setErrorAlert,
      handleGeneratorCodeCreation
    })

    setShowActivationBanner(needsActivation)
  }

  const handleSocialsCheckTerms = (args: { quiet?: boolean } | void): boolean => {
    if (!values.terms && isSignUp && !isUS) {
      if (!args?.quiet) {
        setSocialTermsError(t('SignUpPage.errors.termsSocialButton'))
        setError('terms', {
          message: t('SignUpPage.errors.termsCheckbox')
        })
      }
      return false
    }
    return true
  }

  useEffect(() => {
    if (values.terms && socialTermsError) {
      setSocialTermsError(undefined)
    }
  }, [values.terms, socialTermsError])

  // HubSpot user sync. Create/update user in HubSpot with Ithaca role when user sign up or sign in
  useHubSpotUserIthacaRoleSync()

  useEffect(() => {
    if (isSignUp && errors?.terms?.message && checkboxRef.current) {
      checkboxRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'end' })
    }
  }, [errors?.terms?.message, isSignUp])

  useOnce(() => {
    if (isFreeQrGeneratorPage && router.query?.email) {
      setValue('email', router.query.email as string)
    }
  })

  const NeedsActivationBanner: React.FC = () => {
    if (!showActivationBanner) return null

    if (isSignIn)
      return (
        <Alert sx={{ mt: 4 }} severity="warning">
          {`${t('EmailVerification.signin')} ${values.email}.`}
        </Alert>
      )

    if (isSignUp)
      return (
        <Alert sx={{ mt: 4 }} severity="success">
          {`${t('EmailVerification.signup')} ${values.email}.`}
        </Alert>
      )

    return null
  }

  return (
    <>
      <AuthenticationPageHeader
        planTypeQueryParams={planTypeQueryParams}
        invitationDomain={invitationDomain}
        onToggleMode={() =>
          dispatch(
            setAuthFormState({
              email: values.email,
              password: values.password
            })
          )
        }
        isSignIn={isSignIn}
        fromFlowpage={fromFlowpage}
        inviteState={inviteState}
      />
      {/* used for tests to detect presence of sign in or signup form */}
      <span data-testid={isSignIn ? 'signin-form' : 'signup-form'}></span>
      <SSORedirect redirectFormHtml={ssoRedirectHtml} />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        noValidate
        onFocus={() => validateOnFormFocus?.()}
      >
        {!invitationDomain && (
          <>
            <SocialLoginButtons
              onSubmit={onSubmit}
              validateOnFormFocus={validateOnFormFocus}
              checkTerms={handleSocialsCheckTerms}
            />
            {socialTermsError && (
              <Box sx={{ mt: versionEmailFieldsAboveTheFold ? 0 : 4 }}>
                <Typography variant="captionMedium" color="error.main">
                  {socialTermsError}
                </Typography>
              </Box>
            )}
            <Divider
              sx={theme => ({
                m: isFreeQrGeneratorPage
                  ? {
                      xs: theme.spacing(4, 0),
                      md: theme.spacing(versionEmailFieldsAboveTheFold ? 2 : 8, 0)
                    }
                  : theme.spacing(6, 0)
              })}
              role="separator"
              data-testid="divider"
            >
              <Typography variant="labelSmall" color="text.secondary">
                {t('OR')}
              </Typography>
            </Divider>
          </>
        )}

        <Stack
          sx={
            versionEmailFieldsAboveTheFold
              ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }
              : undefined
          }
        >
          <Box sx={{ mb: versionEmailFieldsAboveTheFold ? 0 : 4 }}>
            <TextField
              {...register('email', {
                onChange: e => {
                  if (errors.email?.message) clearErrors('email')
                  const email = e.target.value?.toLowerCase()
                  checkIfEmailSSOEnabled(email)
                },
                onBlur: () => {
                  if (values.email) {
                    fireAnalyticsEvent('userTypedInAuthFormEmailField', {
                      email: values.email
                    })
                  }
                }
              })}
              label={isSignIn ? t('Email.signInLabel') : t('Email.signUpLabel')}
              type={'email'}
              autoComplete="email"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              onFocus={() => {
                const email = values.email
                checkIfEmailSSOEnabled(email)
                fireAnalyticsEvent(
                  isSignIn ? 'Core_SignIn_Focused_TextField' : 'Core_SignUp_Focused_TextField',
                  { textField: 'email' }
                )
              }}
              inputProps={{
                maxLength: MAX_EMAIL_CHARS,
                name: 'email',
                'data-testid': `${isSignIn ? 'signin' : 'signup'}-email-input`
              }}
            />
          </Box>
          {mode === 'sso' ? (
            <Box sx={{ mt: 5.5 }}>
              <Stack alignItems="center" justifyContent="center" direction="row">
                <LockOutlinedIcon sx={{ mr: 2 }} />
                <Typography variant="bodyMedium">{t('SignUpPage.SSOEnabled')}</Typography>
              </Stack>
            </Box>
          ) : (
            <>
              <TextField
                {...register('password', {
                  onChange: () => {
                    if (errors.password?.message) clearErrors('password')
                    trigger('password')
                  },
                  onBlur: () => {
                    if (values.password) {
                      fireAnalyticsEvent('userTypedInAuthFormPasswordField')
                    }
                  }
                })}
                label={t('Password.label')}
                type={revealPassword ? 'text' : 'password'}
                endAdornment={
                  values.password ? (
                    <MuiIconButton
                      onClick={() => {
                        fireAnalyticsEvent(
                          isSignIn
                            ? auth_signin_form_emailpassword_toggled_eyeicon
                            : auth_signup_form_emailpassword_toggled_eyeicon
                        )
                        setRevealPassword(prev => !prev)
                      }}
                      aria-label={tLocg('PasswordField.ToggleVisibility')}
                      sx={{ svg: { color: 'onSurface.secondary' } }}
                    >
                      {revealPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </MuiIconButton>
                  ) : (
                    <></>
                  )
                }
                autoComplete={isSignIn ? 'current-password' : 'new-password'}
                fullWidth
                error={!!errors.password}
                helperText={errors.password?.message}
                onFocus={() => {
                  fireAnalyticsEvent(
                    isSignIn ? 'Core_SignIn_Focused_TextField' : 'Core_SignUp_Focused_TextField',
                    { textField: 'password' }
                  )
                }}
                inputProps={{
                  name: 'password',
                  'data-testid': `${isSignIn ? 'signin' : 'signup'}-password-input`
                }}
              />
              {isSignIn && (
                <Stack
                  sx={{
                    mt: 2,
                    alignItems: 'flex-end',
                    a: {
                      textDecoration: 'underline',
                      pt: 2
                    }
                  }}
                >
                  <Typography
                    component={Link}
                    href={Routes.FORGOT_PASSWORD}
                    variant="labelSmall"
                    align="right"
                    color="onSurface.secondary"
                    onClick={() => fireAnalyticsEvent('userClickedAuthFormForgotPassword')}
                  >
                    {t('SignInPage.forgotPassword')}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>

        {isSignUp && (
          <>
            {isUSLoaded ? (
              !isUS &&
              mode != 'sso' && (
                <Stack sx={{ mt: versionEmailFieldsAboveTheFold ? 0 : 2 }} ref={checkboxRef}>
                  <Checkbox
                    inputProps={{
                      ...register('noMarketingEmails', {
                        onChange: () => {
                          clearErrors('noMarketingEmails')
                          if (!values.noMarketingEmails) {
                            fireAnalyticsEvent(
                              'Revenue_SignUpPage_Clicked_MarketingOptOutCheckbox',
                              {
                                isChecked: !values.noMarketingEmails
                              }
                            )
                          }
                        }
                      }),
                      role: 'checkbox'
                    }}
                    checked={values.noMarketingEmails}
                    label={<Typography variant="labelSmall">{t('noMarketingEmails')}</Typography>}
                    size="small"
                    color="secondary"
                    helperText={errors?.noMarketingEmails?.message ?? ''}
                    error={Boolean(errors?.noMarketingEmails?.message)}
                    data-testid="marketing-emails-checkbox"
                  />

                  <Checkbox
                    inputProps={{
                      ...register('terms', {
                        onChange: () => {
                          clearErrors('terms')
                          setValue('terms', !values.terms)
                          if (!values.terms) {
                            fireAnalyticsEvent('userCheckedAuthFormIAgreeToTermsAndConditions')
                            fireAnalyticsEvent(
                              'Revenue_SignUpPage_Clicked_TermsAndConditionsCheckbox'
                            )
                          }
                        }
                      }),
                      role: 'checkbox'
                    }}
                    label={
                      <Trans
                        i18nKey={'authentication:agreeToTermsAndConditions'}
                        components={{
                          span: <Typography variant="labelSmall" component={'span'} />,
                          link: (
                            <Link
                              href={
                                isNonTrialPlan
                                  ? FLOWCODE_TERMS_OF_USE_LINK
                                  : FLOWCODE_TRIALS_TERMS_OF_SERVICE
                              }
                              style={{ textDecoration: 'underline' }}
                              target="_blank"
                            />
                          )
                        }}
                        defaultTrans={'<span>I agree to the <link>terms of use</link>.</span>'}
                      />
                    }
                    checked={values.terms}
                    size="small"
                    color="secondary"
                    helperText={errors?.terms?.message ?? ''}
                    error={Boolean(errors?.terms?.message)}
                    data-testid="terms-checkbox"
                  />
                </Stack>
              )
            ) : (
              <Skeleton height={76} sx={{ mt: 7.5 }} />
            )}
          </>
        )}

        {errorAlert && (
          <Box sx={{ mt: 4 }}>
            <Alert description={errorAlert} severity="error" />
          </Box>
        )}

        <Box sx={{ mt: 6 }}>
          <Button
            type="submit"
            size="large"
            fullWidth
            loading={isSubmitting}
            onClick={() => {
              fireAnalyticsEvent('userClickedAuthFormSubmitButton', {
                sso: mode === 'sso'
              })

              if (mode === 'emailAndPassword') {
                isSignUp &&
                  fireAnalyticsEvent('Revenue_SignUpPage_Clicked_SignUpButton', {
                    signupButtonType: 'EMAIl'
                  })
              }
            }}
            data-testid="submit-button-text"
            startIcon={isFreeQrGeneratorPage ? DownloadIcon : undefined}
          >
            {isFreeQrGeneratorPage
              ? tLocg('LoggedOutGenerator.FormActionButtons.Download')
              : authFormSubmitButtonLabel}
          </Button>
        </Box>
      </form>
      {isSignUp && (
        <AuthDisclaimer
          isUS={Boolean(isUS)}
          ssoMode={mode === 'sso'}
          isNonTrialPlan={isNonTrialPlan}
          versionEmailFieldsAboveTheFold={versionEmailFieldsAboveTheFold}
        />
      )}
      <NeedsActivationBanner />
    </>
  )
}
