import { Box } from '@dtx-company/design-system/src/components/Box/Box'
import { CSSProperties, FC, PropsWithChildren, cloneElement } from 'react'
import { EmptyObject } from 'type-fest'
import { Stack } from '@dtx-company/design-system/src'
import { useTheme } from '@mui/material'
import get from 'lodash/get'

export type Slot<T = EmptyObject> = PropsWithChildren<T>

type GridLayoutProps = {
  /**
   * The header slot is an optional full-width slot that is always displayed at the top of the screen.
   */
  headerSlot?: Slot
  background?: string
} & (TwoColumnSlotOptions | OneColumnSlotOptions)

type TwoColumnSlotOptions = {
  /**
   * The primary slot is the main content of the page.
   * In two-column layouts, it is the left column at desktop sizes, and below the secondary slot at mobile sizes.
   * If the secondary slot has a graphic, the primary slot may not have one.
   */
  primarySlot: Slot<GridLayoutSlotOptionsWithoutGraphic>
  /**
   * The secondary slot is an optional slot intended to be used for marketing content.
   * In two-column layouts, it is the right column at desktop sizes, and above the primary slot at mobile sizes.
   * Additionally, at mobile sizes, the secondary slot's graphic is displayed as a hero image above the primary slot,
   * regardless of the graphic's position as defined by its props, unless 'hideOnMobile' is set to true, in which case
   * neither the graphic nor the slot's children will be displayed.
   */
  secondarySlot?: Slot<GridLayoutSlotOptionsWithGraphic> & { hideOnMobile?: boolean }
}

interface OneColumnSlotOptions {
  /**
   * The primary slot is the main content of the page.
   * In one-column layouts, it is the only column.
   */
  primarySlot: Slot<GridLayoutSlotOptionsWithGraphic>
  /**
   * Do not pass a secondary slot in order to create a one-column layout.
   */
  secondarySlot?: never
}

interface BaseGridLayoutSlotOptions {
  backgroundColor?: string
  verticalAlignment?: CSSProperties['alignSelf']
  gridColumn?: string
  maxWidth?: string
}

export interface GridLayoutSlotOptionsWithoutGraphic extends BaseGridLayoutSlotOptions {
  backgroundImgSrc?: never
  graphic?: never
  graphicPosition?: never
}

export type GridLayoutSlotOptionsWithGraphic = BaseGridLayoutSlotOptions &
  (GridLayoutSlotOptionsWithHeroGraphic | GridLayoutSlotOptionsWithBackgroundGraphic)

interface GridLayoutSlotOptionsWithHeroGraphic extends BaseGridLayoutSlotOptions {
  backgroundImgSrc?: never
  graphic?: JSX.Element
  graphicPosition?: 'hero'
}

interface GridLayoutSlotOptionsWithBackgroundGraphic extends BaseGridLayoutSlotOptions {
  backgroundImgSrc?: string
  graphic?: never
  graphicPosition?: 'background'
}

export const HEADER_HEIGHT_FACTORS = {
  xs: 18,
  md: 20
}

export const MAX_WIDTH_FACTOR = 116

export const GridLayout: FC<GridLayoutProps> = ({
  headerSlot,
  background,
  primarySlot,
  secondarySlot
}) => {
  const hasSecondarySlot = Boolean(secondarySlot) || Object.keys(secondarySlot || {}).length > 0
  const shouldShowSecondarySlotGraphicOnMobile =
    Boolean(secondarySlot?.graphic || secondarySlot?.backgroundImgSrc) &&
    !secondarySlot?.hideOnMobile
  const backgroundGraphicSrc = primarySlot.backgroundImgSrc || secondarySlot?.backgroundImgSrc

  const theme = useTheme()

  const getColor = (color?: string): string => {
    if (color?.startsWith('#') || color?.startsWith('rgb')) {
      return color
    }
    return get(theme.palette, color || 'surface.0')
  }

  return (
    <Box
      sx={theme => ({
        display: 'grid',
        background: background,
        backgroundRepeat: background ? 'norepeat' : undefined,
        // set up page layout grid with 3 columns at md+:
        gridTemplateColumns: {
          md: `auto minmax(0, ${theme.breakpoints.values.xl}px) auto`,
          xl: `auto ${theme.breakpoints.values.xl}px auto`
        },
        gridTemplateRows: {
          xs: `${theme.spacing(HEADER_HEIGHT_FACTORS.xs)} auto auto`,
          md: `${theme.spacing(HEADER_HEIGHT_FACTORS.md)} auto`
        },
        height: '100%',
        minHeight: '100dvh',
        overflowX: 'hidden',
        position: 'relative',
        ...(hasSecondarySlot && {
          background: {
            // extend the secondary slot's background color to edge of screen
            // the '60%' value isn't specific, it just needs to be larger than the width of the primary slot
            xl: `linear-gradient(
                to right,
                ${getColor(primarySlot.backgroundColor)},
                ${getColor(primarySlot.backgroundColor)} 60%,
                ${getColor(secondarySlot?.backgroundColor)} 60%,
                ${getColor(secondarySlot?.backgroundColor)}
              )`
          }
        })
      })}
    >
      {/* header slot */}
      <Stack
        data-testid="header-slot"
        justifyContent="center"
        sx={{
          gridColumn: {
            md: '2 / 2',
            xl: '1 / -1'
          },
          gridRow: '1 / 1',
          backgroundColor: {
            xs: shouldShowSecondarySlotGraphicOnMobile
              ? secondarySlot?.backgroundColor
              : primarySlot?.backgroundColor,
            md: primarySlot?.backgroundColor || 'unset'
          },
          px: {
            xs: 6,
            md: 8
          },
          zIndex: 'appBar'
        }}
      >
        {headerSlot?.children}
      </Stack>

      <Stack
        sx={{
          // place content in the center of the screen
          gridColumn: {
            md: '2 / 2'
          },
          gridRow: '2 / -1',
          // set up content layout grid with 12 columns:
          display: {
            xs: 'flex',
            md: 'grid'
          },
          gridTemplateColumns: {
            md: 'repeat(12, 1fr)'
          },
          gridTemplateRows: {
            md: 'repeat(12, 1fr)'
          },
          alignItems: {
            xs: 'center',
            md: 'unset'
          },
          gap: {
            xs: 0,
            md: 6
          },
          maxWidth: {
            xs: '100vw',
            md: 'unset'
          }
        }}
      >
        {/* primary slot */}
        <Stack
          data-testid="primary-slot"
          direction="column"
          sx={theme => ({
            order: {
              xs: 2,
              md: 'unset'
            },
            gridColumn: {
              xs: '1 / -1',
              md: primarySlot?.gridColumn
                ? primarySlot?.gridColumn
                : `${hasSecondarySlot ? 2 : 4} / ${hasSecondarySlot ? -7 : -4}`
            },
            gridRow: {
              md: '1 / -1'
            },
            alignSelf: {
              md: primarySlot?.verticalAlignment || 'center'
            },
            justifySelf: {
              xs: 'center', // always justify primary slot to center on mobile
              md:
                hasSecondarySlot && secondarySlot?.graphicPosition === 'hero'
                  ? 'start' // justify primary slot to start (left) if the secondary slot has a hero graphic
                  : 'center' // otherwise, justify primary slot to center
            },
            width: '100%',
            maxWidth: primarySlot?.maxWidth
              ? primarySlot?.maxWidth
              : theme.spacing(MAX_WIDTH_FACTOR),
            backgroundColor: primarySlot?.backgroundColor,
            px: {
              xs: 6,
              md: 'unset'
            },
            pb: {
              md:
                primarySlot.verticalAlignment === 'center'
                  ? HEADER_HEIGHT_FACTORS.md // leave space a the bottom equal to the header row height when using center alignment
                  : 'unset'
            },
            mt: {
              xs: 8,
              md: primarySlot.verticalAlignment === 'start' ? 8 : 'unset'
            }
          })}
        >
          {/* slot content */}
          {primarySlot.children}
        </Stack>

        {/* secondary slot */}
        {hasSecondarySlot && (
          <Box
            data-testid="secondary-slot"
            sx={{
              order: {
                xs: 1,
                md: 'unset'
              },
              gridColumn: {
                xs: '1 / -1',
                md: '7 / -1'
              },
              gridRow: {
                md: '1 / -1'
              },
              display: {
                xs: secondarySlot?.hideOnMobile ? 'none' : 'grid',
                md: 'grid'
              },
              gridTemplateColumns: 'repeat(12, 1fr)',
              gridTemplateRows: 'repeat(12, 1fr)',
              gap: 6,
              container: {
                ...(secondarySlot?.graphicPosition === 'background' && {
                  md: 'secondary-background / size'
                }),
                xl: 'unset'
              },
              position: {
                xs: 'relative',
                md: 'static'
              },
              width: {
                xs: '100%',
                md: 'unset'
              }
            }}
          >
            {/* background color */}
            <Box
              data-testid="secondary-slot__background"
              sx={{
                gridColumn: {
                  xs: '1 / -1',
                  md: '3 / -1'
                },
                gridRow: {
                  xs: '1 / -3',
                  md: '1 / -1'
                },
                backgroundColor: secondarySlot?.backgroundColor,
                display: {
                  xs: 'block',
                  md: 'grid'
                },
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridTemplateRows: 'repeat(12, 1fr)',
                position: {
                  md: 'relative'
                }
              }}
            >
              {/* empty div to extend background color above the secondary slot into the header slot */}
              <Box
                sx={theme => ({
                  gridColumn: '1 / -1',
                  gridRow: '1 / -1',
                  transform: 'translateY(calc(-100% + 1px))', // 1px to cover cases when a 1px gap appears between the header and secondary slot at some screen sizes
                  height: theme.spacing(HEADER_HEIGHT_FACTORS.md + 1),
                  backgroundColor: secondarySlot?.backgroundColor
                })}
              />

              <Box
                sx={{
                  gridColumn: {
                    xs: '2 / -2',
                    lg: '3 / -3'
                  },
                  gridRow: {
                    xs: '1 / -1',
                    md: `2 / -${secondarySlot?.backgroundImgSrc ? 5 : 2}` // leave space for the background graphic
                  },
                  position: 'relative',
                  alignSelf: secondarySlot?.verticalAlignment || 'center',
                  pb: {
                    md:
                      !secondarySlot?.verticalAlignment ||
                      secondarySlot?.verticalAlignment === 'center'
                        ? HEADER_HEIGHT_FACTORS.md // leave space a the bottom equal to the header row height when using center alignment
                        : 'unset'
                  }
                }}
              >
                {/* slot content -- hidden on mobile (should only be marketing content) */}
                <Box sx={{ display: { xs: 'none', md: 'unset' } }}>{secondarySlot?.children}</Box>
              </Box>
            </Box>

            {/* mobile background graphic */}
            {backgroundGraphicSrc && (
              <Box
                data-testid="background-graphic--mobile"
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none'
                  },
                  position: 'relative',
                  gridColumn: '2 / -2',
                  gridRow: '1 / -1',
                  backgroundImage: `url("${backgroundGraphicSrc}")`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPositionX: 'center'
                }}
              />
            )}

            {/* hero slot graphic */}
            {secondarySlot?.graphic && (
              <Box
                data-testid="secondary-slot__graphic"
                sx={theme => ({
                  gridColumn: {
                    xs: '2 / -2',
                    md: '1 / -1'
                  },
                  gridRow: {
                    xs: '1 / -1',
                    md: '1 / -2'
                  },
                  display: 'flex',
                  alignSelf: {
                    xs: 'end',
                    md: 'unset'
                  },
                  alignItems: {
                    xs: 'flex-end',
                    md: 'center'
                  },
                  justifyContent: {
                    xs: 'center',
                    md: 'unset'
                  },
                  height: '100%',
                  position: 'relative',
                  '.fc-grid-layout__secondary__graphic--hero': {
                    objectFit: 'contain',
                    position: 'relative', // to have it display in front of the background color
                    width: '100%',
                    maxWidth: {
                      xs: theme.spacing(MAX_WIDTH_FACTOR),
                      md: '50vw'
                    },
                    height: 'auto',
                    maxHeight: {
                      xs: 'min(80vw, 100cqi)',
                      md: '100%'
                    },
                    pr: {
                      md: 6
                    }
                  }
                })}
              >
                {cloneElement(secondarySlot.graphic, {
                  className: 'fc-grid-layout__secondary__graphic--hero'
                })}
              </Box>
            )}
          </Box>
        )}
      </Stack>

      {/* background image */}
      {backgroundGraphicSrc && (
        <Box
          sx={{
            gridColumn: '1 / -1',
            gridRow: '1 / -1',
            display: {
              xs: 'none',
              md: 'grid'
            },
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridTemplateRows: 'repeat(12, 1fr)',
            pointerEvents: 'none'
          }}
        >
          <Box
            data-testid="background-graphic--desktop"
            sx={{
              display: {
                xs: 'none',
                md: backgroundGraphicSrc ? 'block' : 'none'
              },
              gridColumn: `${hasSecondarySlot ? 9 : 10} / -1`,
              gridRow: '9 / -1',
              position: 'relative',
              backgroundImage: `url("${backgroundGraphicSrc}")`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'right calc(100% / -12) bottom calc(100% / -12)'
            }}
          />
        </Box>
      )}
    </Box>
  )
}
