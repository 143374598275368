import { FC, useEffect } from 'react'

export const SSORedirect: FC<{ redirectFormHtml?: string | null }> = ({ redirectFormHtml }) => {
  useEffect(() => {
    if (redirectFormHtml) {
      document.getElementById('SAMLSubmitButton')?.click()
    }
  }, [redirectFormHtml])
  return !redirectFormHtml ? (
    <></>
  ) : (
    <div
      style={{ height: '0px', width: '0px', opacity: 0 }}
      dangerouslySetInnerHTML={{ __html: redirectFormHtml }}
    />
  )
}
