import { ReactNode } from 'react'
import { SWRConfig, SWRConfiguration, SWRResponse, preload, unstable_serialize } from 'swr'
import { useDebounce } from 'use-debounce'
import { useSWRPlus } from '../../services/swrPlus'
import stableHash from 'stable-hash'

// Using `unknown` in this file creates TS errors in a number of places
// and I spent a couple hours trying to figure them out and not getting anywhere.
// Using `unknown` is important to ensure that the type is checked before used, but these
// values aren't used within this file, the typechecks are just to ensure that things with
// valid signatures are used.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ANY = any

type FetcherOptions = Record<string | symbol | number, ANY>
type FetcherResponse = Record<string | symbol | number, ANY> | ANY[] | null
type FetcherFn<TOptions extends FetcherOptions, TData extends FetcherResponse> = {
  (options: TOptions): Promise<TData>
  swrKey: string
}

type UseAPIOptions<TOptions extends FetcherOptions> =
  | TOptions
  | undefined
  | (() => TOptions | undefined)
type UseAPIConfig<TData, TError> = SWRConfiguration<TData, TError> & {
  debounceInterval?: number
}
type UseAPIResponse<TData, TError> = SWRResponse<TData, TError>

function getUuid(fetcher: FetcherFn<FetcherOptions, FetcherResponse>): string {
  return `useAPISWR-${fetcher.swrKey}`
}

function normalizeOptions<TOptions extends FetcherOptions>(
  fetcher: FetcherFn<FetcherOptions, FetcherResponse>,
  options: UseAPIOptions<TOptions>
): [string, TOptions] | undefined {
  const uuid = getUuid(fetcher)
  let normalizedOptions: TOptions | undefined
  if (typeof options === 'function') {
    try {
      normalizedOptions = options()
    } catch (_error) {
      // useSWR expects that if this throws an error it's because
      // the deps aren't ready so it suppresses the error
      // and simply does not call the fetcher yet
      normalizedOptions = undefined
    }
  } else {
    normalizedOptions = options
  }
  if (!normalizedOptions) return
  return [uuid, normalizedOptions]
}

/**
 * Provides a simple `useSWR` wrapper with strong types for API fetcher functions.
 *
 * The `fetcher` function must take a single `options` object argument to receive all its
 * params, and it may not return `undefined`. Most things should return an object or array,
 * or `null` may be used to indicate that something did not exist.
 *
 * Replaces `useAPI` by removing the need to write a custom wrapper, spec, and mock for each function.
 *
 * @example
 * const plansSWR = useAPISWR(getPlans, { filter: { planId: 'flowcode_pro-g' } }, { revalidateOnMount: true })
 */
export function useAPISWR<
  TOptions extends FetcherOptions,
  TData extends FetcherResponse,
  TError extends Error = Error
>(
  fetcher: FetcherFn<TOptions, TData>,
  options: UseAPIOptions<TOptions>,
  config?: UseAPIConfig<TData, TError>
): UseAPIResponse<TData, TError> & { key: string } {
  const [debouncedOptions] = useDebounce(
    // SWR stuff is only called on the client as-is, but checking to avoid
    // populating the fn cache on the server-side
    normalizeOptions<TOptions>(fetcher, options),
    config?.debounceInterval || 0,
    {
      leading: false,
      trailing: true,
      equalityFn(left, right) {
        // stable hash is the same comparator that useSWR uses internally
        // unlike json, it supports functions and circular refs so any args can be used
        return stableHash(left) === stableHash(right)
      }
    }
  )

  return {
    ...useSWRPlus<TData, TError>(
      debouncedOptions,
      ([_uuid, options]: [string, TOptions]) => {
        return fetcher(options)
      },
      config
    ),
    key: unstable_serialize(debouncedOptions)
  }
}

export function preloadAPISWR<TOptions extends FetcherOptions, TData extends FetcherResponse>(
  fetcher: FetcherFn<TOptions, TData>,
  options: UseAPIOptions<TOptions>
): Promise<TData> {
  const normalizedOptions = normalizeOptions<TOptions>(fetcher, options)
  return preload(normalizedOptions, ([_uuid, options]: [string, TOptions]) => {
    return fetcher(options)
  })
}

interface APISWRFallbackProviderProps<
  TOptions extends FetcherOptions,
  TData extends FetcherResponse
> {
  fetcher: FetcherFn<TOptions, TData>
  options: UseAPIOptions<TOptions>
  data: TData
  children: ReactNode
}

export function APISWRFallbackProvider<
  TOptions extends FetcherOptions,
  TData extends FetcherResponse
>(props: APISWRFallbackProviderProps<TOptions, TData>): JSX.Element {
  const { fetcher, options, data, children } = props
  const normalizedOptions = normalizeOptions<TOptions>(fetcher, options)
  const key = unstable_serialize(normalizedOptions)
  return <SWRConfig value={{ fallback: { [key]: data } }}>{children}</SWRConfig>
}
