/**
 * Splits a vCard phone number on the first hyphen ("-") character. This is mostly
 * used for dealing with phone numbers in vCard objects
 *
 * vCard phone numbers in the vCard service are stored as "+CC-DIGITS" where CC is the country code
 * followed by a hypen ("-") followed by DIGITS. The hyphen is valid separator
 * as per RFC3966 but not the only
 * valid separator. Flowcode chose to use the "hyphen" as the only valid separator between
 * the country code and the remaining digits for the time being
 *
 * See. {@link [RFC3966](https://datatracker.ietf.org/doc/html/rfc3966)} and
 * {@link [vCard service](https://github.com/dtx-company/vcard)}
 */
const split = (fullNumber: string | null | undefined): string[] => {
  if (!fullNumber || fullNumber?.length === 0) {
    return ['', '']
  } else if (fullNumber[0] !== '+') {
    return ['', fullNumber]
  }
  // split string at first -
  const [countryCode, phoneNumber] = fullNumber.replace('+', '').split(/-(.*)/s)
  return [countryCode, phoneNumber]
}

/**
 * Joins a countryCode with a phone if both exist. This is to format a phone number
 * before sending it in a vCard object to the vCard service
 *
 * vCard phone numbers in the vCard service are stored as "+CC-DIGITS" where CC is the country code
 * followed by a hypen ("-") followed by DIGITS. The hyphen is valid separator
 * as per RFC3966 but not the only valid separator.  Flowcode chose to use the "hyphen" as the
 * only valid separator between  the country code and the remaining digits for the time being
 *
 * See. {@link [RFC3966](https://datatracker.ietf.org/doc/html/rfc3966)} and
 * {@link [vCard service](https://github.com/dtx-company/vcard)}
 */
const join = (countryCode: string, phone: string): string => {
  const computedCountryCode = countryCode ? `+${countryCode}-` : ''
  return phone ? `${computedCountryCode}${phone}` : ''
}

/**
 * Utils for joining and spliting phone numbers with country codes to interface with
 * the vCard service
 */
export const countryCodeAndNumberUtils = { join, split }
