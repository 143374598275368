import { FRONTEGG_RESET_ACTIVATION_ENDPOINT, JSON_HEADERS } from '@dtx-company/ithaca-sdk/src'
import { logger } from '@dtx-company/logger'

const sendActivationEmail = async (email: string): Promise<boolean> => {
  try {
    const res = await fetch(FRONTEGG_RESET_ACTIVATION_ENDPOINT, {
      method: 'POST',
      headers: JSON_HEADERS,
      body: JSON.stringify({
        email
      })
    })

    if (res.ok || res.status === 201) return true
  } catch (e) {
    logger.logError(`${e}`, { team: 'PLG' })
    return false
  }

  return false
}

export default sendActivationEmail
