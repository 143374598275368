import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-types/src'

export type Options = 'qrrotate' | 'positionElementBottomLeft' | 'positionElementTopRight'

// when frame is enabled alwaysDefaultColor is set to false to add custom white color CTA text
// selectedColor must be added to revert the overwritten colors set by getCreateModeFrameOptions.
export const getRotatedOptions = (
  rotate: boolean,
  options?: FcGeneratorOptions,
  inheritColor?: boolean,
  selectedColor?: string
): Pick<FcGeneratorOptions, Options> => {
  return {
    qrrotate: rotate,
    positionElementBottomLeft: {
      ...options?.positionElementTopRight,
      ...(inheritColor && {
        innerShapeColor: selectedColor,
        outerShapeColor: selectedColor,
        ...(options?.positionElementTopRight?.text && {
          text: {
            ...options.positionElementTopRight.text,
            fontColor: selectedColor || options.positionElementTopRight.text.fontColor
          }
        })
      })
    },
    positionElementTopRight: {
      ...options?.positionElementBottomLeft,
      ...(inheritColor && {
        innerShapeColor: selectedColor,
        outerShapeColor: selectedColor,
        ...(options?.positionElementBottomLeft?.text && {
          text: {
            ...options.positionElementBottomLeft.text,
            fontColor: selectedColor || options.positionElementBottomLeft.text.fontColor
          }
        })
      })
    }
  }
}
