import { Box } from '../Box/Box'
import { FC, useId } from 'react'
import { FormControl } from '../../internal-components/FormControl/FormControl'
import { Stack } from '../Stack/Stack'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import MuiFormHelperText from '@mui/material/FormHelperText'
import MuiFormLabel from '@mui/material/FormLabel'

export interface CheckboxProps
  extends Pick<
    MuiCheckboxProps,
    | 'checked'
    | 'color'
    | 'defaultChecked'
    | 'id'
    | 'indeterminate'
    | 'inputProps'
    | 'inputRef'
    | 'onChange'
    | 'size'
  > {
  alignment?: 'top' | 'center'
  inputProps?: MuiCheckboxProps['inputProps'] & {
    'data-testid'?: string
  }
  disabled?: boolean
  error?: boolean
  focused?: boolean
  helperText?: string
  label: string | JSX.Element
  required?: boolean
}

/**
 * Checkboxes allow the user to select one or more items from a set.
 *
 * More info can be found at: https://mui.com/material-ui/react-checkbox/
 */
export const Checkbox: FC<CheckboxProps> = ({
  alignment = 'top',
  color = 'primary',
  defaultChecked,
  inputProps,
  inputRef,
  indeterminate,
  size,
  checked,
  label,
  onChange,
  disabled,
  error,
  focused,
  required,
  helperText
}: CheckboxProps) => {
  const inputId = useId()
  const helperTextId = useId()

  return (
    <FormControl disabled={disabled} error={error} focused={focused} required={required}>
      <Stack direction="row" sx={{ ...(alignment === 'center' && { alignItems: 'center' }) }}>
        <MuiCheckbox
          id={inputId}
          color={color}
          defaultChecked={defaultChecked}
          inputProps={{
            ...inputProps,
            ...(helperText && { 'aria-describedby': helperTextId }),
            'aria-invalid': error
          }}
          inputRef={inputRef}
          indeterminate={indeterminate}
          onChange={onChange}
          size={size}
          checked={checked}
          sx={{ height: 'fit-content' }}
        />
        <Box sx={{ ...(alignment === 'top' && { pt: size === 'small' ? 1.875 : 2.25 }) }}>
          <MuiFormLabel htmlFor={inputId} color={color}>
            {label}
          </MuiFormLabel>
          {helperText && (
            <MuiFormHelperText id={helperTextId} {...(error && { role: 'alert' })}>
              {helperText}
            </MuiFormHelperText>
          )}
        </Box>
      </Stack>
    </FormControl>
  )
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    default: false
    primary: true
    secondary: true
    error: false
    info: false
    success: false
    warning: false
  }
}
