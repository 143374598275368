import { AuthState, useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'

import {
  trackHubSpotPage,
  updateHubspotUser
} from '@dtx-company/inter-app/src/event-tracking/helpers'
import { useEffect } from 'react'

function getIthacaRole(authState: AuthState): string | undefined {
  if (!authState.isAuthChecked) return undefined
  const { jwt } = authState
  if (jwt?.org && jwt.org.orgPlan) {
    const plan = jwt.org.orgPlan.split(':')[1]
    if (plan) return plan
  }
  if (jwt?.personalPlan) {
    const plan = jwt.personalPlan.split(':')[1]
    if (plan) return plan
  }
  return undefined
}

/**
 * Syncs user data from the app into HubSpot
 */
export function useHubSpotUserIthacaRoleSync(): void {
  const authState = useAuthState()
  const ithacaRole = getIthacaRole(authState)
  useEffect(() => {
    if (!authState.isAuthChecked) return
    if (!authState.jwt?.email) return
    if (!ithacaRole) return
    updateHubspotUserWithValues({
      email: authState.jwt?.email,
      ithaca_role: ithacaRole
    })
  }, [authState.isAuthChecked, authState.jwt?.email, ithacaRole])
}

export type HubSpotUser = Record<string, string> & { email: string }
export const updateHubspotUserWithValues = (values: HubSpotUser): void => {
  updateHubspotUser({
    ...values
  })
  // Must fire a custom event or page tracking event in order to sync the data to hubspot
  // but it seems like custom events aren't working, even when defined in their app
  //https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor
  // updateHubspotUser call stores the data in the tracker, but the data is not actually passed to HubSpot with this call.
  // The data will only be passed when tracking a page view or an event (with either the trackPageView or trackEvent functions).
  trackHubSpotPage()
}
