//temp location while dashboard POC is being built

// Definitions
// *************************************************************************************************
// These heap events should be named as logically as possible. We've set up a convention for naming
// that follows the following pattern: SCOPE_LOCATION_INTENT_METHOD_STATE
//
// Scope remains fixed for all of these events: Auth
// Intent is what the user is trying to do: SignIn, SignUp
// Location is where the event is fired from: Form, DesktopLOCG, MobileLOCG
// Method is how the user is trying to do it: Google, Facebook, EmailPassword, SSO
// State is the state of the event: Started, Success, Failure
//
// An example of this naming convention in practice is: Auth_Form_SignIn_EP_Started
// (Authication via the signup form to signup with email and password was started)

/* Google events */
export const auth_signin_form_google_started = 'Auth_SignIn_Form_Google_Started'
export const auth_signup_form_google_started = 'Auth_SignUp_Form_Google_Started'

export const auth_signin_form_google_success = 'Auth_SignIn_Form_Google_Success'
export const auth_signup_form_google_success = 'Auth_SignUp_Form_Google_Success'

/* Facebook events */
export const auth_signin_form_facebook_started = 'Auth_SignIn_Form_Facebook_Started'
export const auth_signup_form_facebook_started = 'Auth_SignUp_Form_Facebook_Started'

export const auth_signin_form_facebook_success = 'Auth_SignIn_Form_Facebook_Success'
export const auth_signup_form_facebook_success = 'Auth_SignUp_Form_Facebook_Success'

/* Email and password events */
export const auth_signin_form_emailpassword_started = 'Auth_SignIn_Form_EmailPassword_Started'
export const auth_signup_form_emailpassword_started = 'Auth_SignUp_Form_EmailPassword_Started'

export const auth_signup_form_emailpassword_failure = 'Auth_SignUp_Form_EmailPassword_Failure'
export const auth_signin_form_emailpassword_failure = 'Auth_SignIn_Form_EmailPassword_Failure'

export const auth_signup_form_emailpassword_success = 'Auth_SignUp_Form_EmailPassword_Success'
export const auth_signin_form_emailpassword_success = 'Auth_SignIn_Form_EmailPassword_Success'

export const auth_signin_form_emailpassword_toggled_eyeicon =
  'Auth_SignIn_Form_EmailPassword_ToggledEyeIcon'
export const auth_signup_form_emailpassword_toggled_eyeicon =
  'Auth_SignUp_Form_EmailPassword_ToggledEyeIcon'

/* SSO events */
export const auth_newform_signin_sso_started = 'Auth_SignIn_Form_SSO_Started'

/* Google events, desktop + mobile LOCG */
export const auth_desktoplocg_either_google_started = 'Auth_DesktopLOCG_Either_Google_Started'
export const auth_mobilelocg_either_google_started = 'Auth_MobileLOCG_Either_Google_Started'

/* Email + password, desktop + mobile LOCG */
export const auth_desktoplocg_signup_emailpassword_started =
  'Auth_DesktopLOCG_Signup_EmailPassword_Started'
export const auth_mobilelocg_signup_emailpassword_started =
  'Auth_MobileLOCG_SignUp_EmailPassword_Started'
export const auth_mobilelocg_signin_emailpassword_started =
  'Auth_MobileLOCG_SingIn_EmailPassword_Started'

/* SSO events, desktop + mobile LOCG */
export const auth_desktoplocg_signin_sso_started = 'Auth_DesktopLOCG_SignIn_SSO_Started'
export const auth_mobilelocg_signin_sso_started = 'Auth_MobileLOCG_SignIn_SSO_Started'
