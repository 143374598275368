import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import {
  FRONTEGG_AUTH_JSON_HEADERS,
  FRONTEGG_SOCIALS_POST_LOGIN_ENDPOINT,
  JSON_HEADERS
} from './constants'
import { FronteggLoginResponse } from './types'
import {
  OAuthQueryParams,
  OAuthState
} from '@dtx-company/true-common/src/authentication/OAuth/types'
import { SocialLoginProviders } from '@frontegg/rest-api'
import { logger } from '@dtx-company/logger'
import { notifyObserversOfLogin } from './notify'
import { setAccessTokenCookie } from './setAccessTokenCookie'

export async function socialLogin({
  provider,
  action,
  authCacheId
}: {
  provider: string
  action: string
  authCacheId?: string
  postLoginCallback?: () => Promise<void>
}): Promise<string | void> {
  try {
    const resp = await fetch(`${FLOWCODE_ROOT}/api/frontegg/social-login`, {
      method: 'POST',
      headers: JSON_HEADERS,
      body: JSON.stringify({
        provider,
        action,
        authCacheId
      })
    })

    if (!resp.ok) {
      await logger.logFetchError(resp, { technicalArea: 'authentication' })
    }

    const { url } = await resp.json()

    return url
  } catch (e: unknown) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}

export type SocialPostLoginArgs = Pick<OAuthState, 'provider'> & Required<OAuthQueryParams>

export const MISSING_EMAIL_ERROR_MESSAGE = 'Email contains invalid characters'
export const MISSING_EMAIL_ERROR_MESSAGE_FACEBOOK = `You must share your email in order to sign in with Facebook. Please check the settings for the "Flow" app in your Facebook account and try again. If the issue persists, please contact support.`

export async function socialPostLogin(
  { provider, code, state }: SocialPostLoginArgs,
  shouldNotify = true
): Promise<{ ssoRedirectUrl?: Location; token?: string; errorMessage?: string }> {
  try {
    const endpoint = new URL(FRONTEGG_SOCIALS_POST_LOGIN_ENDPOINT[provider])

    endpoint.searchParams.append('state', state)
    endpoint.searchParams.append('code', code)

    const resp = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: FRONTEGG_AUTH_JSON_HEADERS
    })

    //frontegg will return a 302 redirect if google domain is SSO enabled
    if (resp.status === 302) {
      const ssoRedirectUrl = (await resp.text()) as unknown as Location
      return { ssoRedirectUrl }
    } else if (!resp.ok) {
      if (resp.status === 403) {
        const json = await resp.json()
        if (
          json?.errors?.[0] === MISSING_EMAIL_ERROR_MESSAGE &&
          provider === SocialLoginProviders.Facebook
        ) {
          const errorMessage = MISSING_EMAIL_ERROR_MESSAGE_FACEBOOK
          return { errorMessage }
        }
      }
      await logger.logFetchError(resp, {
        technicalArea: 'authentication',
        status: resp.status,
        url: endpoint.href
      })
    } else {
      const data: FronteggLoginResponse = await resp.json()
      await setAccessTokenCookie(data.accessToken)
      if (shouldNotify) {
        notifyObserversOfLogin(data.accessToken)
      }
      return { token: data.accessToken }
    }
  } catch (e: unknown) {
    logger.logError(e, { technicalArea: 'authentication', source: 'catch' })
  }
  return {}
}
