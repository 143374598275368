import {
  BATCH_TEMPLATES_FRAGMENT,
  CUSTOM_ASSIGNED_CODES_FRAGMENT,
  FLOWCODE_TEMPLATES_FRAGMENT,
  FOLDER_ANCESTORS_FRAGMENT
} from '@dtx-company/flow-codegen/src/code/fragments'
import { gql } from 'graphql-request'

const GET_FLOWCODE_SAVED_QUERY_BODY = `
  batchTemplates {
    id
    configuration
    name
  }
`

const GET_BORDERS_BODY = `
  borderDesignConfigs {
    studioConfig {
      configuration
      assetFile {
        assetName
        url
      }
      name
      id
    }
    partialId
  }
`

const GET_FLOWCODE_TEMPLATES_QUERY_BODY = `
  flowcodeTemplates {
    id
    name
    configuration
    assetFile {
      assetName
      url
    }
    isCustomizable
  }
`

export const GET_FLOWCODE_TEMPLATES_QUERY = /* GraphQL */ `
  query getFlowcodeTemplates {
    ${GET_FLOWCODE_TEMPLATES_QUERY_BODY}
  }
`

// query used on the /createflowcodepage
export const GET_COMBINED_FLOWCODE_DESIGNS_QUERY = /* GraphQL */ `
  query getFlowcodeDesigns {
    ${GET_FLOWCODE_SAVED_QUERY_BODY}
    ${GET_BORDERS_BODY}
    ${GET_FLOWCODE_TEMPLATES_QUERY_BODY}
    customAssignedCodes {
      id
      name
      configuration
      assetFile {
        assetName
        url
      }
      isCustomizable
    }
  }
`

export const GET_FLOWCODE_DIRECTORIES_QUERY = /* GraphQL */ `
  query getDirectories(
    $first: Int
    $before: String
    $after: String
    $contains: String
    $orderBy: String
    $entityId: ID
    $entityType: Int
    $parentDirectoryId: ID
  ) {
    directories(
      dirsNameContains: $contains
      orderDirs: $orderBy
      entityId: $entityId
      entityType: $entityType
      parentDirectoryId: $parentDirectoryId
    ) {
      allDirs(before: $before, after: $after, first: $first) {
        totalCount
        edges {
          node {
            id
            level
            name
            timeStamp
            batchCount
            descendantCount
            analytics {
              total {
                scans
              }
              lastScanDate
            }
            entityPermissions {
              entityId
              entityType
              entityName
              permissionName
            }
            isOwner
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`

export const GET_NAV_FLOWCODE_DIRECTORIES_QUERY = /* GraphQL */ `
  query getDirectories($orderBy: String) {
    directories(orderDirs: $orderBy) {
      allDirs {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

// TODO - combione with code details query to prevent a second request - DF
export const BATCH_RULE_QUERY = /* GraphQL */ `
  query batchRules($batchId: ID!) {
    batchRules(batchId: $batchId) {
      id
      lastModified
      rule
      batch {
        id
      }
    }
  }
`

export const FLOWCODE_DETAIL_QUERY = /* GraphQL */ `
  query flowcodeDetail(
    $id: ID!
    $numDays: Int
    $endDay: Date
    $withInterstitial: Boolean = false
    $withBatchRules: Boolean = false
  ) {
    batch(batchId: $id) {
      id
      batchRules @include(if: $withBatchRules) {
        id
        interstitial @include(if: $withInterstitial)
      }
      timeStamp
      nickname
      domain
      assetFileName
      type
      creationSource
      studioConfig {
        configuration
        isCustomizable
        source
        id
        name
      }
      directory {
        id
        name
      }
      activeLink {
        redirectValue
        shorturl
        redirectType
      }
      analytics {
        total {
          scans
          users
          avgScans
          scansToday
        }
        days(endDay: $endDay, numDays: $numDays) {
          day
          scans
          users
        }
      }
      entityPermissions {
        entityId
        entityName
        entityType
        permissionName
      }
      batchOwner {
        isOwner
        user {
          fullName
        }
      }
      assetLabels {
        id
        name
      }
    }
  }
`

export const FLOWPAGE_FLOWCODE_DOWNLOAD_DETAILS = /* GraphQL */ `
  query flowpageFlowcodeDownloadDetails($id: ID!) {
    batch(batchId: $id) {
      archived
      studioConfig {
        id
        configuration
      }
      activeLink {
        shorturl
      }
    }
  }
`

export const FETCH_FLOWCODES_QUERY = /* GraphQL */ `
  query CodeManagement(
    $orderHighlightedBy: String
    $after: String
    $contains: String
    $orderBy: String
    $withStudioConfig: Boolean = false
    $entityId: ID
    $entityType: Int
    $filterCreationSource: String
    $filterHighlightedCreationSource: String
    $allbatchesArchived: Boolean = false
    $pageType: PageType
  ) {
    myFlowcodes(
      orderHighlight: $orderHighlightedBy
      orderBatch: $orderBy
      highlightedArchived: false
      allbatchesArchived: $allbatchesArchived
      allbatchesNicknameContains: $contains
      entityId: $entityId
      entityType: $entityType
      allbatchesExcludeCreationSource: $filterCreationSource
      highlightedExcludeCreationSource: $filterHighlightedCreationSource
      pageType: $pageType
    ) {
      highlighted(first: 4) {
        edges {
          node {
            id
            nickname
            domain
            creationSource
            studioConfig {
              configuration
              id
            }
            activeLink {
              redirectValue
              shorturl
              redirectType
            }
            analytics {
              total {
                scans
              }
            }
            entityPermissions {
              entityId
              entityName
              entityType
              permissionName
            }
          }
        }
      }
      allBatches(first: 15, after: $after) {
        totalCount
        edges {
          node {
            id
            timeStamp
            lastModified
            nickname
            domain
            creationSource
            studioConfig @include(if: $withStudioConfig) {
              configuration
              id
            }
            activeLink {
              redirectValue
              shorturl
              redirectType
            }
            analytics {
              total {
                scans
              }
              lastScanDate
            }
            directory {
              id
            }
            entityPermissions {
              entityId
              entityName
              entityType
              permissionName
            }
            batchOwner {
              isOwner
            }
            assetLabels {
              id
              name
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_DIRECTORY_QUERY = /* GraphQL */ `
  query getDirectory(
    $directoryId: ID!
    $after: String
    $contains: String
    $orderBy: String
    $withStudioConfig: Boolean = false
    $withAncestors: Boolean = false
  ) {
    directory(directoryId: $directoryId) {
      parent {
        id
      }
      id
      name
      level
      analytics {
        total {
          scans
          users
          avgScans
          scansToday
        }
      }
      ancestors @include(if: $withAncestors) {
        ...FolderAncestors
      }
      batchCount
      isOwner
      user {
        ithacaId
      }
      entityPermissions {
        entityId
        entityName
        entityType
        permissionName
      }
    }
    myFlowcodes(
      directoryId: $directoryId
      orderBatch: $orderBy
      allbatchesArchived: false
      allbatchesNicknameContains: $contains
    ) {
      allBatches(first: 15, after: $after) {
        edges {
          node {
            id
            nickname
            domain
            studioConfig @include(if: $withStudioConfig) {
              configuration
              id
            }
            timeStamp
            activeLink {
              redirectValue
              shorturl
              redirectType
            }
            analytics {
              total {
                scans
              }
              lastScanDate
            }
            directory {
              id
            }
            entityPermissions {
              entityId
              entityName
              entityType
              permissionName
            }
            batchOwner {
              isOwner
            }
            assetLabels {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
  ${FOLDER_ANCESTORS_FRAGMENT}
`

export const GET_NOTIFICATION_SUBSCRIPTION_STATUSES_QUERY = /* GraphQL */ `
  query getNotificationSubscriptionStatuses($email: String!) {
    notificationSubscriptionStatuses(email: $email) {
      sendgrid
      hubspot
    }
  }
`

export const FETCH_STUDIO_CONFIG_PARTIALS = /* GraphQL */ `
  query getStudioConfigPartials(
    $replaceValues: JSONString
    $autoDesignFetchCount: Int
    $ignoreColorPriority: Boolean
    $logoImageUrl: String
    $logoHeight: Int
    $logoWidth: Int
    $logoFillSvgColor: String
  ) {
    autoDesignConfigs(
      replaceValues: $replaceValues
      count: $autoDesignFetchCount
      ignoreColorPriority: $ignoreColorPriority
      logoImageUrl: $logoImageUrl
      logoHeight: $logoHeight
      logoWidth: $logoWidth
      logoFillSvgColor: $logoFillSvgColor
    ) {
      ids
      configuration
    }
  }
`

export const GET_WHITE_LABEL_REDIRECT_DOMAINS_QUERY = gql`
  query getWhiteLabelRedirectDomains($withTimestamp: Boolean!) {
    whiteLabelRedirectDomains {
      domain
      timestamp @include(if: $withTimestamp)
    }
  }
`

export const TRANSFER_CODE_MODAL_BATCH_CONTENT_QUERY = gql`
  query transferCodeModalBatchContent($id: ID!) {
    batch(batchId: $id) {
      nickname
      type
      studioConfig {
        configuration
      }
      activeLink {
        shorturl
        redirectValue
      }
    }
  }
`

export const DUPLICATE_CODE_MODAL_BATCH_CONTENT_QUERY = gql`
  query duplicateCodeModalBatchContent($id: ID!) {
    batch(batchId: $id) {
      nickname
      domain
      assetFileName
      type
      studioConfig {
        configuration
        name
        id
        source
      }
      activeLink {
        redirectType
        redirectValue
      }
      directory {
        id
      }
      batchRules {
        id
        rule
        interstitial
      }
      assetLabels {
        id
        name
      }
    }
  }
`

export const DOWNLOAD_CODE_MODAL_BATCH_CONTENT_QUERY = gql`
  query downloadCodeModalBatchContent($id: ID!) {
    batch(batchId: $id) {
      type
      studioConfig {
        configuration
        source
      }
      activeLink {
        redirectValue
        shorturl
        redirectType
      }
    }
  }
`

export const FETCH_PLACEMENT_TEMPLATE_GROUP_WITH_BATCH_QUERY = gql`
  query placementTemplateConfigGroup($templateId: ID!, $batchId: ID, $productId: String) {
    batch(batchId: $batchId) {
      id
      nickname
      studioConfig {
        id
        configuration
      }
      activeLink {
        redirectValue
        shorturl
      }
    }
    placementTemplateConfigGroup(placementTemplateConfigId: $templateId, productId: $productId) {
      id
      name
      configuration
      category {
        name
        id
      }
      placeholderAssetFile {
        url
      }
      size
      isPrintable
      isPurchasable
      isSingleFlowcode
    }
    templatePrintStoreProducts(templateId: $templateId) {
      products {
        id
        name
        imageUrls
      }
    }
  }
`

export const FLOWCODE_IS_OWNER_DETAIL_QUERY = gql`
  query flowcodeDetail($id: ID!) {
    batch(batchId: $id) {
      batchOwner {
        isOwner
      }
    }
  }
`

export const GET_FOLDER_LEVEL_QUERY = gql`
  query getFolderLevel($directoryId: ID!) {
    directory(directoryId: $directoryId) {
      level
    }
  }
`

/**
 * Edit design page query
 * Update this query with all necessary data so we only need to perform one request.
 */
export const EDIT_DESIGN_DATA_QUERY = gql`
  query getEditFlowcodeDesignData($id: ID!) {
    batch(batchId: $id) {
      id
      type
      nickname
      assetFileName
      activeLink {
        redirectType
        redirectValue
        shorturl
      }
      studioConfig {
        configuration
        id
        source
      }
    }
    customAssignedCodes {
      ...CustomAssignedCodes
    }
    batchTemplates {
      ...BatchTemplates
    }
    flowcodeTemplates {
      ...FlowcodeTemplates
    }
  }
  ${BATCH_TEMPLATES_FRAGMENT}
  ${FLOWCODE_TEMPLATES_FRAGMENT}
  ${CUSTOM_ASSIGNED_CODES_FRAGMENT}
`

export const CREATE_FLOWCODE_FROM_URL_QUERY = /* GraphQL */ `
  query flowgo($url: String!, $count: Int!) {
    autoDesignsFromUrl(url: $url, count: $count) {
      ids
      configuration
    }
  }
`

// AutoBuilder
export const AUTO_DESIGNS_BRAND_DATA_QUERY = gql`
  query autoDesignsFromBrandData(
    $url: String!
    $count: Int = 10
    $colors: [String]
    $topRimText: String
    $bottomRimText: String
    $logos: [String]
  ) {
    autoDesignsFromBrandData(
      url: $url
      count: $count
      colors: $colors
      topRimText: $topRimText
      bottomRimText: $bottomRimText
      logos: $logos
    ) {
      ids
      configuration
    }
  }
`
