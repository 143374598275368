/**
 * Safely parses the json body of a repsonse object.
 *
 * If there is no body or the body fails to parse as json, returns undefined
 * instead of throwing an error.
 *
 * Will throw an error if the response body has already been used.
 */
// Allow any to match that response.json() returns any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function parseResponseJSONSafe<T = any>(response: Response): Promise<T | undefined> {
  if (response.bodyUsed) {
    // Mirror how response.json() throws
    throw new TypeError('body used already')
  }
  try {
    // A response only allows calling the `json()` method once so it's
    // safer to clone it first to avoid errors
    const cloned = response.clone()
    return await cloned.json()
  } catch (error) {
    // parse errors are expected in this case and just return nothing
    return undefined
  }
}
