import { getPlans } from '@dtx-company/inter-app/src/api/subscription-service/getPlans'
import { useAPISWR } from '@dtx-company/inter-app/src/hooks/useAPISWR/useAPISWR'
import { useFlowRouter } from '@dtx-company/inter-app/src/hooks/useFlowRouter'

export function useTrialsEnabled(): boolean | undefined {
  const flowRouter = useFlowRouter()
  const plansSWR = useAPISWR(
    getPlans,
    {},
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  )

  if (!plansSWR.data) return undefined

  const override = flowRouter.getString('trialsEnabled')
  if (override === 'on') return true
  if (override === 'off') return false

  const autoEnrolledPlan = plansSWR.data.find(plan => plan.autoEnroll)
  return autoEnrolledPlan ? true : false
}
