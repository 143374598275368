// In theory this file should be replaced with local apollo resolvers once we have cache persistence

export const dismissedExpKey = 'dismissedExperiences'

export function getDismissedExperiences(): string[] {
  const storage = window.localStorage.getItem(dismissedExpKey)
  return storage ? JSON.parse(storage) : []
}

export function addDismissedExperience(experience: string): void {
  if (typeof window === 'undefined') {
    return
  }
  const dismissedExperiences = getDismissedExperiences()
  if (!dismissedExperiences.includes(experience)) {
    dismissedExperiences.push(experience)
    setLocalStorageItem(dismissedExperiences)
  }
}

export function experienceWasDismissed(experience: string): boolean {
  if (typeof window === 'undefined') {
    return true
  }
  return getDismissedExperiences().includes(experience)
}

export function setLocalStorageItem(values: string[]): void {
  window.localStorage.setItem(dismissedExpKey, JSON.stringify(values))
}
