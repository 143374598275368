import { Cohort } from '@dtx-company/true-common/src/types/cohorts'
import {
  DEFAULT_COHORT,
  cohortA,
  cohortB,
  cohortC,
  cohortD,
  cohortE,
  cohortF,
  cohortG,
  cohortH,
  cohortI,
  cohortJ
} from '@dtx-company/true-common/src/constants/cohorts'
import { useAuthState } from '../useAuthState'
import { useFlowExperiment } from '../useFlowExperiment/useFlowExperiment'

// NOTE: the cohort names have a colon, like `cohort:A`, but the experiment
// values do not, like `cohortA`, so this object does that mapping - it should
// probably get phased out at some point
const CohortForVariationName: Record<string, Cohort> = {
  cohortA: cohortA,
  cohortB: cohortB,
  cohortC: cohortC,
  cohortD: cohortD,
  cohortE: cohortE,
  cohortF: cohortF,
  cohortG: cohortG,
  cohortH: cohortH,
  cohortI: cohortI,
  cohortJ: cohortJ
}

/**
 * For logged in users, wait for `isAuthChecked` and then this will be
 * their cohort.
 *
 * For logged out users, this pulls from what they were assigned in Optimizely
 * or provides the current `DEFAULT_COHORT`.
 *
 * Can be used to assign the initial cohort on signup, show cohort limits,
 * and log the cohort name to heap with any events that are interested in it.
 */
export function useCohort(isDefaultCohort?: boolean): Cohort {
  const { cohort } = useAuthState()
  const [variation] = useFlowExperiment('user_cohorts')
  if (isDefaultCohort) return DEFAULT_COHORT
  if (cohort) return cohort
  if (variation && variation in CohortForVariationName) {
    return CohortForVariationName[variation]
  }
  return DEFAULT_COHORT
}
