import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import { socialLogin } from '@dtx-company/ithaca-sdk/src'
import { useAuthCache } from '@app/authentication/AuthCache/hooks'
import { useSWRPlus } from '@dtx-company/inter-app/src/services/swrPlus'

export function useSocialLoginUrl(
  action: 'signup' | 'login',
  provider: SocialProvider
): { url: string; loaded: boolean } {
  const authCacheId = useAuthCache()?.id
  const swr = useSWRPlus(
    [provider, action, authCacheId],
    async ([provider, action, authCacheId]) => {
      const url = await socialLogin({
        provider,
        action,
        authCacheId
      })
      // Default to empty string to maintain parity with previous code and to provide
      // an indication of never having loaded (undefined) vs loaded and no data ('')
      return url || ''
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  )

  // Not using `swr.isLoading` here because it may initially not be loading _yet_ and
  // we need an indication that it is loading from the very first render until it's done
  // since this isn't used for a loading state but instead to know once something is available.
  return { url: swr.data || '', loaded: swr.data !== undefined }
}

export function useGoogleLoginUrl(action: 'signup' | 'login'): { url: string; loaded: boolean } {
  return useSocialLoginUrl(action, SocialProvider.GOOGLE)
}

export function useFacebookLoginUrl(action: 'signup' | 'login'): { url: string; loaded: boolean } {
  return useSocialLoginUrl(action, SocialProvider.FACEBOOK)
}
