import { PlanType } from '@dtx-company/true-common/src/types/planType'

export const getNextPlanType = (billingPlanType?: PlanType): PlanType | undefined => {
  const planSequence: PlanType[] = [
    PlanType.FREE,
    PlanType.PRO,
    PlanType.PRO_PLUS_ORG,
    PlanType.PRO_FLEX,
    PlanType.ENTERPRISE
  ]

  const currentPlanIndex = billingPlanType ? planSequence.indexOf(billingPlanType) : -1

  if (currentPlanIndex === -1 || currentPlanIndex === planSequence.length - 1) {
    return undefined
  }

  return planSequence[currentPlanIndex + 1]
}
