import * as React from 'react'
import { ElementType } from 'react'
import { default as MuiDivider, DividerProps as MuiDividerProps } from '@mui/material/Divider'
import { OverrideProps } from '../../types/mui.types'

export interface DividerTypeMap<P = Record<string, unknown>, D extends React.ElementType = 'hr'> {
  props: Omit<P, 'style'> &
    Pick<
      MuiDividerProps,
      'absolute' | 'children' | 'flexItem' | 'orientation' | 'textAlign' | 'variant' | 'sx'
    >
  defaultComponent: D
}
export type DividerProps<
  D extends React.ElementType = DividerTypeMap['defaultComponent'],
  P = Record<string, unknown>
> = OverrideProps<DividerTypeMap<P, D>, D>

/**
 * The Divider component is a thin line that groups content in lists and layouts.
 *
 * More info can be found at: https://mui.com/material-ui/react-divider/
 */
export const Divider = <C extends ElementType>(
  props: DividerProps<C, { component?: C }>
): JSX.Element => <MuiDivider {...props} />
