import { Button } from '../Button/Button'
import { DEFAULT_AUTOHIDE_DURATION } from './Snackbar.constants'
import { ForwardRefExoticComponent, SyntheticEvent, forwardRef, useCallback, useMemo } from 'react'
import { SnackbarCloseReason, SnackbarOrigin, SnackbarProps } from './Snackbar.types'
import { SnackbarProvider } from './Snackbar.context'
import { Typography } from '../Typography/Typography'
import CloseIcon from '@mui/icons-material/Close'
import MuiIconButton from '@mui/material/IconButton'
import MuiSnackbar from '@mui/material/Snackbar'

/**
 * Display a toast notification with a message and optional action.
 * Renders within {@link NotificationsWrapper}.
 */
export const Snackbar: ForwardRefExoticComponent<SnackbarProps> = forwardRef(
  (
    {
      action,
      anchorOrigin = { vertical: 'bottom', horizontal: 'center' } as SnackbarOrigin,
      autoHideDuration = DEFAULT_AUTOHIDE_DURATION,
      message,
      withCloseIcon,
      onClose,
      type = 'default',
      children,
      ...rest
    },
    ref
  ) => {
    const handleClose = useCallback(
      (event: SyntheticEvent | Event, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') {
          return
        }

        onClose?.(event, reason)
      },
      [onClose]
    )

    const actionComponent = useMemo(() => {
      return action ? (
        <Button
          variant="text"
          size="small"
          color="inherit"
          onClick={action.onClick}
          href={action.href}
          component={action.component}
          data-testid="fc-snackbar__action"
        >
          {action.label}
        </Button>
      ) : null
    }, [action])

    const endActionComponent = useMemo(() => {
      return withCloseIcon ? (
        <>
          {actionComponent}
          <MuiIconButton
            aria-label="close"
            data-testid="fc-snackbar__close-button"
            onClick={e => handleClose(e, 'escapeKeyDown')}
            color="inherit"
          >
            <CloseIcon />
          </MuiIconButton>
        </>
      ) : (
        action && actionComponent
      )
    }, [action, actionComponent, handleClose, withCloseIcon])

    return (
      <MuiSnackbar
        ref={ref}
        {...rest}
        autoHideDuration={autoHideDuration}
        anchorOrigin={anchorOrigin}
        action={endActionComponent}
        onClose={handleClose}
        message={!children && <Typography variant="bodySmall">{message}</Typography>}
        ContentProps={{
          role: { error: 'alert', default: 'status' }[type],
          elevation: 2
        }}
        data-testid={{ error: 'fc-snackbar--error', default: 'fc-snackbar--default' }[type]}
      >
        {children && (
          <div>
            <SnackbarProvider handleClose={handleClose}>{children}</SnackbarProvider>
          </div>
        )}
      </MuiSnackbar>
    )
  }
)
Snackbar.displayName = 'Snackbar'
