import {
  FACEBOOK_PATTERNS,
  GOOGLE_DOCS_PATTERN,
  INSTAGRAM_PATTERNS,
  LINKEDIN_PATTERNS,
  SNAPCHAT_PATTERNS,
  SPOTIFY_PATTERNS,
  TIKTOK_PATTERNS,
  TWITTER_PATTERNS,
  YOUTUBE_PATTERNS
} from '../../constants/patterns'
import { LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import { RedirectType } from '@dtx-company/flow-codegen/src/code/generated.types'
import { flowpageRoot } from '@dtx-company/true-common/src/constants/page'
import { isValidUrl } from '@dtx-company/inter-app/src/utils/isValidUrl/isValidUrl'

const venmoPattern = /^venmo:\/\//gi
const emailPattern = /^mailto:/gi
const smsPattern = /^sms:/gi
const phonePattern = /^tel:/gi
const eventPattern = /^BEGIN:VCALENDAR/gi
const wifiPattern = /^WIFI:/gi
const filePattern = /^https:\/\/(dtxsharedprodcdn|cdn.flowcode.com)/gi
const flowpagePattern = new RegExp(flowpageRoot, 'gi')

// TODO: DF - pull from flowcode utils once references to old enum are all removed
export const getRedirectType = (
  redirectValue: string,
  redirectTypeFromServer?: RedirectType
): LandingPageDestination => {
  // Return redirect type w/o checking the redirect value for types that do not need to be checked on the client - DF
  if (redirectTypeFromServer === RedirectType.Flowpage) {
    return LandingPageDestination.FLOWPAGE
  }
  if (redirectTypeFromServer === RedirectType.Url) {
    return LandingPageDestination.URL
  }
  if (redirectValue.match(venmoPattern)) {
    return LandingPageDestination.VENMO
  }

  if (redirectValue.match(phonePattern)) {
    return LandingPageDestination.PHONE
  }

  // A vcard redirectValue always contain "/api/v1/card" in the url.
  // If you're changing this pattern ensure all vCard codes in PROD would match it.
  // For more context see https://github.com/dtx-company/flow/pull/11596
  const vcardPattern = new RegExp('/api/v1/card', 'gi')
  if (redirectValue.match(vcardPattern)) {
    return LandingPageDestination.VCARD
  }

  if (redirectValue.match(emailPattern)) {
    return LandingPageDestination.EMAIL
  }

  if (redirectValue.match(smsPattern)) {
    return LandingPageDestination.SMS
  }

  if (redirectValue.match(eventPattern)) {
    return LandingPageDestination.EVENT
  }

  if (redirectValue.match(wifiPattern)) {
    return LandingPageDestination.WIFI
  }

  if (redirectValue.match(filePattern)) {
    return LandingPageDestination.FILE
  }

  if (redirectValue.match(flowpagePattern)) {
    return LandingPageDestination.FLOWPAGE
  }

  if (redirectValue.match(GOOGLE_DOCS_PATTERN)) {
    return LandingPageDestination.GOOGLE_DOC
  }

  if (INSTAGRAM_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.INSTAGRAM
  }

  if (YOUTUBE_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.YOUTUBE
  }

  if (LINKEDIN_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.LINKEDIN
  }

  if (FACEBOOK_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.FACEBOOK
  }

  if (TIKTOK_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.TIKTOK
  }

  if (TWITTER_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.TWITTER
  }

  if (SNAPCHAT_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.SNAPCHAT
  }

  if (SPOTIFY_PATTERNS.some(pattern => pattern.test(redirectValue))) {
    return LandingPageDestination.SPOTIFY
  }

  if (redirectValue && isValidUrl(redirectValue)) {
    return LandingPageDestination.URL
  }

  return LandingPageDestination.URL
}
