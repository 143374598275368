import {
  CreateFlowcodeFormContext,
  CreateFlowcodeFormContextType
} from './CreateFlowcodeFormContext'
import { CreateFlowcodeFormField, CreateFlowcodeStateActions } from './CreateFlowcodeForm.types'
import {
  LandingPageDestination,
  SocialMediaDestinations
} from '@dtx-company/inter-app/src/types/flowcode'
import {
  setBorder,
  setBorderShape,
  setColor,
  setDestination,
  setDownloadFileType,
  setLoadingCreateFlowcode,
  setRotate,
  setShortURL,
  setTemplate,
  setTheme,
  toggleTransparency
} from '../../../redux/slices/createFlowcodeSlice'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowcode'

/**
 *
 * Returns actions to update state related to creating a flowcode.
 * Heap events are also dispatched.
 *
 */
export const useCreateFlowcodeStateActions = (arg?: {
  variant?: string | null
}): CreateFlowcodeStateActions => {
  const dispatch = useDispatch()
  return {
    setTemplate: template => {
      dispatch(setTemplate(template))
      if (template?.assetFile?.assetName) {
        events.userSelectedFlowcodeTemplate(template?.assetFile?.assetName, arg?.variant)
      }
    },
    setColor: color => {
      dispatch(setColor(color))
      events.userChoseFlowcodeColor(color.label, undefined, undefined, arg?.variant)
    },
    setTheme: theme => {
      dispatch(setTheme(theme))
      events.userSelectedFlowcodeTheme(theme.label)
    },
    setDownloadFileType: fileType => {
      dispatch(setDownloadFileType(fileType))
    },
    toggleTransparency: transparent => {
      dispatch(toggleTransparency(transparent))
    },
    setDestination: destination => {
      events.userSelectedFlowcodeDestination(destination.label, destination.id)
      dispatch(setDestination(destination))
    },
    setLoadingCreateFlowcode: loading => {
      dispatch(setLoadingCreateFlowcode(loading))
    },
    setBorder: border => {
      dispatch(setBorder(border))
      if (border?.assetFile?.assetName) {
        events.userSelectedFlowcodeBorder(border.assetFile.assetName)
      }
    },
    setShortURL: url => {
      dispatch(setShortURL(url))
    },
    setBorderShape: shape => {
      dispatch(setBorderShape(shape))
    },
    setRotate: rotate => {
      dispatch(setRotate(rotate))
    }
  }
}

/**
 * Returns create flowcode form context
 */
export const useCreateFlowcodeForm = (): CreateFlowcodeFormContextType => {
  const context = useContext(CreateFlowcodeFormContext)
  if (!context) {
    throw new Error('useCreateFlowcodeForm must be used within a CreateFlowcodeFormProvider')
  }

  return context
}

const isSocialDestination = (destination: string): boolean =>
  destination === LandingPageDestination.SOCIAL_MEDIA || destination in SocialMediaDestinations

// TEMP: need to match the way mobile passes social media ids
export const useSocialMediaId = ({
  socialMediaId,
  destination
}: {
  socialMediaId: LandingPageDestination
  destination: LandingPageDestination
}): void => {
  const { setValue, watch } = useCreateFlowcodeForm()
  const subtypeId = watch('subtypeId')

  useEffect(() => {
    if (isSocialDestination(destination) && (!subtypeId || subtypeId !== socialMediaId)) {
      setValue(CreateFlowcodeFormField.SocialMediaId, socialMediaId)
    } else if (!isSocialDestination(destination) && subtypeId) {
      setValue(CreateFlowcodeFormField.SocialMediaId, '')
    }
  }, [destination, socialMediaId, setValue, subtypeId])
}
