import { AuthenticationFormProps } from '../AuthenticationForm.types'
import { Box, Typography } from '@dtx-company/design-system/src'
import { FC, useMemo } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useAuthenticationPageHeader } from './AuthenticationPageHeader.hooks'
import { useRouter } from 'next/router'
import { useTrialsEnabled } from '@app/common/src/hooks/useTrialsEnabled/useTrialsEnabled'
import Link from 'next/link'
import Trans from 'next-translate/Trans'

interface AuthenticationPageHeaderProps
  extends Pick<
    AuthenticationFormProps,
    'onToggleMode' | 'fromFlowpage' | 'isSignIn' | 'planTypeQueryParams' | 'inviteState'
  > {
  invitationDomain: string | undefined
}
export const AuthenticationPageHeader: FC<AuthenticationPageHeaderProps> = ({
  invitationDomain,
  onToggleMode,
  planTypeQueryParams,
  isSignIn,
  fromFlowpage,
  inviteState
}) => {
  const isTrialsEnabled = useTrialsEnabled()

  const router = useRouter()

  const isFreeQrGeneratorPage = router.pathname === Routes.FREE_QR_CODE_GENERATOR

  const header = useAuthenticationPageHeader({
    isSignIn,
    fromFlowpage,
    planTypeQueryParams,
    trialsEnabled: isTrialsEnabled,
    inviteState
  })

  const { cta, ctaLink, ctaLinkText } = useMemo(() => {
    if (invitationDomain) {
      return {
        ctaLink: {
          pathname: isSignIn ? Routes.SIGN_IN : Routes.SIGN_UP,
          query: {
            ...(() => {
              // This will drop the query params that force sso mode. There shouldn't be any others,
              // but if there are they'll be retained
              const { org: _org, ssoDomain: _ssoDomain, ...rest } = router.query
              return rest
            })()
          }
        },
        cta: '',
        ctaLinkText: isSignIn ? 'Sign up' : 'Sign in'
      }
    } else if (isFreeQrGeneratorPage) {
      return {}
    } else {
      return {
        ctaLink: { pathname: isSignIn ? Routes.SIGN_UP : Routes.SIGN_IN, query: router.query },
        cta: isSignIn ? 'Don’t have an account?' : 'Already have an account?',
        ctaLinkText: isSignIn ? 'Sign up' : 'Sign in'
      }
    }
  }, [isSignIn, router.query, invitationDomain, isFreeQrGeneratorPage])

  function handleSignInUpToggleClick(): void {
    if (!isSignIn) {
      fireAnalyticsEvent('Revenue_SignUpPage_Clicked_SignInButton')
    }
    if (onToggleMode) {
      onToggleMode()
    }
  }

  return (
    <Box sx={{ mb: ctaLink && cta ? 0 : 4 }}>
      <Typography
        variant={
          isFreeQrGeneratorPage
            ? { xs: 'labelLarge', md: 'headlineXSmall' }
            : { xs: 'headlineMedium', lg: 'headlineLarge' }
        }
        component={isFreeQrGeneratorPage ? 'h2' : 'h1'}
      >
        {header}
      </Typography>
      {ctaLink && cta && (
        <Box sx={{ mt: 2, mb: 6 }}>
          <Trans
            i18nKey={
              invitationDomain
                ? 'authentication:SignUpSignInSSOToggleCTA'
                : 'authentication:SignUpSignInToggleCTA'
            }
            components={{
              p: <Typography variant="labelSmall" component={'p'} color="onSurface.secondary" />,
              link: (
                <Link
                  href={ctaLink}
                  style={{ textDecoration: 'underline' }}
                  onClick={() =>
                    invitationDomain
                      ? fireAnalyticsEvent('AuthForm_DisableSSOLink_Clicked')
                      : handleSignInUpToggleClick()
                  }
                />
              )
            }}
            values={{
              ...(cta && {
                cta
              }),
              ctaLinkText
            }}
            defaultTrans={
              invitationDomain
                ? '<p>Want to <link>{{ctaLinkText}}</link> without SSO?</p>'
                : '<p>{{cta}} <link>{{ctaLinkText}}</link></p>'
            }
          />
        </Box>
      )}
    </Box>
  )
}
