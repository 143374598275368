import {
  CombinedGeneratorCampaigns,
  MutationCreateBatchArgs
} from '@dtx-company/flow-codegen/src/code/generated.types'
import { CreateBatchResponseType } from '@app/code/src/types/flowcode.types'
import { CreateFlowcodeData } from '../../../types/create-flowcode.types'
import { CreateFlowcodeReturnType } from './CreateFlowcodeForm.types'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-browser/src'
import {
  FlowcodeDownloadOptionMimeType,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { createBatch } from '@app/code/src/utils/flowcode/createBatch'
import { downloadFlowcode as downloadFlowcodeUtil } from '../../../utils/downloadFlowcode'
import { getLandingPageDestination } from '../../../utils/landing-page'
import { getNewStudioConfigIdAndUploadedCenterImageUrl } from '../../../utils/flowcode'
import { isStaticRedirectType } from '@app/code/src/utils/flowcode/isStaticRedirectType'

type FlowcodeData = Pick<
  CreateFlowcodeData,
  'selectedDestination' | 'flowcodeOptions' | 'destinationFormValues'
>

export async function createFlowcode(
  token: string | undefined,
  flowcodeData: FlowcodeData,
  flowcodeOptions: FcGeneratorOptions,
  centerImageFile?: File | null,
  partialConfigIds?: string[],
  campaignName?: MutationCreateBatchArgs['campaignName'],
  isAutoDesign?: boolean
): Promise<CreateFlowcodeReturnType> {
  const { studioConfigId, centerImageUrl } = await getStudioConfigIdAndCenterImageUrl(
    flowcodeOptions,
    centerImageFile ?? null,
    token,
    isAutoDesign
  )
  const flowcodeOptionsWithUploadedCenterImageUrl: FcGeneratorOptions = {
    ...flowcodeOptions,
    logoImageUrl: centerImageUrl
  }
  const flowcodeBatch = await getFlowcodeBatch(
    token,
    flowcodeData,
    studioConfigId,
    partialConfigIds,
    campaignName ?? undefined
  )
  const updatedFlowcodeOptions = updateFlowcodeOptionsWithBatch(
    flowcodeOptionsWithUploadedCenterImageUrl,
    flowcodeData,
    flowcodeBatch
  )
  return { flowcodeOptions: updatedFlowcodeOptions, flowcodeBatch }
}

export async function getFlowcodeBatch(
  token: string | undefined,
  flowcodeData: FlowcodeData,
  studioConfigId: string,
  partialConfigIds?: string[],
  campaignName?: CombinedGeneratorCampaigns
): Promise<CreateBatchResponseType> {
  const redirectType = flowcodeData.selectedDestination.id
  const destinationData = getDestinationData(flowcodeData)
  const batch = await createBatch({
    data: destinationData,
    redirectType,
    studioConfigId,
    isCollectible: redirectType === LandingPageDestination.SCAN_TO_OWN,
    partialConfigIds,
    campaignName,
    token
  })
  return batch
}

export function updateFlowcodeOptionsWithBatch(
  flowcodeOptions: FcGeneratorOptions,
  flowcodeData: Pick<FlowcodeData, 'selectedDestination' | 'destinationFormValues'>,
  batch: CreateBatchResponseType
): FcGeneratorOptions {
  const destinationData = getDestinationData(flowcodeData)
  const { selectedDestination } = flowcodeData
  const newFlowcodeOptionsData =
    isStaticRedirectType(selectedDestination.id) && destinationData
      ? (destinationData as string)
      : batch.shortUrl
  const updatedFlowcodeOptions = { ...flowcodeOptions, data: newFlowcodeOptionsData }
  return updatedFlowcodeOptions
}

export async function getStudioConfigIdAndCenterImageUrl(
  flowcodeOptions: FcGeneratorOptions,
  centerImageFile: File | null,
  token: string | undefined,
  isAutoDesign?: boolean
): Promise<{ studioConfigId: string; centerImageUrl: string }> {
  const result = await getNewStudioConfigIdAndUploadedCenterImageUrl(
    {
      configuration: flowcodeOptions,
      centerImage: centerImageFile,
      centerImagePreviewUrl: flowcodeOptions.logoImageUrl,
      setCenterImagePreviewUrl: () => undefined,
      isCustomizable: true,
      isAutoDesign: isAutoDesign
    },
    token
  )

  return {
    studioConfigId: result?.studioConfigId ?? '',
    centerImageUrl: result?.centerImageUrl ?? ''
  }
}

export function getDestinationData(
  flowcodeData: Pick<FlowcodeData, 'selectedDestination' | 'destinationFormValues'>
): string | File {
  const { selectedDestination, destinationFormValues } = flowcodeData
  return getLandingPageDestination({
    type: selectedDestination.id,
    values: destinationFormValues
  })
}

// TODO - can this be consolidated w/ other download flowcode utils - DF
export async function downloadFlowcode(
  fileType: FlowcodeDownloadOptionMimeType,
  flowcodeOptions: FcGeneratorOptions,
  flowcodeId: string
): Promise<void> {
  await downloadFlowcodeUtil({
    targetFileType: fileType,
    flowcodeOptions: flowcodeOptions,
    flowcodeId
  })
}
