import { AuthenticationFormFields, AuthenticationFormProps } from '../AuthenticationForm.types'
import { Domains } from '@dtx-company/true-common/src/types/domains'
import { LandingPageDestination } from '../../../types/flowcode'
import {
  NEW_SIGN_UP_ERROR_MESSAGE,
  USER_ALREADY_EXIST_ERROR_MESSAGE
} from '@dtx-company/true-common/src/constants/auth'
import { ReactNode, SetStateAction } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import { UseFormSetError } from 'react-hook-form'
import {
  auth_signin_form_emailpassword_failure,
  auth_signup_form_emailpassword_failure
} from '@dtx-company/inter-app/src/constants/authHeapEvents'
import { clearAuthFormState } from '../../../redux/slices/auth-form'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { logAndUnsubscribe } from './logAndUnsubscribe'
import { logger } from '@dtx-company/logger'
import { useAuthFormContext } from '../AuthenticationForm'
import { useBuildLoggedOutGeneratorCreateCodePayload } from '@app/code/src/components/LoggedOutCodeGeneratorV2/hooks/useLoggedOutGeneratorCreateCode.hooks'
import { useCohort } from '../../../hooks/cohorts/useCohort'
import { useCurrentPage } from '../../../redux/slices/utils'
import { useDispatch } from 'react-redux'
import { useLocgSignUp } from './useLocgSignUp'
import { useLoginExistingUser } from '../../../hooks/auth/useLogin'
import { useRouter } from 'next/router'
import { useSignUpOrSignIn } from './useSignUpOrSignIn'
import { useSignup } from '../../../hooks/auth/useSignup'
import useTranslation from 'next-translate/useTranslation'

export interface OnAuthFormSubmitArgs {
  values: AuthenticationFormFields
  setError: UseFormSetError<AuthenticationFormFields>
  setErrorAlert: (value: SetStateAction<string>) => void
  onSubmit: AuthenticationFormProps['onSubmit']
}
type OnAuthFormSubmit = (
  args: OnAuthFormSubmitArgs & { handleGeneratorCodeCreation: () => Promise<void> }
) => Promise<boolean>
type UseOnAuthFormSubmit = () => OnAuthFormSubmit
export const useOnAuthFormSubmit: UseOnAuthFormSubmit = () => {
  const signupMethod = useSignup()
  const { action } = useAuthFormContext()
  const isSignIn = action === 'sign in'
  const dispatch = useDispatch()
  const locgSignUp = useLocgSignUp()
  const signUpOrSignIn = useSignUpOrSignIn()
  const createCodeArgs = useBuildLoggedOutGeneratorCreateCodePayload()
  const { name: cohortName } = useCohort()
  const { loginExistingUser } = useLoginExistingUser()
  const { t } = useTranslation('authentication')

  const currentPage = useCurrentPage()
  const slug = currentPage?.slugName || ''

  const router = useRouter()
  const isFreeQrGeneratorPage = router.pathname === Routes.FREE_QR_CODE_GENERATOR

  const onAuthFormSubmit: OnAuthFormSubmit = async ({
    values,
    onSubmit,
    setError,
    setErrorAlert,
    handleGeneratorCodeCreation
  }) => {
    let needsActivation = false

    try {
      const isInLOCGCombinedAPI =
        createCodeArgs.selectedDestination.id !== LandingPageDestination.FILE

      // These should be broken out into separate modules with their own unit tests
      const shouldUseLOCGSignUp = isFreeQrGeneratorPage && isInLOCGCombinedAPI
      const shouldUseLOCGLegacyLogic = isFreeQrGeneratorPage && !isInLOCGCombinedAPI
      const shouldUseSignupSignin = !isFreeQrGeneratorPage

      if (shouldUseLOCGSignUp) {
        await locgSignUp({
          values,
          onSubmit,
          setError,
          setErrorAlert
        })
      }

      if (shouldUseLOCGLegacyLogic) {
        const product = slug ? Domains.FLOWPAGE : Domains.FLOWCODE

        const user = await signupMethod({
          ...values,
          product
        })

        if (!user) {
          throw new Error(t('AuthErrors.user'))
        }

        await logAndUnsubscribe({
          isSignIn: false,
          noMarketingEmails: values.noMarketingEmails,
          email: values.email,
          cohortName
        })
        await handleGeneratorCodeCreation()
      }

      if (shouldUseSignupSignin) {
        needsActivation = await signUpOrSignIn({ values, onSubmit, setError, setErrorAlert })
      }

      return needsActivation
    } catch (e) {
      let errorMessage: ReactNode = isSignIn ? t('AuthErrors.signIn') : t('AuthErrors.signUp')

      if (e.message === USER_ALREADY_EXIST_ERROR_MESSAGE) {
        errorMessage = NEW_SIGN_UP_ERROR_MESSAGE

        const signInUser = await loginExistingUser(values.email, values.password)
        const user = signInUser?.user

        if (user) {
          if (user?.needsActivation) return true

          if (onSubmit) {
            await onSubmit(user, SocialProvider.PASSWORD)
          }

          await logAndUnsubscribe({
            isSignIn: true,
            noMarketingEmails: values.noMarketingEmails,
            email: values.email,
            cohortName
          })
          // for existing users
          if (isFreeQrGeneratorPage) {
            handleGeneratorCodeCreation()
          }
          return false
        }
      }

      if (e.message.includes('recurring characters')) {
        setError('password', {
          message: t('AuthErrors.passwordRecurringChars')
        })

        // We will exit early, but we started the auth process so we need to
        // track that this attempt failed.
        fireAnalyticsEvent(
          isSignIn ? auth_signin_form_emailpassword_failure : auth_signup_form_emailpassword_failure
        )
        return false
      }

      // Make sure we have a heap event for failed auth so we can compare the
      // percentage of failures versus auth attempts.
      fireAnalyticsEvent(
        isSignIn ? auth_signin_form_emailpassword_failure : auth_signup_form_emailpassword_failure
      )

      setErrorAlert(errorMessage || e.message)
      logger.error({
        name: 'Auth form submission error',
        message: e,
        technicalArea: 'authentication',
        stack: e.stack
      })
    } finally {
      dispatch(clearAuthFormState())
    }

    return false
  }

  return onAuthFormSubmit
}
