import {
  ClientSideError,
  ClientSideErrorType,
  ServerSideError,
  getServerSideErrorType
} from '@dtx-company/logger'
import { INITIAL_SCAN_DESTINATION_FORM_STATE } from '@dtx-company/inter-app/src/constants/ScanToOwnGeneric'
import { VCardProperties } from './types'
import { countryCodeAndNumberUtils } from './utils/countryCodeAndNumberUtils'
import { getRequestHeadersWithAuthHeader } from '@dtx-company/inter-app/src/components/org/utils'
import { isEmail } from '@dtx-company/flowcode-utils/src'
import { validatePhoneNumber } from '@app/page/src/utils/main'
import { vcardEndpoint } from './constants'

export async function createVCard({
  values,
  token,
  ithacaId
}: {
  values?: VCardProperties
  token?: string
  ithacaId?: string
}): Promise<{ id: string }> {
  const headers = await getRequestHeadersWithAuthHeader(token, ithacaId)
  if (!values)
    throw new ClientSideError({
      name: 'Error Creating vCard',
      message: 'Error creating vcard.  Cannot create empty vcard',
      type: ClientSideErrorType.ClientSideValidationError
    })
  const {
    phone = '',
    phone_country_code = '',
    work_phone = '',
    work_phone_country_code = '',
    ...rest
  } = values
  const res = await fetch(vcardEndpoint, {
    method: 'POST',
    body: JSON.stringify({
      ...rest,
      phone: phone ? '+' + (phone_country_code || 1) + '-' + phone : undefined,
      work_phone: work_phone ? '+' + (work_phone_country_code || 1) + '-' + work_phone : undefined
    }),
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    }
  })

  const data = await res.json()
  if (!res.ok) {
    throw new ServerSideError({
      name: 'Error Creating vCard',
      message: data?.message || 'Error create vcard',
      endpoint: vcardEndpoint,
      type: getServerSideErrorType(res.status)
    })
  }
  return {
    id: data.id
  }
}

export const vcardIsEmpty = (values: VCardProperties): boolean => {
  return !(values && Object.values(values).some(value => Boolean(value)))
}

export const vcardHasValidProperties = (values: VCardProperties): boolean => {
  if (vcardIsEmpty(values)) return false

  // remove dashes from number during validation check
  if (
    values.phone &&
    !validatePhoneNumber(values.phone.replace(/-/gi, ''), `${values.phone_country_code || 1}`)
  ) {
    return false
  }
  if (
    values.work_phone &&
    !validatePhoneNumber(
      values.work_phone.replace(/-/gi, ''),
      `${values.work_phone_country_code || 1}`
    )
  ) {
    return false
  }

  if (values.email && !isEmail({ email: values.email })) {
    return false
  }

  return true
}

export async function getVCardProperties(
  redirectValue: string,
  token?: string
): Promise<VCardProperties> {
  const headers = await getRequestHeadersWithAuthHeader(token)

  // The vCard object id will always be the last slash-separated segment of the reidrectValue URL
  // This method of getting the vcID is perferred because when local the prefix would be 'localhost'
  // on STG it would be 'app.stg.flowcode' and so on for other ENVs
  const vcRedirectValueSplit = redirectValue.split('/')
  const vcID = vcRedirectValueSplit[vcRedirectValueSplit.length - 1]

  if (!vcID) return INITIAL_SCAN_DESTINATION_FORM_STATE.vcard
  try {
    const res = await fetch(`${vcardEndpoint}/${vcID}`, {
      method: 'GET',
      headers
    })

    const { data } = await res.json()

    const [phone_country_code, phone] = countryCodeAndNumberUtils.split(data?.phone)

    const [work_phone_country_code, work_phone] = countryCodeAndNumberUtils.split(data.work_phone)
    const {
      city,
      company,
      country,
      state,
      street2,
      street,
      email,
      first_name,
      last_name,
      title,
      zipcode,
      id,
      website
    } = data
    return {
      id,
      first_name,
      last_name,
      company,
      title,
      email,
      phone,
      work_phone,
      work_phone_country_code,
      phone_country_code,
      website,
      street,
      street2,
      city,
      state,
      zipcode,
      country
    }
  } catch (e) {
    console.error(e)
    return INITIAL_SCAN_DESTINATION_FORM_STATE.vcard
  }
}
