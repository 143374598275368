import { FlowcodeType, LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import { getRedirectType } from './getRedirectType'
import { isStaticCodeType } from './isStaticCodeType'
import { isStaticRedirectType } from './isStaticRedirectType'

export const isStaticCode = (flowcode?: FlowcodeType): boolean => {
  const redirectType = flowcode?.activeLink?.redirectType
  const redirectValue = flowcode?.activeLink?.redirectValue
  const originalRedirectType =
    redirectType === LandingPageDestination.FILE
      ? LandingPageDestination.FILE
      : getRedirectType(redirectValue || '')
  return isStaticRedirectType(originalRedirectType) || isStaticCodeType(flowcode?.type)
}
