import { LOCAL_STORAGE_REDIRECT_PATH_KEY } from '@dtx-company/true-common/src/constants/auth'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { removeLocalStorageItem } from '../utils/removeLocalStorageItem'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const authPathnames = [
  Routes.FRONTEGG_SOCIAL_REDIRECT,
  Routes.FRONTEGG_SSO_REDIRECT,
  Routes.FRONTEGG_REDIRECT,
  Routes.SIGN_IN,
  Routes.SIGN_UP
] as string[]

export function useCleanupLocalStorageOnPageLoad(): void {
  const { pathname } = useRouter()

  useEffect(() => {
    if (!authPathnames.includes(pathname)) {
      removeLocalStorageItem(LOCAL_STORAGE_REDIRECT_PATH_KEY)
    }
  }, [pathname])
}
