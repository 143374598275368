import { AuthenticationFormProps } from '../AuthenticationForm.types'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { getPlanTypeName } from '@app/common/src/utils/getPlanTypeName'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils'
import { useFlowRouter } from '@dtx-company/inter-app/src/hooks/useFlowRouter'
import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'

interface UseAuthenticationPageHeader
  extends Pick<
    AuthenticationFormProps,
    'inviteState' | 'isSignIn' | 'fromFlowpage' | 'planTypeQueryParams'
  > {
  trialsEnabled: boolean | undefined
}

export const useAuthenticationPageHeader = ({
  isSignIn,
  fromFlowpage,
  planTypeQueryParams,
  trialsEnabled,
  inviteState
}: UseAuthenticationPageHeader): string => {
  const flowRouter = useFlowRouter()
  const curPage = useCurrentPage()

  const { t } = useTranslation('authentication')

  const headingText = useMemo(() => {
    const redirectLocation = decodeURIComponent(flowRouter.getString('redirectTo') || '')
    const { pathname: redirectPathName } = new URL(redirectLocation, FLOWCODE_ROOT)
    const currentPathname = flowRouter.pathname
    const buyPlanName = planTypeQueryParams ? getPlanTypeName(planTypeQueryParams) : undefined
    if (isSignIn) {
      if (inviteState?.inviteType) {
        return t('SignInPage.inviteHeading')
      } else if (redirectPathName.includes('/buy/') && buyPlanName) {
        return t('SignInPage.purchasePlanHeading', { planTypeName: buyPlanName })
      } else {
        return t('SignInPage.baseHeading')
      }
    } else if (currentPathname === Routes.FREE_QR_CODE_GENERATOR) {
      return t('FreeQrCodeGeneratorPage.header')
    } else {
      if (inviteState?.inviteType) {
        return t('SignUpPage.inviteHeading')
      } else if (fromFlowpage && curPage?.slugName) {
        if (trialsEnabled) {
          return t('SignUpPage.createFlowpageTrialHeading')
        }
        return t('SignUpPage.createFlowpageHeading')
      } else if (redirectPathName.includes('/buy/') && buyPlanName) {
        return t('SignUpPage.purchasePlanHeading', { planTypeName: buyPlanName })
      } else {
        if (trialsEnabled) {
          return t('SignUpPage.baseTrialHeading')
        }
        return t('SignUpPage.baseHeading')
      }
    }
  }, [
    flowRouter,
    planTypeQueryParams,
    isSignIn,
    inviteState?.inviteType,
    t,
    fromFlowpage,
    curPage?.slugName,
    trialsEnabled
  ])

  return headingText
}
