import { Experiments } from '@dtx-company/true-common/src/constants/experiments'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useEffect } from 'react'
import { useExperiment } from '@optimizely/react-sdk'
import { useFlowExperimentContext as useFlowExperimentContext } from './context/useFlowExperimentContext'
import { useRouter } from 'next/router'

export type UseFlowExperimentCache = { [key: string]: ReturnType<typeof useFlowExperiment> }

/**
 * Wraps useExperiment from Optimizely with additional cache layers for
 * use during development.
 *
 * The cache may be populated via the command-line
 *  `window.flow.experiment.add(key, value, persist)`
 * or via the query param
 *  `?experiment[key]=value`
 *
 * The value should match one of the variation keys from the experiment.
 *
 * If the value is empty or missing, the hook
 * will use null to simulate the experiment being turned off.
 * Returning an empty string is not currently supported.
 *
 * Cache priority: query > session > local
 *
 * @see useExperiment
 *
 * @example
 * // ?experiment[my_key]=foo
 * useFlowExperiment('my_key') // => ['foo', true, false]
 * // ?experiment[my_key]=
 * useFlowExperiment('my_key') // => [null, true, false]
 *
 * @example
 * > flow.experiment.add('other_key', 'foo')
 * > flow.experiment.remove('other_key')
 * > flow.experiment.clear()
 * > flow.experiment.list()
 */
function useFlowExperimentActual(
  ...args: Parameters<typeof useExperiment>
): ReturnType<typeof useExperiment> {
  const context = useFlowExperimentContext()
  const cache = { ...context.localCache, ...context.sessionCache, ...context.queryCache }
  const optimizelyData = useExperiment(...args)

  const key = args[0]
  useEffect(() => {
    if (!key) return
    context.addToLiveCache(key, optimizelyData)
  }, [key, context, optimizelyData])
  if (key in cache) {
    return cache[key]
  }
  return optimizelyData
}

function useFlowExperimentStub(
  ..._args: Parameters<typeof useExperiment>
): ReturnType<typeof useExperiment> {
  return ['', true, true]
}

function useFlowExperimentFn(): (
  ...args: Parameters<typeof useExperiment>
) => ReturnType<typeof useExperiment> {
  const router = useRouter()
  const isFlowpage = router?.pathname === Routes.FLOWPAGE
  if (isFlowpage) return useFlowExperimentStub
  return useFlowExperimentActual
}

type P = Parameters<typeof useExperiment>
type RT = ReturnType<typeof useExperiment>

/**
 * Provides the state of the requested experiments.
 *
 * @see useExperiment from Optimizely
 * @see Experiments for typing
 *
 * @example
 * interface Experiments {
 *   mascot: 'astronaut' | 'jester' | 'technician' | 'zoologist'
 * }
 * const [mascotVariation] = useFlowExperiment('mascot')
 */
export function useFlowExperiment<K extends keyof Experiments>(
  experimentKey: K,
  options?: P[1],
  overrides?: P[2]
): [Experiments[K] | null, RT[1], RT[2]]
export function useFlowExperiment<K extends string>(
  experimentKey: K,
  options?: P[1],
  overrides?: P[2]
): [string | null, RT[1], RT[2]]
export function useFlowExperiment<K extends keyof Experiments | string>(
  experimentKey: K,
  options?: P[1],
  overrides?: P[2]
): [K extends keyof Experiments ? Experiments[K] | null : string | null, RT[1], RT[2]] {
  return useFlowExperimentFn()(experimentKey, options, overrides) as unknown as [
    K extends keyof Experiments ? Experiments[K] | null : string | null,
    RT[1],
    RT[2]
  ]
}
