import { AutoDesignConfigType } from '../../components/Homepage/CreateFlowcodeForm/AutoDesignButton/AutoDesignButton.utils'
import {
  BorderTemplateType,
  CreateFlowcodeStateType,
  FlowcodeColorOptionType,
  FlowcodeTemplateType,
  FlowcodeThemeType
} from '@app/common/src/types/createFlowcode.types'
import { DEFAULT_FLOWCODE_DESTINATION } from '@app/common/src/constants/studio'
import { DEFAULT_LOGO_OUTER_PADDING } from '../../constants/flowcode'
import {
  FLOWCODE_THEME_OPTIONS,
  LANDING_PAGE_DESTINATIONS,
  LOGGED_OUT_FLOWCODE_COLOR_OPTIONS
} from '@app/common/src/constants/flowcode'
import {
  FcErrorCorrectionLevel,
  FcGeneratorOptions,
  FcShape
} from '@dtx-company/flowcode-generator-browser/src'
import {
  FlowcodeDownloadOptionMimeType,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { addBorderPartialToGenOptions } from '../../components/border-selector/utils'
import { getDefaultCodeDownloadOptions } from '@app/common/src/utils/download'
import {
  getEncodedData,
  getIsFlowcodeInvertible,
  isFlowcodeCustomizable
} from '@app/common/src/utils/flowcode'
import { getRotatedOptions } from '../../containers/create-flowcode-fullpage/utils/getRotatedOptions'

interface GetFlowcodeGeneratorOptionsProps {
  theme?: FlowcodeThemeType
  color?: FlowcodeColorOptionType
  designOptions: FlowcodeTemplateType | null
  transparent: boolean
  url?: string
  shortUrl: string | null
  destinationId: LandingPageDestination
  allowCustom?: boolean
  logo?: {
    url: string
    height: number
    width: number
  }
  border?: BorderTemplateType | null
  borderShape?: FcShape
  rotate?: boolean | null
}

const getFlowcodeGeneratorOptions = ({
  theme,
  color,
  transparent,
  designOptions,
  url = '',
  shortUrl,
  destinationId,
  allowCustom,
  logo,
  border,
  rotate
}: GetFlowcodeGeneratorOptionsProps): FcGeneratorOptions => {
  const activeColor = theme?.dark && color?.darkColor ? color.darkColor : color?.color
  const dataColor = theme?.inverted ? '#FFF' : activeColor
  const backgroundColor = theme?.inverted ? activeColor : undefined
  const invertedEyeColorProps = {
    positionElementTopRight: {
      backgroundColor
    },
    positionElementTopLeft: {
      backgroundColor
    },
    positionElementBottomLeft: {
      backgroundColor
    }
  }

  const options: FcGeneratorOptions = {
    ...designOptions?.options,
    data: getEncodedData({
      url,
      shortUrl,
      id: destinationId
    }),
    transparent,
    ...(allowCustom && {
      logoImageUrl: logo?.url,
      logoHeight: logo?.height,
      logoWidth: logo?.width,
      logoOuterPadding: DEFAULT_LOGO_OUTER_PADDING,
      defaultColor: dataColor,
      alwaysUseDefaultColor: true,
      containerBackgroundColor: backgroundColor,
      gridBackgroundColor: backgroundColor
    }),
    ...(allowCustom &&
      logo?.url && {
        errorCorrectionLevel: FcErrorCorrectionLevel.H
      }),
    ...(allowCustom && theme?.inverted && invertedEyeColorProps),
    ...(allowCustom &&
      typeof rotate === 'boolean' &&
      getRotatedOptions(rotate, designOptions?.options))
  }

  if (border) return addBorderPartialToGenOptions(border, options)

  return options
}

/**
 * flowcode options based on a state
 */
export function generatorOptions(state: CreateFlowcodeStateType): FcGeneratorOptions {
  return getFlowcodeGeneratorOptions({
    allowCustom: state.isCustomizable,
    designOptions: state.template,
    theme: state.theme,
    color: state.color,
    transparent: state.transparent,
    destinationId: state.destination?.id,
    url: state.url,
    shortUrl: state.shortURL,
    logo: {
      url: state.centerImage.objectUrl,
      height: state.centerImage.height,
      width: state.centerImage.width
    },
    border: state.border,
    rotate: state.rotate
  })
}

/**
 * flowcode options for auto design based on a state
 */
function autoDesignedFlowcodeOptions(state: CreateFlowcodeStateType): AutoDesignConfigType & {
  options: FcGeneratorOptions
} {
  return {
    ...state.selectedAutoDesignConfig,
    options: {
      ...state.selectedAutoDesignConfig.options,
      logoImageUrl: state.centerImage.objectUrl,
      logoHeight: state.centerImage.height,
      logoWidth: state.centerImage.width
    }
  }
}

export type CreateFlowcodeState = CreateFlowcodeStateType

export const initialCreateFlowcodeState: CreateFlowcodeStateType = {
  options: {
    data: DEFAULT_FLOWCODE_DESTINATION
  },
  url: '',
  name: '',
  destination: LANDING_PAGE_DESTINATIONS[0],
  transparent: false,
  template: null,
  border: null,
  color: LOGGED_OUT_FLOWCODE_COLOR_OPTIONS[0],
  theme: FLOWCODE_THEME_OPTIONS[0],
  isCustomizable: true,
  shortURL: '',
  downloadFileType: FlowcodeDownloadOptionMimeType.PNG,
  displayTransparencyToggle: false,
  batchId: '',
  centerImage: {
    name: '',
    height: 0,
    width: 0,
    objectUrl: ''
  },
  flowcodeDownloadOptions: getDefaultCodeDownloadOptions({}),
  loadingCreateFlowcode: false,
  partialConfigIds: [],

  // homepage experiment
  autoDesignConfigs: [],
  selectedAutoDesignConfig: {
    configuration: '',
    ids: [],
    options: {
      data: DEFAULT_FLOWCODE_DESTINATION
    }
  },
  selectedStudioConfig: {
    id: '',
    name: '',
    configuration: '{}'
  },
  borderShape: FcShape.CIRCLE,
  rotate: null
}

type State = CreateFlowcodeStateType | null

const createFlowcodeSlice = createSlice({
  name: 'createFlowcode',
  initialState: null as State,
  reducers: {
    /**
     * set flowcode generator options, data field is required
     */
    setOptions: (state, action: PayloadAction<CreateFlowcodeStateType['options']>) => {
      if (!state) return
      state.options = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      if (!state) return
      state.name = action.payload
    },
    setDestination: (state, action: PayloadAction<CreateFlowcodeStateType['destination']>) => {
      if (!state) return
      state.destination = action.payload
    },
    toggleTransparency: (state, action: PayloadAction<boolean>) => {
      if (!state) return
      state.transparent = action.payload
    },
    setTemplate: (state, action: PayloadAction<CreateFlowcodeStateType['template']>) => {
      if (!state) return
      state.template = action.payload
      state.isCustomizable = isFlowcodeCustomizable(action.payload)
      if (!getIsFlowcodeInvertible(action.payload)) {
        state.theme = initialCreateFlowcodeState.theme
      }
      state.options = generatorOptions(state)
    },
    setShortURL: (state, action: PayloadAction<CreateFlowcodeStateType['shortURL']>) => {
      if (!state) return
      state.shortURL = action.payload
    },
    setColor: (state, action: PayloadAction<CreateFlowcodeStateType['color']>) => {
      if (!state) return
      state.color = action.payload
      state.options = generatorOptions(state)
    },
    setTheme: (state, action: PayloadAction<CreateFlowcodeStateType['theme']>) => {
      if (!state) return
      state.theme = action.payload
      state.options = generatorOptions(state)
    },
    setEncodedValue: (state, action: PayloadAction<string>) => {
      if (!state) return
      state.options.data = action.payload
    },
    setDownloadFileType: (
      state,
      action: PayloadAction<CreateFlowcodeStateType['downloadFileType']>
    ) => {
      if (!state) return
      state.downloadFileType = action.payload
    },
    // renamed due to naming conflitcts
    setFlowcodeBatchId: (state, action: PayloadAction<string>) => {
      if (!state) return
      state.batchId = action.payload
    },
    setFlowcodeCenterImage: (
      state,
      action: PayloadAction<CreateFlowcodeStateType['centerImage']>
    ) => {
      if (!state) return
      state.centerImage = action.payload
      state.options = generatorOptions(state)
      state.selectedAutoDesignConfig = autoDesignedFlowcodeOptions(state)
    },
    clearFlowcodeCenterImage: state => {
      if (!state) return
      state.centerImage = {
        name: '',
        height: 0,
        width: 0,
        objectUrl: ''
      }
      state.options = generatorOptions(state)
      state.selectedAutoDesignConfig = autoDesignedFlowcodeOptions(state)
    },
    setLoadingCreateFlowcode: (state, action: PayloadAction<boolean>) => {
      if (!state) return
      state.loadingCreateFlowcode = action.payload
    },
    setPartialConfigIds: (state, action: PayloadAction<string[]>) => {
      if (!state) return
      state.partialConfigIds = action.payload
    },
    setSelectedAutoDesignConfig: (
      state,
      action: PayloadAction<AutoDesignConfigType & { options: FcGeneratorOptions }>
    ) => {
      if (!state) return
      state.selectedAutoDesignConfig = action.payload
      state.selectedAutoDesignConfig = autoDesignedFlowcodeOptions(state)
    },
    setAutoDesignConfigs: (state, action: PayloadAction<AutoDesignConfigType[]>) => {
      if (!state) return
      state.autoDesignConfigs = action.payload
    },
    setBorder: (state, action: PayloadAction<CreateFlowcodeStateType['border']>) => {
      if (!state) return
      state.border = action.payload
      if (action.payload?.partialId) {
        state.partialConfigIds = [action.payload?.partialId]
      } else {
        state.partialConfigIds = []
      }
      state.options = generatorOptions(state)
    },
    setSelectedStudioConfig: (
      state,
      action: PayloadAction<CreateFlowcodeState['selectedStudioConfig']>
    ) => {
      if (!state) return
      state.selectedStudioConfig = action.payload
    },
    setBorderShape: (state, action: PayloadAction<CreateFlowcodeState['borderShape']>) => {
      if (!state) return
      state.borderShape = action.payload
    },
    setRotate: (state, action: PayloadAction<CreateFlowcodeState['rotate']>) => {
      if (!state) return
      state.rotate = action.payload
      state.options = generatorOptions(state)
    }
  }
})

export const {
  setOptions,
  setName,
  setDestination,
  toggleTransparency,
  setTemplate,
  setShortURL,
  setEncodedValue,
  setDownloadFileType,
  setTheme,
  setColor,
  setFlowcodeBatchId,
  setFlowcodeCenterImage,
  clearFlowcodeCenterImage,
  setLoadingCreateFlowcode,
  setPartialConfigIds,
  setBorder,
  setAutoDesignConfigs,
  setSelectedAutoDesignConfig,
  setSelectedStudioConfig,
  setBorderShape,
  setRotate
} = createFlowcodeSlice.actions

const reducer = createFlowcodeSlice.reducer
export default reducer
