import { FRONTEGG_SIGNUP_ENDPOINT, JSON_HEADERS } from './constants'
import { FronteggAuthResponse } from './types'
import { SignUpLocation } from '../auth'
import { logger } from '@dtx-company/logger'
import { notifyObserversOfSignup } from './notify'
import { setAccessTokenCookie } from './setAccessTokenCookie'

export interface SignUpArgs {
  email: string
  provider?: string
  cohort: string
  password?: string
  invitationToken?: string
  product: string | null
  skipInviteEmail?: boolean
  recaptchaToken?: string
  signupLocation: SignUpLocation
}
type SignUpRes = Promise<void | boolean | { errors: string[] }>
type SignUpWithEmailAndPassword = (arg0: SignUpArgs) => SignUpRes

export const signupWithEmailAndPassword: SignUpWithEmailAndPassword = async ({
  email,
  password,
  cohort,
  invitationToken,
  provider = 'local',
  product,
  skipInviteEmail = true,
  recaptchaToken,
  signupLocation
}) => {
  try {
    const resp = await fetch(FRONTEGG_SIGNUP_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        provider,
        skipInviteEmail,
        invitationToken,
        companyName: `My Team - ${email}`,
        // Be careful to keep the metadata slim since it becomes part of the jwt and
        // is included in cookies and request headers
        metadata: JSON.stringify({
          cohort,
          platformSix: false
        }),
        recaptchaToken
      }),
      credentials: 'include',
      headers: JSON_HEADERS
    })

    if (resp.ok) {
      const {
        authResponse: { accessToken }
      } = (await resp.json()) as { authResponse: FronteggAuthResponse }
      await setAccessTokenCookie(accessToken)
      notifyObserversOfSignup(accessToken, product || undefined, signupLocation)
      return resp.ok
    } else {
      await logger.logFetchError(resp, { technicalArea: 'authentication' })
      return await resp.json()
    }
  } catch (e: unknown) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}
