import { AssetTypes } from '@app/code/src/types/asset-types.types'
import { ClientSideError, ClientSideErrorType, cloneErrorObject, logger } from '@dtx-company/logger'
import { Domains } from '@dtx-company/true-common/src/types/domains'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { OnAuthFormSubmitArgs } from './index'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import { createFlowcodeFromFile, getFlowcodeId } from '@app/code/src/utils/flowcode'
import { downloadFlowcode } from '@app/code/src/utils/downloadFlowcode'
import { fireAnalyticsEvent } from '../../../event-tracking/helpers/fireAnalyticsEvent'
import { initialCreateFlowcodeState } from '@app/code/src/redux/slices/createFlowcodeSlice'
import { logAndUnsubscribe } from './logAndUnsubscribe'
import { signUpAndCreateCode } from '../../../hooks/auth/signUpAndCreateCode'
import { updateDownloadCodeData } from '../../../redux/slices/homePageSlice'
import { useBuildLoggedOutGeneratorCreateCodePayload } from '@app/code/src/components/LoggedOutCodeGeneratorV2/hooks/useLoggedOutGeneratorCreateCode.hooks'
import { useCallback } from 'react'
import { useCohort } from '../../../hooks/cohorts/useCohort'
import { useDispatch } from 'react-redux'

export const useLocgSignUp = (): ((arg: OnAuthFormSubmitArgs) => Promise<void>) => {
  const dispatch = useDispatch()
  const { name: cohortName } = useCohort()
  const createCodeArgs = useBuildLoggedOutGeneratorCreateCodePayload()

  return useCallback(
    async ({ values, onSubmit }: OnAuthFormSubmitArgs): Promise<void> => {
      let config = createCodeArgs
      const file = createCodeArgs.centerLogoImageFile
      let logoImageUrl: string | undefined

      if (file) {
        logoImageUrl = (
          await createFlowcodeFromFile(
            {
              data: file,
              createBatch: false,
              assetType: AssetTypes.EXTERNAL_CENTER_IMAGE
            },
            undefined
          )
        ).file

        config = {
          ...createCodeArgs,
          centerLogoImageFile: null,
          centerImageObjectURL: logoImageUrl || '',
          options: {
            ...createCodeArgs.options,
            logoImageUrl: logoImageUrl
          }
        }
      }

      const signUpCreateResp = await signUpAndCreateCode(
        {
          ...values,
          product: Domains.FLOWCODE,
          cohortName
        },
        config
      )
      await logAndUnsubscribe({
        isSignIn: false,
        noMarketingEmails: values.noMarketingEmails,
        email: values.email,
        cohortName
      })

      if (onSubmit) {
        await onSubmit(
          {
            email: values.email,
            needsActivation: false
          },
          SocialProvider.PASSWORD
        )
      }

      const accessToken = signUpCreateResp?.data.accessToken
      const flowcodeBatch = signUpCreateResp?.data.createBatchResponse || { shortUrl: '' }

      if (accessToken) {
        const flowcodeId = getFlowcodeId(flowcodeBatch?.shortUrl)
        try {
          fireAnalyticsEvent('Auth_LOCG_DownloadCode_start')
          await downloadFlowcode({
            targetFileType: initialCreateFlowcodeState.downloadFileType,
            flowcodeOptions: {
              ...config.options,
              data: flowcodeBatch.shortUrl
            },
            flowcodeId: flowcodeId // it is used only for logging
          })
          fireAnalyticsEvent('Auth_LOCG_DownloadCode_end_success')
        } catch (error) {
          fireAnalyticsEvent('Auth_LOCG_DownloadCode_end_error')
          logger.logError(
            new ClientSideError({
              ...cloneErrorObject(error),
              type: ClientSideErrorType.ClientSideError
            }),
            { technicalArea: 'authentication' }
          )
          //It is not a critical error, do not block the sign up process
        }

        // this is needed for the case is user exists and has reached the code limit.
        // The data is read from redux in PaymentStep component
        dispatch(
          updateDownloadCodeData({
            codeOptions: JSON.stringify(config.options),
            codeBatchId: flowcodeId
          })
        )

        const redirectPath = Routes.HOME

        //used on www and app subdomains, can't use router.push
        window.location.href = `${FLOWCODE_ROOT}${redirectPath}`
      } else {
        const error = new ClientSideError({
          message: 'Failed to create code access token is undefined',
          type: ClientSideErrorType.ClientSideValidationError,
          name: 'sign-up-and-create-code'
        })
        logger.logError(error, { technicalArea: 'authentication' })
        throw error
      }
    },
    [createCodeArgs, cohortName, dispatch]
  )
}
