import { ServerSideErrorType } from '@dtx-company/logger'

export enum GqlErrorCodes {
  // Error codes to check against with the backend

  // Role-Based Errors
  IS_SUPER_USER = 'IS_SUPER_USER',
  IS_STUDIO_USER = 'IS_STUDIO_USER',
  IS_PRO = 'IS_PRO',

  /// Authentication Errors
  PERMISSION_ERROR = 'PERMISSION_ERROR',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  TRANSFER_FLOWCODE_ERROR = 'TRANSFER_FLOWCODE_ERROR',

  // Custom Errors
  CODE_LIMIT_ERROR = 'CODE_LIMIT_ERROR',
  PAGE_LIMIT_ERROR = 'PAGE_LIMIT_ERROR',
  STUDIO_CONFIG_NOT_PUBLISHED = 'STUDIO_CONFIG_NOT_PUBLISHED',
  PMC_PERMISSION_ERROR = 'PMC_PERMISSION_ERROR',
  CENTER_IMAGE_SIZE_ERROR = 'CENTER_IMAGE_SIZE_ERROR',
  FILE_UPLOAD_SIZE_ERROR = 'FILE_UPLOAD_SIZE_ERROR',
  UNSAFE_FILE_UPLOAD_ERROR = 'UNSAFE_FILE_UPLOAD_ERROR'
}

export const mapGqlToFlowErrorType: Record<GqlErrorCodes, ServerSideErrorType> = {
  [GqlErrorCodes.AUTHENTICATION_ERROR]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.TRANSFER_FLOWCODE_ERROR]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.PERMISSION_ERROR]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.IS_SUPER_USER]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.IS_STUDIO_USER]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.IS_PRO]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.PMC_PERMISSION_ERROR]: ServerSideErrorType.ServerSideValidationError,
  [GqlErrorCodes.CENTER_IMAGE_SIZE_ERROR]: ServerSideErrorType.ServerSideInternalError,
  [GqlErrorCodes.STUDIO_CONFIG_NOT_PUBLISHED]: ServerSideErrorType.ServerSideInternalError,
  [GqlErrorCodes.CODE_LIMIT_ERROR]: ServerSideErrorType.ServerSideInternalError,
  [GqlErrorCodes.PAGE_LIMIT_ERROR]: ServerSideErrorType.ServerSideInternalError,
  [GqlErrorCodes.FILE_UPLOAD_SIZE_ERROR]: ServerSideErrorType.ServerSideInternalError,
  [GqlErrorCodes.UNSAFE_FILE_UPLOAD_ERROR]: ServerSideErrorType.ServerSideInternalError
}
