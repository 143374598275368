import { CashApp, Email, Phone, SMS, Venmo, WiFi } from '../../types'
import qs from 'query-string'

const CASH_APP_PREFIX = 'cash.app'

// retrieve landing page type params

export const getVenmoParams = (url: string): Venmo => {
  const { query } = qs.parseUrl(url)
  let recipients

  if (url.includes('recipients=')) {
    // Handles legacy format venmo://paycharge?txn=pay&note=<NOTE>&recipients=<USER_NAME_1>
    recipients = query?.recipients?.toString()
  } else {
    // Handles format https://venmo.com/<USER_NAME_1>,<USER_NAME_2>...?txn=<charge|pay>&note=<NOTE>&amount=<AMOUNT>
    recipients = url.split('venmo.com/')[1]?.split('?')[0]
  }

  return {
    recipients: recipients || '',
    ...(query.amount && { amount: Number(query.amount) }),
    ...(query.note && { note: query.note.toString() })
  }
}

export const getEmailParams = (url: string): Email => {
  const parsedUrl = url.replace(/mailto:/, '')
  const [email, queryString] = parsedUrl.split('?')
  const query = qs.parse(queryString)

  return {
    email,
    ...(query?.subject && { subject: query.subject.toString() }),
    ...(query?.body && { body: query.body?.toString() })
  }
}

export const getSMSParams = (url: string): SMS => {
  const parsedUrl = url.replace(/sms:/, '')
  const [phoneNumber, body] = parsedUrl.split(';?&body=')
  return {
    phoneNumber,
    message: body ? decodeURIComponent(body) : undefined
  }
}

export const getPhoneParams = (url: string): Phone => {
  const phoneNumber = url.replace(/tel:/, '')
  return {
    phoneNumber
  }
}

export const getWiFiParams = (url: string): Partial<WiFi> => {
  const parsedUrl = url.replace(/WIFI:/, '')
  const wifiFields = parsedUrl.split(';')

  const result: Record<string, string> = {}
  for (const field of wifiFields) {
    if (field === '') {
      continue
    }
    const [fieldType, value] = field.split(':')
    switch (fieldType) {
      case 'T':
        result.authenticationType = value
        break
      case 'S':
        result.ssid = value
        break
      case 'P':
        result.password = value
        break
      case 'H':
        result.hidden = value
        break
      case 'E':
        result.eapMethod = value
        break
      case 'A':
        result.anonIdentity = value
        break
      case 'I':
        result.identity = value
        break
      case 'PH2':
        result.phaseTwoMethod = value
        break
      default:
    }
  }
  return result
}

export const getCashAppParams = (url: string): CashApp => {
  const { url: parsedUrl } = qs.parseUrl(url)
  const pathnames = parsedUrl.split('/')
  const domainIndex = pathnames.indexOf(CASH_APP_PREFIX)
  const username = pathnames[domainIndex + 1]
  const amount = pathnames[domainIndex + 2]
  return {
    username,
    ...(amount && { amount: Number(amount) })
  }
}

// construct landing page

export const constructVenmoLink = (data: Venmo): string => {
  // VENMO syntax: https://venmo.com/<USER_NAME_1>,<USER_NAME_2>...?txn=<charge|pay>&note=<NOTE>&amount=<AMOUNT>
  // See https://venmo.com/paymentlinks/ for more details

  const venmoUrl = `https://venmo.com/${data.recipients}?txn=pay`
  const query = qs.stringify({
    ...(data.amount && { amount: data.amount }),
    ...(data.note && { note: data.note })
  })

  if (query.length === 0) {
    return venmoUrl
  }

  return `${venmoUrl}&${query}`
}

export const constructEmailLink = (data: Email): string => {
  const prefix = `mailto:${data.email}`
  const query = qs.stringify({
    ...(data.subject && { subject: data.subject }),
    ...(data.body && { body: data.body })
  })

  return `${prefix}?${query}`
}

export const constructSMSLink = (data: SMS): string => {
  const { message, phoneNumber } = data
  const body = message ? `;?&body=${encodeURIComponent(message)}` : ''

  return `sms:${phoneNumber}${body}`
}

export const constructPhoneLink = (data: Phone): string => {
  const { phoneNumber } = data
  return `tel:${phoneNumber}`
}

export const constructWiFiLink = (data: WiFi): string => {
  let result = 'WIFI:'
  if (data.authenticationType) result += `T:${data.authenticationType};`
  if (data.ssid) result += `S:${data.ssid};`
  if (data.password) result += `P:${data.password};`
  if (data.hidden) result += `H:${data.hidden};`
  if (data.eapMethod) result += `E:${data.eapMethod};`
  if (data.anonIdentity) result += `A:${data.anonIdentity};`
  if (data.identity) result += `I:${data.identity};`
  if (data.phaseTwoMethod) result += `PH2:${data.phaseTwoMethod};`
  return result
}

export const constructCashAppLink = (data: CashApp): string => {
  const prefix = CASH_APP_PREFIX
  const { username, amount } = data

  return amount ? `${prefix}/${username}/${amount}` : `${prefix}/${username}`
}
