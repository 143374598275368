import { EmptyNavPageContainer } from '../components/layout/EmptyNavPageContainer/EmptyNavPageContainer'
import { FC } from 'react'
import { GetServerSideProps } from 'next'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SignInPageProps } from '../containers/authentication/SigninPageContainer/SigninPageContainer.types'
import { SigninPageContainer } from '../containers/authentication/SigninPageContainer/SigninPageContainer'
import { buildServerSideProps } from '@app/common/src/utils/buildServerSideProps'
import { deserializePlanTypeQueryParam } from '@dtx-company/true-common/src/query-string-serialization/planType/deserializePlanTypeQueryParam'
import { getInviteState } from '../utils/invite'
import { removeF2RedirectCookie } from '../utils/auth'
import { signinPagePrismicDocument } from '../components/pages/SigninPage/__mocks__/signinPagePrismicDocument'
import { useRedirectOnAuthChanges } from '@dtx-company/inter-app/src/hooks/auth/useRedirectOnAuthChanges'
import Head from 'next/head'

const SigninPage: FC<SignInPageProps> = ({
  fromFlowpage,
  prismicData,
  inviteState,
  planTypeQueryParams
}) => {
  useRedirectOnAuthChanges()

  return (
    <EmptyNavPageContainer page={Routes.SIGN_IN}>
      {prismicData?.background?.url && (
        <Head>
          <link rel="preload" as="image" href={prismicData.background.url} />
        </Head>
      )}
      <SigninPageContainer
        prismicData={prismicData}
        fromFlowpage={fromFlowpage}
        inviteState={inviteState}
        planTypeQueryParams={planTypeQueryParams}
      />
    </EmptyNavPageContainer>
  )
}

export const getServerSideProps: GetServerSideProps = buildServerSideProps(async context => {
  // Determine if we are coming from flowpage or not so we can render the
  // correct text on the server-side (avoiding a hydration issue)
  const fromFlowpage = context.query.flowpage === 'true'
  const prismicData = signinPagePrismicDocument
  const ctxPlanType = context.query.planType
  const planTypeQueryParams = ctxPlanType
    ? deserializePlanTypeQueryParam(ctxPlanType.toString())
    : ''
  const inviteState = getInviteState(context)

  removeF2RedirectCookie(context)

  return {
    props: { fromFlowpage, prismicData, inviteState, planTypeQueryParams }
  }
})

export default SigninPage
