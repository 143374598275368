import { IthacaJwt } from '@dtx-company/ithaca-sdk/src/auth/types'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import {
  buildJwtFromFronteggAccessToken,
  getCurrentAccessTokenCookie,
  getCurrentImpersonationAccessTokenCookie
} from '@dtx-company/ithaca-sdk/src'
import { getCookieDomain } from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { logger } from '@dtx-company/logger'
import {
  setAuthChecked,
  setCurrentUserData,
  setImpersonatedUserData
} from '../../redux/slices/user-slice'
import { useAuthState } from '../useAuthState'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFlowRouter } from '../useFlowRouter'

//we manually handle initializing the session on these routes
const DO_NOT_RESTORE_FRONTEGG_ROUTES = [
  Routes.FRONTEGG_SOCIAL_REDIRECT,
  Routes.FRONTEGG_SSO_REDIRECT,
  Routes.FRONTEGG_REDIRECT,
  Routes.IMPERSONATION
]

function useShouldRestoreFronteggSession(): boolean {
  const isImpersonating = useAuthState().isImpersonating
  const currentRoute = useFlowRouter().pathname as Routes
  const shouldSkipFronteggSessionRestore = DO_NOT_RESTORE_FRONTEGG_ROUTES.includes(currentRoute)
  return !isImpersonating && !shouldSkipFronteggSessionRestore
}

export function useRestoreSessionFromFrontegg(): void {
  const dispatch = useDispatch()
  const shouldRestoreFronteggSession = useShouldRestoreFronteggSession()

  const restoreFronteggSession = useRestoreFronteggSession()

  return useEffect(() => {
    ;(async () => {
      const IS_FLOWCODE_DOMAIN = window.location.href.includes(getCookieDomain())
      if (IS_FLOWCODE_DOMAIN) {
        if (shouldRestoreFronteggSession) {
          await restoreFronteggSession()
        }
      }
      dispatch(setAuthChecked())
    })()
  }, [dispatch, shouldRestoreFronteggSession, restoreFronteggSession])
}

export function useRestoreFronteggSession(): () => Promise<{ authenticated: boolean }> {
  const dispatch = useDispatch()

  return useCallback(async () => {
    try {
      const [accessToken, impersonationAccessToken] = await Promise.all([
        getCurrentAccessTokenCookie(),
        getCurrentImpersonationAccessTokenCookie()
      ])

      let currentUserJwt: IthacaJwt | undefined
      let impersonationJwt: IthacaJwt | undefined

      if (accessToken) {
        currentUserJwt = await buildJwtFromFronteggAccessToken(accessToken)
      }

      if (impersonationAccessToken) {
        impersonationJwt = await buildJwtFromFronteggAccessToken(impersonationAccessToken)
      }

      if (accessToken && !currentUserJwt) {
        throw new Error('Failed to restore Frontegg session - build JWT failed')
      }

      if (impersonationAccessToken && !impersonationJwt) {
        throw new Error('Failed to restore impersonation session - build JWT failed')
      }

      if (currentUserJwt) {
        dispatch(setCurrentUserData({ data: currentUserJwt, isFronteggUser: true }))
      }

      if (impersonationJwt) {
        dispatch(setImpersonatedUserData({ data: impersonationJwt }))
      }

      return { authenticated: Boolean(currentUserJwt) || Boolean(impersonationJwt) }
    } catch (e) {
      logger.logError(e, { technicalArea: 'authentication' })
      return { authenticated: false }
    }
  }, [dispatch])
}
