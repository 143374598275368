import { DEFAULT_FLOWCODE_DESTINATION } from '../constants/studio'
import {
  EncodedDataType,
  FlowcodeTemplateType,
  FlowcodeUrlInputType
} from '../types/createFlowcode.types'
import { FLOWCODE_API_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { FLOWPAGE_FLOWCODE_DOWNLOAD_DETAILS } from '@app/code/src/gql/queries/flowcode'
import { FlowpageFlowcodeDetailsDownloadType } from '@app/code/src/types/flowcode.types'
import { getFlowcodeGeneratorRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { getIdFromEncodedString } from '@dtx-company/true-common/src/utils/strings'
import { gqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { isStaticRedirectType } from '@app/code/src/utils/flowcode/isStaticRedirectType'

export function getBatchIdFromShortUrl(shortUrl: string): string | undefined {
  const url = shortUrl.endsWith('/') ? shortUrl.slice(0, -1) : shortUrl
  return url.split('/').slice(-1).pop()?.slice(0, -4)
}
export function getFlowCodeUrl(props: FlowcodeUrlInputType): string {
  // To do: build a query string from non null values
  const studioConfigIdQuery = props?.studioConfigId
    ? `&studio_config_id=${props?.studioConfigId}`
    : ''

  const cmykImageType = props.imageType === 'eps' ? 'eps' : 'pdf'
  return props.iscmyk
    ? `${getFlowcodeGeneratorRootUrl()}/v1/flowcode/?data=${props.data}&density=${
        props.dpi
      }&download=${Number(
        props.download
      )}&imageType=${cmykImageType}&colorspace=cmyk${studioConfigIdQuery}`
    : `${getFlowcodeGeneratorRootUrl()}/v1/flowcode/?data=${props.data}&density=${
        props.dpi
      }&download=${Number(props.download)}&imageType=${props.imageType}${studioConfigIdQuery}`
}

export async function getFlowcodeImage(
  batchId: string,
  imageType?: string,
  ignoreArchivedStatus = false
): Promise<string | void> {
  // Codes that are archived will not throw an error when ignoreArchivedStatus is true. This is useful for rendering Flowcode images on Flowpages when users archive their auto generated Flowcode.
  const batchCodeDetails = ignoreArchivedStatus
    ? await getBatchCodeDetailsGql(batchId, true)
    : await getBatchCodeDetails(batchId)
  const imageTypeExt = imageType ? `&imageType=${imageType}` : ''
  if (!batchCodeDetails || (!batchCodeDetails?.studioConfigId && !batchCodeDetails.shortUrl)) {
    return
  }

  if (batchCodeDetails?.studioConfigId && batchCodeDetails.shortUrl) {
    return (
      `${getFlowcodeGeneratorRootUrl()}/v1/flowcode?studio_config_id=${
        batchCodeDetails.studioConfigId
      }&data=${batchCodeDetails.shortUrl}` + imageTypeExt
    )
  }

  if (!batchCodeDetails.shortUrl) {
    return
  }
  return (
    `${getFlowcodeGeneratorRootUrl()}/v1/flowcode?&data=${batchCodeDetails.shortUrl}` + imageTypeExt
  )
}
export async function getDynamicFlowcodeImage(batchId: string): Promise<string | void> {
  const batch = await getBatchCodeDetailsGql(batchId)

  if (!batch || (!batch?.studioConfig && !batch?.shortUrl)) {
    return
  }
  let opts = { data: batch.shortUrl }
  if (batch?.studioConfig) {
    opts = { ...JSON.parse(batch?.studioConfig), ...opts }
  }

  return `${getFlowcodeGeneratorRootUrl()}/v1/flowcode?&opts=${encodeURIComponent(
    JSON.stringify(opts)
  )}`
}

export async function getBatchCodeDetails(
  batchId: string
): Promise<{ studioConfigId: string | null; shortUrl: string } | void> {
  try {
    const data = await (
      await fetch(FLOWCODE_API_ENDPOINT + `/v1/batch/${batchId}`, {
        headers: {
          Accept: 'application/json'
        },
        method: 'GET'
      })
    ).json()
    return {
      studioConfigId: data.studio_config_id || null,
      shortUrl: data.short_url
    }
  } catch (e) {
    console.error(e)
  }
}
export async function getBatchCodeImg(flowcodeID: string): Promise<string | void> {
  try {
    const res = await fetch(getFlowcodeGeneratorRootUrl() + `/v1/embed?id=${flowcodeID}`, {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    })

    return res.text()
  } catch (e) {
    console.error(e)
  }
}

export interface GetBatchCodeDetailsGqlResponse {
  studioConfig: string | null
  shortUrl: string
  studioConfigId: string
}
export async function getBatchCodeDetailsGql(
  batchId: string,
  ignoreArchived = false
): Promise<GetBatchCodeDetailsGqlResponse | void> {
  try {
    const { batch } = await gqlFetcher<{ batch: FlowpageFlowcodeDetailsDownloadType }>(
      FLOWPAGE_FLOWCODE_DOWNLOAD_DETAILS,
      { id: batchId }
    )
    if (batch.archived && !ignoreArchived) {
      return
    }
    return {
      studioConfig: batch.studioConfig?.configuration,
      shortUrl: batch.activeLink.shorturl,
      studioConfigId: batch.studioConfig?.id ? getIdFromEncodedString(batch.studioConfig?.id) : ''
    }
  } catch (e) {
    console.error(e)
  }
}

export const isFlowcodeCustomizable = (flowcodeTemplate: FlowcodeTemplateType | null): boolean => {
  if (!flowcodeTemplate) return false
  return (
    flowcodeTemplate.isCustomizable ||
    (!!flowcodeTemplate?.options && !Object.values(flowcodeTemplate?.options).length)
  )
}

export const getIsFlowcodeInvertible = (
  flowcodeTemplate: Pick<FlowcodeTemplateType, 'configuration'> | null
): boolean => {
  if (!flowcodeTemplate) return false
  const options: FlowcodeTemplateType['options'] = flowcodeTemplate?.configuration
    ? JSON.parse(flowcodeTemplate?.configuration)
    : undefined
  return typeof options?.isInvertible === 'boolean' ? options.isInvertible : true
}

export const getEncodedData = ({
  url,
  shortUrl,
  defaultValue = DEFAULT_FLOWCODE_DESTINATION,
  id
}: EncodedDataType): string => {
  if (url && isStaticRedirectType(id)) return url
  if (shortUrl) return shortUrl
  return url || defaultValue
}
