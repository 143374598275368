import { Link as MuiLink } from '@mui/material'
import { RTLinkNode } from '@prismicio/client'
import { ReactNode } from 'react'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import Link from 'next/link'

export type PrismicLinkSerializerProps = {
  children: ReactNode
  node: RTLinkNode
  heapId?: string
}

export const PrismicLinkSerializer = ({
  children,
  node,
  heapId
}: PrismicLinkSerializerProps): JSX.Element | null => {
  if (!node.data.url) {
    return null
  }
  //default to new tab if not specified
  const target = 'target' in node.data ? node.data?.target : '_blank'
  return (
    <Link
      href={node.data.url ?? ''}
      target={target}
      rel={target === '_blank' ? 'noopener' : undefined}
      onClick={() => {
        fireAnalyticsEvent(`${heapId}_Link_Clicked`, { link: node.data.url })
      }}
    >
      <MuiLink underline="always" color="inherit" component={'span'}>
        {children}
      </MuiLink>
    </Link>
  )
}
