import { AssetTypes } from '../../types/asset-types.types'
import { CreateBatchResponseType } from '../../types/flowcode.types'
import { DEFAULT_ERROR, getGqlErrorMessage } from '@app/code/src/utils/errors'
import { FileDestinations, LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import { GqlErrorCodes, mapGqlToFlowErrorType } from '../../constants/gql-errors'
import { MutationCreateBatchArgs } from '@dtx-company/flow-codegen/src/code/generated.types'
import { ServerSideError, ServerSideErrorType, logger } from '@dtx-company/logger'
import { createFlowcode, createFlowcodeFromFile } from '../flowcode'
import { getGqlError } from '../errors'
import { getIdFromEncodedString } from '@dtx-company/true-common/src/utils/strings'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'

export async function createBatch({
  data,
  redirectType,
  redirectDomain,
  studioConfigId,
  nickname,
  isCollectible,
  staticCodeSelected = false,
  partialConfigIds = [],
  assetLabels,
  campaignName,
  token
}: {
  data: string | File
  redirectType: LandingPageDestination
  redirectDomain?: string
  studioConfigId?: string
  nickname?: string
  isCollectible?: boolean
  staticCodeSelected?: boolean
  partialConfigIds?: string[]
  assetLabels?: MutationCreateBatchArgs['assetLabels']
  campaignName?: MutationCreateBatchArgs['campaignName']
  token: string | undefined
}): Promise<CreateBatchResponseType> {
  try {
    if (redirectType in FileDestinations) {
      const uploadFileData = await createFlowcodeFromFile(
        {
          data: data as File,
          studioConfigId,
          nickname,
          assetType: AssetTypes.FILE,
          staticCodeSelected,
          partialConfigIds,
          redirectDomain,
          assetLabels
        },
        token
      )

      if (!uploadFileData.short_url) {
        throw new ServerSideError({
          name: 'createFlowcodeFromFile',
          message: 'error uploading file',
          endpoint: 'graphql/file',
          type: ServerSideErrorType.ServerSideInternalError
        })
      }

      return {
        shortUrl: uploadFileData.short_url,
        batchId: uploadFileData.batch_id
      }
    }
    const createFlowcodeData = await createFlowcode(
      {
        url: data as string,
        redirectType,
        redirectDomain,
        ...(studioConfigId && { studioConfigId }),
        nickname,
        isCollectible,
        staticCodeSelected,
        partialIds: partialConfigIds,
        assetLabels,
        campaignName
      },
      token
    )
    if (!createFlowcodeData.createBatch.ok) {
      throw new ServerSideError({
        name: 'createFlowcodeData',
        message: 'Error creating Flowcode. Please try again.',
        endpoint: 'graphql/file',
        type: ServerSideErrorType.ServerSideInternalError
      })
    }

    return {
      shortUrl: createFlowcodeData.createBatch.link.shorturl,
      batchId: getIdFromEncodedString(createFlowcodeData.createBatch.batch.id),
      nickname,
      studioConfigId
    }
  } catch (e) {
    let error = e
    if (e?.response) error = getGqlError(e, false)
    // supress default error notification for code limits, the upsell modal will handle those
    if (error.code !== 'CODE_LIMIT_ERROR') {
      const message = getGqlErrorMessage(e, DEFAULT_ERROR.message)
      sendErrorNotification(message)
    }
    if (e.type) {
      logger.logError(e, { technicalArea: 'codes' })
      throw e
    } else {
      logger.logError(
        new ServerSideError({
          ...error,
          name: 'createBatch',
          endpoint: 'graphql/file',
          type:
            mapGqlToFlowErrorType[error.code as GqlErrorCodes] ||
            ServerSideErrorType.ServerSideOtherError
        }),
        { technicalArea: 'codes' }
      )
      throw new ServerSideError({
        ...error,
        name: 'createBatch',
        endpoint: 'graphql/file',
        type:
          mapGqlToFlowErrorType[error.code as GqlErrorCodes] ||
          ServerSideErrorType.ServerSideOtherError
      })
    }
  }
}
