import { FC, ReactNode, forwardRef, useId } from 'react'
import { InputLabel } from '@mui/material'
import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps
} from '@mui/material/TextField'
import { Stack } from '../Stack/Stack'
import { variants } from '../Typography/Typography.overrides'
import InputAdornment from '@mui/material/InputAdornment'
import ReportSharpIcon from '@mui/icons-material/ReportSharp'
import clsx from 'clsx'

export type TextFieldProps = Pick<
  MuiTextFieldProps,
  | 'autoComplete'
  | 'autoFocus'
  | 'children'
  | 'defaultValue'
  | 'disabled'
  | 'error'
  | 'fullWidth'
  | 'helperText'
  | 'hiddenLabel'
  | 'id'
  | 'inputRef'
  | 'InputLabelProps'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'placeholder'
  | 'ref'
  | 'required'
  | 'type'
  | 'value'
  | 'rows'
  | 'multiline'
  | 'minRows'
  | 'maxRows'
  | 'InputProps'
  | 'inputProps'
  | 'SelectProps'
  | 'onKeyUp'
  | 'onKeyDownCapture'
> & {
  label: string
  /**
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  startAdornment?: string | JSX.Element
  endAdornment?: string | JSX.Element
  focused?: boolean
  labelAbove?: boolean
  slots?: Record<string, string | FC>
  endAdornmentPosition?: 'start' | 'end'
}

/**
 * TextField is a styled variant of MUI's TextField.
 *
 * More info can be found at: https://mui.com/material-ui/react-text-field/
 */
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      startAdornment,
      endAdornment,
      fullWidth,
      label,
      hiddenLabel,
      error,
      size = 'medium',
      InputProps,
      focused,
      inputProps,
      labelAbove,
      endAdornmentPosition,
      ...rest
    },
    ref
  ) => {
    const labelId = useId()
    const inputLabelId = `${labelId}-label`
    if (!label?.length && !hiddenLabel)
      throw new Error('A non-empty string must be provided as a label to ensure accessibility.')

    const shouldHideLabel = labelAbove || hiddenLabel || size === 'small'

    return (
      <TextInputWrapper labelAbove={labelAbove}>
        {labelAbove && (
          <InputLabel
            sx={{ textAlign: 'left', ...variants.captionMedium }}
            htmlFor={labelId}
            id={inputLabelId}
          >
            {label}
          </InputLabel>
        )}
        <MuiTextField
          ref={ref}
          id={labelAbove ? labelId : undefined}
          label={shouldHideLabel ? undefined : label}
          fullWidth={fullWidth}
          error={error}
          hiddenLabel={shouldHideLabel}
          inputProps={{
            ...inputProps,
            ...(shouldHideLabel && { 'aria-label': label })
          }}
          {...rest}
          InputProps={{
            disableUnderline: true,
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: error ? (
              <InputAdornment position="end">
                <ReportSharpIcon sx={{ color: theme => theme.palette.error.main }} />
                {endAdornment}
              </InputAdornment>
            ) : (
              endAdornment && (
                <InputAdornment position={endAdornmentPosition || 'end'}>
                  {endAdornment}
                </InputAdornment>
              )
            ),
            ...InputProps
          }}
          FormHelperTextProps={{
            role: error ? 'alert' : undefined
          }}
          className={clsx({
            'Mui-disabled': rest.disabled,
            'MuiTextField-sizeSmall': size === 'small',
            'MuiTextField-sizeMedium': size === 'medium',
            'MuiTextField-sizeLarge': size === 'large',
            focused: focused
          })}
        />
      </TextInputWrapper>
    )
  }
)

const TextInputWrapper: FC<{ children: ReactNode; labelAbove?: boolean }> = ({
  labelAbove,
  children
}) => (labelAbove ? <Stack gap={2}>{children}</Stack> : <>{children}</>)

TextField.displayName = 'TextField'

declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    standard: false
    outlined: false
  }

  interface TextFieldPropsSizeOverrides {
    large: true
  }

  interface TextFieldClasses {
    sizeLarge: string
  }
}
