import {
  DEFAULT_FLOWCODE_OPTIONS,
  DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_FONTSIZE,
  DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_OFFSET,
  DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE,
  DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
} from '../../../../LoggedOutCodeGenerator.constants'
import {
  FcColorBlack,
  FcPosition,
  FcPositionProperty,
  FcShape,
  FcText
} from '@dtx-company/flowcode-generator-browser/src'

/**
 * Create container text. Changes based on frame and shape
 */

export const createContainerText = (
  shape: FcShape,
  showFrame: boolean,
  fontColor = FcColorBlack
): FcText[] => {
  let containerTextFontSize = DEFAULT_FLOWCODE_OPTIONS.containerText[0].fontSize
  let containerTextOffset = DEFAULT_FLOWCODE_OPTIONS.containerText[0].position.offsetPercentage

  if (showFrame) {
    if (shape === FcShape.SQUARE) {
      containerTextFontSize = DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE
      containerTextOffset = DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
    } else {
      containerTextFontSize = DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_FONTSIZE
      containerTextOffset = DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_OFFSET
    }
  } else {
    if (shape === FcShape.CIRCLE) {
      containerTextFontSize = DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE
      containerTextOffset = DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
    }
  }

  return [
    new FcText(
      'PRIVACY.FLOWCODE.COM',
      'inter_medium',
      containerTextFontSize,
      fontColor,
      new FcPosition(FcPositionProperty.BOTTOM, containerTextOffset)
    )
  ]
}
