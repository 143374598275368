import { FRONTEGG_LOGIN_ENDPOINT, JSON_HEADERS } from './constants'
import { FronteggAuthResponse } from './types'
import { logger } from '@dtx-company/logger'
import { notifyObserversOfLogin } from './notify'
import { setAccessTokenCookie } from './setAccessTokenCookie'

export async function login({
  email,
  password,
  invitationToken,
  recaptchaToken
}: {
  email: string
  password: string
  invitationToken?: string
  recaptchaToken?: string
}): Promise<void | boolean | { errors: string[] }> {
  try {
    const res = await fetch(FRONTEGG_LOGIN_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        invitationToken,
        recaptchaToken
      }),
      credentials: 'include',
      headers: JSON_HEADERS
    })

    if (res.ok) {
      const { accessToken }: FronteggAuthResponse = await res.json()
      await setAccessTokenCookie(accessToken)
      notifyObserversOfLogin(accessToken)
      return true
    }

    const resp = await res.json()

    if (resp.errors) {
      return { errors: resp.errors }
    }

    await logger.logFetchError(resp, { technicalArea: 'authentication' })

    return false
  } catch (e: unknown) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}
