import { LinkInfoFragment, adminPageInfoFrag, allPageInfoFrag, leanPageInfoFrag } from './fragments'
import { gql } from 'graphql-request'

export const GET_BASE_TEMPLATE = gql`
  query getBaseTemplate($pageId: ID!) {
    getBaseTemplate(pageId: $pageId) {
      id
      name
      page {
        profileImage
        theme {
          id
        }
      }
      isInheritable
    }
  }
`

export const GET_UNAUTH_PAGE = gql`
  query unauthPage(
    $slug: String!
    $activeOnly: Boolean
    $includeDeleted: Boolean
    $domainServiceSubdomain: String = null
  ) {
    unauthPage(
      slug: $slug
      activeOnly: $activeOnly
      includeDeleted: $includeDeleted
      domainServiceSubdomain: $domainServiceSubdomain
    ) {
      id
      displayName
      email
      profileImage
      caption
      active
      pageType
      slugName
      verified
      share
      isIndexed
      domain {
        domain
      }
      links {
        ...LinkInfo
      }
      theme {
        id
        primaryColor
        style
        profileImageMaskType
        backgroundImgUrl
        linkStyle
        linkShape
        linkColor
        fontColor
        borderColor
        textSize
        shadowColor
        titleColor
        fontFamily
      }
      slots {
        data
        id
        slotMeta {
          type
          id
        }
      }
    }
  }
  ${LinkInfoFragment}
`

export const GET_PAGE_DETAILS = gql`
  query GetPageDetails($id: ID!) {
    Page(id: $id) {
      ...AllPageInfo
    }
  }
  ${allPageInfoFrag}
`

export const GET_ADMIN_PAGE_DETAILS = gql`
  query GetAdminPageDetails($id: ID!, $includeDeleted: Boolean) {
    Page(id: $id, includeDeleted: $includeDeleted) {
      ...AdminPageInfo
    }
  }
  ${adminPageInfoFrag}
`

export const GET_UNAUTH_PROFILE_IMAGE = gql`
  query unauthPageImage($slug: String!) {
    unauthPage(slug: $slug) {
      profileImage
    }
  }
`

export const SLUG_RESERVED = gql`
  query slugReserved($slug: String!, $domainServiceSubdomainId: String = null) {
    slugReserved(slug: $slug, domainServiceSubdomainId: $domainServiceSubdomainId)
  }
`

export const VERIFIED_STATUS = gql`
  query VerifiedStatus($slug: String!) {
    unauthPage(slug: $slug) {
      verified
    }
  }
`

export const CAPTURED_CONTACT_INFO = gql`
  query CapturedContactInfo($linkId: String!) {
    CapturedContactInfo(linkId: $linkId) {
      email
      phone
      displayName
      custom {
        value
      }
      custom2 {
        value
      }
      gender
      age
      address {
        streetAddress1
        streetAddress2
        city
        region
        postalCode
      }
      capturedAt
      displayData {
        title
        description
        order
      }
    }
  }
`

export const PAGE_ANALYTICS = gql`
  query getPageAnalytics($pageId: ID!) {
    getPageAnalytics(pageId: $pageId) {
      visitorsTotal
      monthlyVisitors
      weeklyVisitors
      uniqueVisitors
      uniqueMonthlyVisitors
      uniqueWeeklyVisitors
      totalScans
      monthlyScans
      weeklyScans
      totalClicks
      monthlyClicks
      weeklyClicks
      unattributedLinkStats {
        title
        provider
        type
        order
        thumbNailImgUrl
        clicksTotal
        monthlyClicks
        weeklyClicks
      }
      linkStats {
        title
        provider
        type
        order
        thumbNailImgUrl
        clicksTotal
        monthlyClicks
        weeklyClicks
      }
    }
  }
`

export const GET_SLOTS = gql`
  query slot($pageId: ID!, $type: String) {
    slots(pageId: $pageId, type: $type) {
      id
      data
      slotMeta {
        type
      }
    }
  }
`

export const GET_SLUG_SUGGESTIONS = gql`
  query slugSuggestions($seed: String!, $vertical: String) {
    slugSuggestions(seed: $seed, vertical: $vertical)
  }
`
export const GET_OG_INFO_FROM_URL = gql`
  query getOgInfoFromUrl($url: String!) {
    ogInfo(url: $url) {
      ogTitle
      ogDescription
      ogImage {
        url
      }
    }
  }
`

export const GET_PAGE_PERMISSION = gql`
  query pagePermission($pageId: ID!) {
    checkPageOwnership(pageId: $pageId)
  }
`

export const ACCOUNT_HAS_HUBSPOT = gql`
  query accountHasHubSpot {
    hasHubSpotIntegration
  }
`

export const GET_FLOWPAGE_ASSET_LABELS = gql`
  query getFlowpageAssetLabels($pageId: ID!) {
    getPageAssetTags(pageId: $pageId) {
      id
      tagName
    }
  }
`

export const GET_USER_ASSET_TAGS = gql`
  query getUserAssetTags {
    getUserAssetTags {
      id
      tagName
    }
  }
`

export const GET_RECOMMENDED_ASSET_TAGS = gql`
  query getRecommendedAssetTags {
    getRecommendedAssetTags
  }
`
export const HIGHLIGHTED_FLOWPAGES = gql`
  query highlightedFlowpages($first: Float) {
    me {
      pageConnection(first: $first) {
        edges {
          theme {
            profileImageMaskType
          }
          displayName
          slugName
          profileImage
          id
          domain {
            domain
          }
        }
      }
    }
  }
`
export const PAGE_MANAGEMENT = gql`
  query pageManagementMe(
    $ithacaFilter: IthacaFilter
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $before: String
    $after: String
    $first: Float
    $last: Float
    $where: PageSearch
  ) {
    me {
      pageConnection(
        ithacaFilter: $ithacaFilter
        orderBy: $orderBy
        orderDirection: $orderDirection
        before: $before
        after: $after
        first: $first
        last: $last
        where: $where
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          ...LeanPageInfo
          theme {
            profileImageMaskType
          }
        }
      }
    }
  }
  ${leanPageInfoFrag}
`

export const ACCOUNT_INFO = gql`
  query accountInfoMe {
    me {
      id
      email
      googleSheetsCode
      hubspotApiKey
      salesforceCode
      salesforceDomain
      createdAt
    }
  }
`

export const TOTAL_PAGE_COUNT = gql`
  query pageCount($ithacaFilter: IthacaFilter! = OWNED) {
    me {
      pageConnection(ithacaFilter: $ithacaFilter) {
        totalCount
      }
    }
  }
`

export const GET_UNAUTH_PAGE_ID = gql`
  query unauthPageId($slug: String!, $domainServiceSubdomain: String = null) {
    unauthPage(slug: $slug, domainServiceSubdomain: $domainServiceSubdomain) {
      id
      email
    }
  }
`

export const GET_FLOWPAGE_SUBDOMAINS = /* GraphQL */ `
  query getSubdomains {
    getSubdomains {
      domain
      id
      createdAt
      isCoBranded
    }
  }
`

export const GET_FLOWPAGE_SUBDOMAIN_PERMISSIONS = /* GraphQL */ `
  query getAssetDetails($assetIds: [String!]!, $assetType: AssetTypes!) {
    getAssetDetails(assetIds: $assetIds, assetType: $assetType) {
      assetId
      result {
        success
        error
      }
      entityPermissions {
        entityId
        entityType
        permissionName
        entityName
      }
    }
  }
`

export const GET_PAGES_FOR_SUBDOMAINS = gql`
  query getPagesForSubdomains($subdomainIds: [ID!]!) {
    getPagesForSubdomains(subdomainIds: $subdomainIds) {
      id
      slugName
    }
  }
`

// PERMISSIONS
export const GET_PAGE_TEMPLATE_PERMISSIONS = gql`
  query getPageTemplatePermissions(
    $assetType: AssetTypes!
    $assetIds: [String!]!
    $templateId: ID!
    $entityTypes: [EntityType!]
  ) {
    getAssetDetails(assetType: $assetType, assetIds: $assetIds, entityTypes: $entityTypes) {
      entityPermissions {
        entityId
        entityName
        entityType
        permissionName
      }
    }
    template(id: $templateId) {
      owner {
        displayName
        email
        ithacaId
      }
    }
  }
`

export const GET_FLOWPAGE_DOMAIN_MANAGEMENT_DETAILS = gql`
  query domainManagement {
    getDomainManagementDetails {
      id
      domain
      type
      deletedAt
      flowpageSubdomains {
        id
        domain
        isCoBranded
        productType
        createdAt
        deletedAt
        isVerified
      }
    }
  }
`

// TEMPLATES
export const GET_ASSET_DETAILS = gql`
  query getTemplateAssetDetails(
    $assetType: AssetTypes!
    $assetIds: [String!]!
    $entityTypes: [EntityType!]
  ) {
    getAssetDetails(assetType: $assetType, assetIds: $assetIds, entityTypes: $entityTypes) {
      entityPermissions {
        entityId
        entityName
        entityType
        permissionName
      }
    }
  }
`

export const GET_PAGE_TEMPLATE = gql`
  query getPageTemplate($id: ID!) {
    template(id: $id) {
      id
      name
      isViewer
      description
      isOwner
      isEditor
      modifiedAt
      position
      owner {
        displayName
        email
        ithacaId
      }
      isInheritable
      page {
        ...AllPageInfo
      }
      data {
        flowcodeDesigns {
          studioConfigId
          default
          name
        }
      }
    }
  }
  ${allPageInfoFrag}
`

export const GET_PAGE_TEMPLATES_COUNT = gql`
  query getPageTemplatesCount($ithacaFilter: IthacaFilter! = OWNED, $removeDisplayToAll: Boolean) {
    templates(ithacaFilter: $ithacaFilter, removeDisplayToAll: $removeDisplayToAll) {
      totalCount
    }
  }
`

export const GET_FLOWPAGE_TEMPLATE_CHILD_PAGES = gql`
  query getTemplateChildPages(
    $id: ID!
    $after: String
    $before: String
    $first: Float
    $last: Float
    $orderBy: String
    $orderDirection: ORDER_DIRECTION! = DESC
  ) {
    template(id: $id) {
      name
      childPages(
        after: $after
        before: $before
        first: $first
        last: $last
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        totalCount
        edges {
          id
          slugName
        }
      }
    }
  }
`

export const GET_FLOWPAGE_TEMPLATES = gql`
  query getFlowpageTemplates(
    $where: TemplateSearch
    $ithacaFilter: IthacaFilter! = OWNED
    $orderBy: String
    $orderDirection: ORDER_DIRECTION! = DESC
    $before: String
    $after: String
    $first: Float
    $last: Float
    $entityTypeFilter: EntityType
    $entityTypesFilter: [EntityType!]
    $removeDisplayToAll: Boolean = false
    $includeDeleted: Boolean = false
  ) {
    templates(
      where: $where
      ithacaFilter: $ithacaFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
      before: $before
      after: $after
      first: $first
      last: $last
      entityTypeFilter: $entityTypeFilter
      entityTypesFilter: $entityTypesFilter
      removeDisplayToAll: $removeDisplayToAll
      includeDeleted: $includeDeleted
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        id
        name
        description
        isInheritable
        position
        createdAt
        modifiedAt
        displaySimpleCard
        isAssigned
        isOwner
        isEditor
        isViewer
        displayToAll
        data {
          flowcodeDesigns {
            studioConfigId
            default
            name
          }
        }
        permission {
          entityId
          entityName
          entityType
          permissionName
        }
        page {
          ...AllPageInfo
        }
        owner {
          ithacaId
        }
      }
      totalCount
    }
  }
  ${allPageInfoFrag}
`

export const GET_FLOWCODE_ID_FROM_PAGE = gql`
  query getFlowcodeIdFromFlowpage($slug: String!) {
    unauthPage(slug: $slug) {
      flowcodeId
    }
  }
`

export const GET_CONTACT_COLLECTION_MANAGEMENT_ROW = gql`
  query getContactCollectionManagementRows {
    getContactCollectionManagementRows {
      linkId
      linkTitle
      linkDescription
      linkCreatedAt
      linkLastModifiedAt
      pageId
      pageSlug
      pageTitle
    }
  }
`

// AutoBuilder
export const AUTO_DESIGNS_FP_QUERY = gql`
  query autoDesignPages(
    $useCaseId: String!
    $count: Int = 10
    $colors: [String!]
    $logos: [String!]
  ) {
    autoDesignPages(useCaseId: $useCaseId, count: $count, colors: $colors, logos: $logos) {
      templateId
      profileImageUrl
      page {
        ...AllPageInfo
      }
    }
  }
  ${allPageInfoFrag}
`
