import { ImageDimensionType } from '../types/image.types'

export const getImageDimensions = async (src: File): Promise<ImageDimensionType> => {
  return new Promise((resolve, reject) => {
    const blob = URL.createObjectURL(src)
    const img = document.createElement('img')
    img.onload = function () {
      const { width, height } = img
      URL.revokeObjectURL(blob)
      resolve({
        width,
        height
      })
    }
    img.onerror = reject
    img.src = blob
  })
}
