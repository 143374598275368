import { SUBSCRIPTION_SERVICE_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { SubscriptionServiceQuery } from './types/SubscriptionServiceQuery'
import { SubscriptionServiceRequest } from './types/SubscriptionServiceRequest'
import { SubscriptionServiceResponse } from './types/SubscriptionServiceResponse'
import { parseResponseJSONSafe } from '@dtx-company/true-common'

function queryToSearchString(query: SubscriptionServiceQuery): string {
  const parts: string[] = []

  // NOTE: could use URLSearchParams but we want finer control to do things
  // like remove undefined values and could be opinionated of how arrays are
  // formatted once supported in the future
  const keys = Object.keys(query)
  keys.forEach(key => {
    const value: string | undefined | boolean | string[] = query[key]
    if (value === undefined) return
    const valueToEncode = Array.isArray(value) ? value.join(',') : value
    const encodedKey = window.encodeURIComponent(key)
    const encodedValue = window.encodeURIComponent(valueToEncode)
    parts.push(`${encodedKey}=${encodedValue}`)
  })

  return parts.join('&')
}

function bodyToJson(body: SubscriptionServiceRequest['body']): string | undefined {
  if (!body) return
  if (typeof body === 'string') return body
  return JSON.stringify(body)
}

/**
 * Calls the given subscription service api endpoint, handling
 * aspects common to the subscription service.
 *
 * May be used as a fetcher for useSWR or by itself for SSR.
 *
 * @docs https://github.com/dtx-company/subscription-service
 */
export async function fetchSubscriptionServiceUrl<TJson>(
  request: SubscriptionServiceRequest
): Promise<SubscriptionServiceResponse<TJson>> {
  //TODO: rename request.url to request.path to be more accurate
  let url = `${SUBSCRIPTION_SERVICE_ENDPOINT}${request.url}`
  if (request.query) {
    url = `${url}?${queryToSearchString(request.query)}`
  }
  const headers: Record<string, string> = {
    ['Accept']: 'application/json',
    ['Content-Type']: 'application/json'
  }
  if (request.token) {
    headers['Authorization'] = `Bearer ${request.token}`
  }
  const body = bodyToJson(request.body)
  const response = await fetch(url.toString(), {
    method: request.method || 'GET',
    headers,
    body
  })
  return {
    json: await parseResponseJSONSafe<TJson>(response),
    status: response.status,
    ok: response.ok
  }
}
