import { FC } from 'react'
import { FLOWCODE_MARKETING_BASE_URL, Routes } from '@dtx-company/true-common/src/constants/routes'
import { Link, Stack } from '@dtx-company/design-system/src'
import FlowcodeLogo from '../../assets/vectors/flowcode-logo.svg?component'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export interface AuthTopNavProps {
  secondarySlotHiddenOnMobile?: boolean
  color?: string
  isSignin?: boolean
}

export const AuthTopNav: FC<AuthTopNavProps> = ({
  secondarySlotHiddenOnMobile,
  color,
  isSignin = false
}) => {
  const { t } = useTranslation('authentication')
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: { xs: secondarySlotHiddenOnMobile ? 'unset' : color, md: 'unset' },
        'a:first-of-type': {
          display: 'flex',
          alignItems: 'center'
        }
      }}
    >
      <a
        href={FLOWCODE_MARKETING_BASE_URL}
        data-testid="homepage"
        data-heap-id={
          isSignin ? 'AuthTopNav_SignInPage_FlowcodeLogo' : 'AuthTopNav_SignUpPage_FlowcodeLogo'
        }
        aria-label="Flowcode homepage"
      >
        <FlowcodeLogo width="10.5rem" data-testid="Flowcode logo" />
      </a>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        sx={{
          color: {
            xs: secondarySlotHiddenOnMobile ? 'unset' : color,
            md: color
          },
          a: { color: 'inherit', textDecorationColor: 'inherit' },
          button: {
            mr: -2
          }
        }}
      >
        {isSignin ? (
          <Link
            href={Routes.SIGN_UP}
            component={NextLink}
            variant="labelLarge"
            data-heap-id="AuthTopNav_SignInPage_SignUpLink"
          >
            {t('SignUp')}
          </Link>
        ) : (
          <Link
            href="https://www.flowcode.com/contact-sales"
            variant="labelLarge"
            data-heap-id="AuthTopNav_SignUpPage_ContactSalesLink"
          >
            {t('ContactSales')}
          </Link>
        )}
      </Stack>
    </Stack>
  )
}
