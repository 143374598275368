import { LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'

/**
 *
 * Updates value for scan destinations that do not construct full URLs.
 *
 */
export function constructScanDestinationLink(value: string, type: LandingPageDestination): string {
  if (value.startsWith('@')) {
    switch (type) {
      case LandingPageDestination.INSTAGRAM:
        return `https://instagram.com/${value.slice(1)}`
      case LandingPageDestination.VENMO:
        return value.slice(1)
      case LandingPageDestination.TWITTER:
        return `https://x.com/${value.slice(1)}`
      case LandingPageDestination.YOUTUBE:
        // Youtube URLs allow @ symbols as part of their structure
        return `https://youtube.com/${value}`
    }
  }

  return value
}
