import { Box, Stack } from '@dtx-company/design-system/src'
import { CustomHead } from '../../head'
import { FC, ReactNode } from 'react'
import { NotificationsWrapper } from '@dtx-company/inter-app/src/components/NotificationsWrapper'
import { useHeapScrollTracking } from '@dtx-company/inter-app/src/event-tracking/hooks/useHeapScrollTracking'
import { useHeapTranslateTracking } from '@dtx-company/inter-app/src/event-tracking/hooks/useHeapTranslateTracking'

interface BaseEmptyContainerProps {
  children: ReactNode
  page: string
  injectedMeta?: Record<string, any>
  header?: ReactNode
  sideNavigation?: ReactNode
  bottomNavigation?: ReactNode
  stickyHeader?: boolean
  stickySideNavigation?: boolean
}

export const EmptyNavPageContainer: FC<BaseEmptyContainerProps> = ({
  children,
  page,
  injectedMeta,
  header,
  sideNavigation,
  bottomNavigation,
  stickyHeader,
  stickySideNavigation
}) => {
  // connect global tracking hooks The same as for BaseContainer component
  useHeapScrollTracking(page)
  useHeapTranslateTracking()

  return (
    <>
      <NotificationsWrapper />
      <CustomHead page={page} injectedMeta={injectedMeta} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          height: stickySideNavigation ? '100vh' : 'auto',
          width: '100%',
          position: 'relative'
        }}
      >
        {header && (
          <Box
            sx={{ position: stickyHeader ? 'sticky' : 'relative', top: 0, zIndex: 1000 }}
            data-testid="header-container"
            role="navigation"
          >
            {header}
          </Box>
        )}
        <Stack
          direction={'row'}
          sx={{
            position: 'relative',
            flexGrow: 2,
            overflow: stickySideNavigation ? 'hidden' : 'auto'
          }}
        >
          {sideNavigation && (
            <Box
              role="navigation"
              data-testid="sidenavigation-container"
              sx={{ width: 'auto', flex: '0 0 0', position: 'sticky', top: 0 }}
            >
              {sideNavigation}
            </Box>
          )}
          <Box
            role={'main'}
            sx={{
              position: 'relative',
              flex: '2 0 0',
              overflow: stickySideNavigation ? 'auto' : 'visible'
            }}
          >
            {children}
          </Box>
          {bottomNavigation && (
            <Box role="navigation" data-testid="bottomnavigation-container">
              {bottomNavigation}
            </Box>
          )}
        </Stack>
      </Box>
    </>
  )
}
