import { Box } from '@dtx-company/design-system/src/components/Box/Box'
import { Button } from '@dtx-company/design-system/src'
import { FC, SyntheticEvent, useEffect } from 'react'
import { RootState } from '../../redux/types'
import { Snackbar, SnackbarCloseReason } from '@dtx-company/design-system/src/components/Snackbar'
import { SnackbarContent } from '@mui/material'
import {
  setCurrentSnack,
  setOpen,
  setSnacks
} from '../../redux/slices/notifications/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useShouldShowBottomMobileNavBar } from '../../hooks/useShouldShowBottomMobileNavBar/useShouldShowBottomMobileNavBar'
import CloseIcon from '@mui/icons-material/Close'
import Link from 'next/link'
import MuiIconButton from '@mui/material/IconButton'
import isEqual from 'lodash/isEqual'

/**
 * Set up the notifications component so that the notifications utility can
 * later use it for global notifications. This can be added on a per-page basis
 * so that pages that don't need notifications don't need to download this code
 * and benefit from a small performance improvement.
 */
export const NotificationsWrapper: FC = () => {
  const snacks = useSelector((state: RootState) => state.notificationsReducer.snacks, isEqual)
  const isOpen = useSelector((state: RootState) => state.notificationsReducer.isOpen)
  const currentSnack = useSelector(
    (state: RootState) => state.notificationsReducer.currentSnack,
    isEqual
  )

  const dispatch = useDispatch()
  const shouldShowBottomNavBar = useShouldShowBottomMobileNavBar()

  useEffect(() => {
    if (snacks?.length && !currentSnack) {
      // Set a new snack when we don't have an active one
      dispatch(setCurrentSnack({ ...snacks[0] }))
      dispatch(setSnacks(snacks.slice(1)))
      dispatch(setOpen(true))
    } else if (snacks?.length && currentSnack && isOpen) {
      // Close an active snack when a new one is added
      dispatch(setOpen(false))
    }
  }, [dispatch, currentSnack, isOpen, snacks])

  const handleClose = (_event: SyntheticEvent | Event, _reason?: SnackbarCloseReason): void => {
    dispatch(setOpen(false))
  }

  const handleExited = (): void => {
    dispatch(setCurrentSnack(undefined))
  }

  return (
    <>
      <Box
        sx={theme => ({
          '.MuiSnackbar-root': {
            ...(shouldShowBottomNavBar && { bottom: theme.spacing(20) })
          }
        })}
      >
        <Snackbar
          key={currentSnack ? currentSnack.key : undefined}
          open={isOpen}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          autoHideDuration={currentSnack?.autoHideDuration}
          type={currentSnack?.type}
          withCloseIcon={currentSnack?.withCloseIcon}
        >
          <SnackbarContent
            message={currentSnack?.message}
            role={currentSnack?.type === 'error' ? 'alert' : 'status'}
            action={
              <>
                {currentSnack?.action?.href ? (
                  <Button
                    component={Link}
                    color="inherit"
                    href={currentSnack.action.href}
                    variant="text"
                  >
                    {currentSnack.action.label}
                  </Button>
                ) : undefined}
                {currentSnack?.withCloseIcon && (
                  // MuiIconButton to keep it consistent with the Snackbar close icon
                  <MuiIconButton
                    aria-label="close"
                    data-testid="fc-snackbar__close-button"
                    onClick={e => handleClose(e, 'escapeKeyDown')}
                    color="inherit"
                  >
                    close
                    <CloseIcon />
                  </MuiIconButton>
                )}
              </>
            }
          />
        </Snackbar>
      </Box>
    </>
  )
}
