import { ClientSideError, ClientSideErrorType, logger } from '@dtx-company/logger'
import { DEFAULT_DPI } from '@dtx-company/flowcode-generator-browser/src/generateFlowcode.constant'
import { DEFAULT_FILE_NAME } from '@app/studio/src/constants/common'
import { DownloadFlowcodeType } from '../types/flowcode.types'
import { FlowcodeDownloadOptionMimeType } from '@dtx-company/inter-app/src/types/flowcode'
import { fileReaderDownload } from './flowcode'
import { generateFlowcode } from '@dtx-company/flowcode-generator-browser/src/generateFlowcode'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowcode'

// TODO - figure out why there are so many downloadFlowcode duplicates - DF
export const downloadFlowcode = async ({
  targetFileType,
  filename,
  flowcodeOptions,
  dpi = DEFAULT_DPI,
  flowcodeId,
  onDownload
}: DownloadFlowcodeType): Promise<void> => {
  return new Promise((resolve, reject) => {
    generateFlowcode(flowcodeOptions, dpi, targetFileType)
      .then(response => response.blob())
      .then(async blob => {
        await fileReaderDownload(filename || 'flowcode', targetFileType, blob)
        events.userDownloadedFlowcode(flowcodeId, targetFileType)
        onDownload?.()
        resolve()
      })
      .catch(e => {
        logger.logError(
          new ClientSideError({
            name: 'Flowcode download error',
            message: e.message,
            type: ClientSideErrorType.ClientSideError
          }),
          { team: 'PLG' }
        )
        if (
          targetFileType === FlowcodeDownloadOptionMimeType.PNG ||
          targetFileType === FlowcodeDownloadOptionMimeType.JPG
        ) {
          generateFlowcode(flowcodeOptions, dpi, FlowcodeDownloadOptionMimeType.SVG).then(
            async response => {
              const blob = await response.blob()
              downloadSvgAsPng(blob, filename || DEFAULT_FILE_NAME, Number(dpi), targetFileType) // Specify desired DPI (e.g., 300)
              events.userDownloadedFlowcode(flowcodeId, targetFileType)
              onDownload?.()
              resolve()
            }
          )
        }
      })
  })
}

const downloadSvgAsPng = (
  svgBlob: Blob,
  fileName: string,
  dpi = Number(DEFAULT_DPI),
  fileType: FlowcodeDownloadOptionMimeType
): void => {
  const img = new Image()

  // Create a URL for the blob
  const url = URL.createObjectURL(svgBlob)

  // Set the image source to the blob URL
  img.src = url

  img.onload = () => {
    // Calculate scaling factor based on DPI
    const scale = dpi / 96 // Default SVG DPI is 96

    // Create a canvas element
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    if (!context) {
      logger.logError(
        new ClientSideError({
          name: 'Flowcode download error',
          message: 'Unable to get canvas context.',
          type: ClientSideErrorType.ClientSideError
        }),
        { team: 'PLG' }
      )
      return
    }

    // Set canvas dimensions adjusted for DPI
    canvas.width = img.width * scale
    canvas.height = img.height * scale

    // Scale the canvas for higher DPI
    context.scale(scale, scale)

    // Draw the image on the canvas
    context.drawImage(img, 0, 0)

    // Convert the canvas to a PNG blob
    canvas.toBlob(blob => {
      if (blob) {
        // Create a download link
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName

        // Simulate a click on the link to trigger the download
        link.click()

        // Cleanup
        URL.revokeObjectURL(link.href)
      } else {
        logger.logError(
          new ClientSideError({
            name: 'Flowcode download error',
            message: 'Canvas toBlob failed.',
            type: ClientSideErrorType.ClientSideError
          }),
          { team: 'PLG' }
        )
      }
    }, `image/${fileType}`)

    // Cleanup
    URL.revokeObjectURL(url)
    img.src = ''
  }

  img.onerror = () => {
    logger.logError(
      new ClientSideError({
        name: 'Flowcode download error',
        message: 'Failed to load image for PNG conversion.',
        type: ClientSideErrorType.ClientSideError
      }),
      { team: 'PLG' }
    )
    URL.revokeObjectURL(url)
  }
}
