import { ServerSideError, ServerSideErrorType, cloneErrorObject, logger } from '@dtx-company/logger'
import {
  auth_signin_form_emailpassword_success,
  auth_signup_form_emailpassword_success
} from '../../../constants/authHeapEvents'
import { fireAnalyticsEvent } from '../../../event-tracking/helpers/fireAnalyticsEvent'
import { unsubscribeFromMarketingEmails } from '../../../utils/notifications'

export const logAndUnsubscribe = async ({
  noMarketingEmails,
  email,
  isSignIn,
  cohortName
}: {
  isSignIn: boolean
  noMarketingEmails: boolean
  email?: string
  cohortName?: string
}): Promise<void> => {
  try {
    if (!isSignIn) {
      fireAnalyticsEvent(auth_signup_form_emailpassword_success, {
        cohort: cohortName ?? ''
      })
    } else {
      fireAnalyticsEvent(auth_signin_form_emailpassword_success)
    }

    if (noMarketingEmails && email) {
      fireAnalyticsEvent('userOptedOutOfMarketingEmails')
      await unsubscribeFromMarketingEmails(email)
    }
  } catch (error) {
    // this error is not critical, do not block sign up process
    if (!error.type) {
      logger.logError(
        new ServerSideError({
          ...cloneErrorObject(error),
          type: ServerSideErrorType.ServerSideOtherError,
          name: error.name || 'logAndUnsubscribe',
          endpoint: 'unsubscribeFromMarketingEmails'
        }),
        { technicalArea: 'authentication' }
      )
    }
  }
}
