import { BorderTemplateType, StudioConfigPartial } from '@app/common/src/types/createFlowcode.types'
import { FcGeneratorOptions, FcText } from '@dtx-company/flowcode-generator-browser/src'

export const addBorderPartialToGenOptions = (
  border: BorderTemplateType,
  options: FcGeneratorOptions
): FcGeneratorOptions => {
  const borderOptions: StudioConfigPartial = border.options ?? JSON.parse(border.configuration)
  const containerText: FcText[] = []
  // border partials only contain the position element of the container text,
  // so we need to combine it here with the current text properties
  for (const [index, text] of (borderOptions.containerText ?? []).entries()) {
    const interiorText = options.containerText?.[index]
    if (interiorText === undefined) break
    containerText.push({ ...interiorText, ...text })
  }

  return { ...options, ...borderOptions, containerText }
}
