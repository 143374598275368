import { CreateFlowcodeReturnType } from '../CreateFlowcodeForm.types'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-types/src'
import {
  FlowcodeTemplateType,
  LandingPageDestinationType,
  LandingPageFormValuesType
} from '@dtx-company/inter-app/src/types/flowcode'
import { FlowcodeThemeType } from '@app/common/src/types/createFlowcode.types'
import { createFlowcode } from '../CreateFlowcodeForm.utils'
import { getCreatedCodeHeapProperties } from '../../../../utils/flowcodeHeapProperties'
import { getFlowcodeId, loadImage } from '../../../../utils/flowcode'

import events from '@dtx-company/inter-app/src/event-tracking/events/flowcode'

export type CreateCodeArguments = {
  centerImageObjectURL: string
  destinationFormValues: LandingPageFormValuesType
  options: FcGeneratorOptions
  selectedDestination: LandingPageDestinationType
  template: FlowcodeTemplateType | null
  theme: FlowcodeThemeType
  token: string | undefined
  centerLogoImageFile?: File | null
  isAutoDesign?: boolean
}

// #2 The action that actually creates the code
export const createCode = async ({
  centerLogoImageFile,
  centerImageObjectURL,
  destinationFormValues,
  options,
  selectedDestination,
  template,
  theme,
  token,
  isAutoDesign
}: CreateCodeArguments): Promise<CreateFlowcodeReturnType> => {
  const centerImageFile = centerImageObjectURL
    ? await loadImage(centerImageObjectURL, 'centerImageFile')
    : null
  const { flowcodeOptions, flowcodeBatch } = await createFlowcode(
    token,
    {
      selectedDestination,
      flowcodeOptions: options,
      destinationFormValues
    },
    options,
    centerLogoImageFile ? centerLogoImageFile : centerImageFile,
    undefined,
    undefined,
    isAutoDesign
  )
  const flowcodeId = getFlowcodeId(flowcodeBatch.shortUrl || '')
  const heapProperties = getCreatedCodeHeapProperties({
    isAutoDesign: isAutoDesign || false,
    selectedFlowcodeTemplate: template,
    flowcodeTheme: theme,
    centerImage: Boolean(centerImageFile) || Boolean(centerLogoImageFile),
    selectedDestination: selectedDestination.id,
    flowcodeOptions: options
  })
  events.userCreatedFlowcode(false, flowcodeId, heapProperties)

  return { flowcodeOptions, flowcodeBatch }
}
