import {
  CMYK_CODE_DEFAULT_DOWNLOAD_OPTION,
  CMYK_CODE_DOWNLOAD_OPTIONS_WITH_TITLE,
  PDF_CODE_DEFAULT_DOWNLOAD_OPTION,
  PDF_CODE_DOWNLOAD_OPTION_WITH_TITLE,
  RGB_CODE_DEFAULT_DOWNLOAD_OPTIONS,
  RGB_CODE_DOWNLOAD_OPTIONS_WITH_TITLE
} from '../../../code/src/constants/DownloadFlowcode.constants'
import { DownloadFileType } from '../../../code/src/types/DownloadFlowcode.types'
import { FlowcodeDownloadOptionMimeType } from '@dtx-company/inter-app/src/types/flowcode'
import { FlowcodeDownloadOptionType } from '../types/createFlowcode.types'

export const getCodeDownloadOptionsWithTitle = ({
  isCMYK
}: {
  isCMYK?: boolean
}): DownloadFileType[] => {
  const options = []
  if (isCMYK) {
    options.push(...CMYK_CODE_DOWNLOAD_OPTIONS_WITH_TITLE)
  } else {
    options.push(...RGB_CODE_DOWNLOAD_OPTIONS_WITH_TITLE)
  }
  options.push(PDF_CODE_DOWNLOAD_OPTION_WITH_TITLE)
  return options
}

//with mimeType
export const getDefaultCodeDownloadOptions = ({
  isCMYK,
  filters
}: {
  isCMYK?: boolean
  // filters for backwards compatibility
  filters?: FlowcodeDownloadOptionMimeType[]
}): FlowcodeDownloadOptionType[] => {
  const options = []
  if (isCMYK) {
    options.push(...CMYK_CODE_DEFAULT_DOWNLOAD_OPTION)
  } else {
    options.push(...RGB_CODE_DEFAULT_DOWNLOAD_OPTIONS)
  }
  options.push(PDF_CODE_DEFAULT_DOWNLOAD_OPTION)
  return options.filter(({ mimeType }) => !filters || !filters.includes(mimeType))
}

// bulk codes can be CMYK, RBG or both
export const getBulkSelectedDownloadOptionsWithTitle = ({
  isCMYK,
  isRGB
}: {
  isCMYK?: boolean
  isRGB?: boolean
}): DownloadFileType[] => {
  const options = []
  if (isCMYK && isRGB) {
    const commonDownloadOptions = RGB_CODE_DOWNLOAD_OPTIONS_WITH_TITLE.filter(rgbType =>
      CMYK_CODE_DOWNLOAD_OPTIONS_WITH_TITLE.some(cmykType => cmykType.value === rgbType.value)
    )
    options.push(...commonDownloadOptions)
  } else {
    if (isCMYK) {
      options.push(...CMYK_CODE_DOWNLOAD_OPTIONS_WITH_TITLE)
    }
    if (isRGB) {
      options.push(...RGB_CODE_DOWNLOAD_OPTIONS_WITH_TITLE)
    }
  }

  options.push(PDF_CODE_DOWNLOAD_OPTION_WITH_TITLE)
  return options
}
