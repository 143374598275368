import { BatchDesignTemplateObjectType, FlowcodeThemeType } from '../types/flowcode.types'
import { CreatedFlowcodeHeapProperties } from '@dtx-company/true-common/src/types/heap'
import { FcGeneratorOptions, FcShape } from '@dtx-company/flowcode-generator-types/src'
import {
  FlowcodeTemplateType,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { HeapCodeType } from '@dtx-company/inter-app/src/types/analytics'
import { InputMaybe } from '@dtx-company/flow-codegen/src/code/generated.types'
import { theme } from '@dtx-company/shared-components/src'

export const getBuilderColorName = (hexColor?: string): string | undefined => {
  const { builder: builderColors } = theme.colors
  return Object.keys(builderColors).find(
    key => builderColors[key as keyof typeof builderColors] === hexColor
  )
}

export const FcShapeNames: Record<FcShape, string> = {
  [FcShape.CIRCLE]: 'circle',
  [FcShape.LOGO]: 'logo',
  [FcShape.SQUARE]: 'square',
  [FcShape.SHAGGY]: 'shaggy',
  [FcShape.DIAMOND]: 'diamond',
  [FcShape.CUSTOM]: 'custom',
  [FcShape.NONE]: 'none'
}

export const getCreatedCodeHeapProperties = (args: {
  isAutoDesign: boolean
  selectedFlowcodeTemplate?: FlowcodeTemplateType | null
  selectedSavedTemplate?: BatchDesignTemplateObjectType | null
  flowcodeOptions: FcGeneratorOptions
  flowcodeTheme?: FlowcodeThemeType
  selectedDestination?: LandingPageDestination
  centerImage?: boolean
  textAroundRim?: boolean
  centerText?: string
  assetLabels?: InputMaybe<InputMaybe<string>[]>
}): CreatedFlowcodeHeapProperties => {
  const getHeapCodeType = (): HeapCodeType => {
    if (args.isAutoDesign) return HeapCodeType.AUTO_DESIGN
    else if (args.selectedFlowcodeTemplate && !args.selectedFlowcodeTemplate.isCustomizable)
      return HeapCodeType.PREMADE_TEMPLATE
    else if (args.selectedSavedTemplate) return HeapCodeType.SAVED_DESIGN
    else return HeapCodeType.SCRATCH
  }
  const heapCodeType = getHeapCodeType()
  const autoDesignColors =
    args.isAutoDesign &&
    args.flowcodeOptions.gridModuleColor
      ?.split(',')
      .map(c => getBuilderColorName(c))
      .join(',')

  const heapCodeColor =
    heapCodeType === HeapCodeType.SCRATCH
      ? getBuilderColorName(args.flowcodeOptions?.gridBackgroundColor)
      : String(autoDesignColors)

  const tags: string | undefined = args.assetLabels ? args.assetLabels.join(', ') : undefined
  const backgroundColor =
    args.flowcodeOptions.defaultBackgroundColor || args.flowcodeOptions.gridBackgroundColor
  return {
    codeType: heapCodeType,
    destination: args.selectedDestination,
    template:
      heapCodeType === HeapCodeType.SCRATCH
        ? args.selectedFlowcodeTemplate?.name
        : args.flowcodeOptions.baseStudioConfigId,
    color:
      heapCodeType !== HeapCodeType.SAVED_DESIGN &&
      heapCodeType !== HeapCodeType.PREMADE_TEMPLATE &&
      !!heapCodeColor
        ? heapCodeColor
        : args.flowcodeOptions.defaultColor,
    backgroundColor: getBuilderColorName(backgroundColor) || backgroundColor,
    theme: heapCodeType === HeapCodeType.SCRATCH ? args.flowcodeTheme?.name : undefined,
    centerImage: args.centerImage,
    has_text_around_rim:
      args.textAroundRim || args.flowcodeOptions.containerRimText?.length ? true : false,
    shapeOption: args.flowcodeOptions.containerShape
      ? FcShapeNames[args.flowcodeOptions.containerShape]
      : undefined,
    centerText: args.centerText,
    tags,
    hasFrame: args.flowcodeOptions.containerRimBackgroundColor ? true : false
  }
}
