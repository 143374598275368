import { gql } from 'graphql-request'

export const CREATE_FLOWCODE_MUTATION = /* GraphQL */ `
  mutation createFlowcode(
    $active: Boolean
    $anonymousSessionId: UUID
    $nickname: String
    $redirectType: RedirectType!
    $url: String!
    $studioConfigId: UUID
    $isCollectible: Boolean
    $staticCodeSelected: Boolean
    $partialIds: [UUID]
    $redirectDomain: String
    $userId: ID
    $assetLabels: [String]
    $campaignName: CombinedGeneratorCampaigns
  ) {
    createBatch(
      active: $active
      anonymousSessionId: $anonymousSessionId
      nickname: $nickname
      redirectType: $redirectType
      url: $url
      studioConfigId: $studioConfigId
      isCollectible: $isCollectible
      isStaticCode: $staticCodeSelected
      partialIds: $partialIds
      redirectDomain: $redirectDomain
      userId: $userId
      assetLabels: $assetLabels
      campaignName: $campaignName
    ) {
      link {
        shorturl
      }
      batch {
        id
      }
      ok
    }
  }
`

export const CREATE_BATCH_RULE_MUTATION = /* GraphQL */ `
  mutation createBatchRules($batchId: ID!, $rule: JSONString, $interstitial: JSONString) {
    createBatchRules(batchId: $batchId, rule: $rule, interstitial: $interstitial) {
      ok
    }
  }
`
export const SEND_STO_EMAIL_MUTATION = /* GraphQL */ `
  mutation createCollectibleBatch($sendEmail: SendCollectBatchEmailInputType) {
    createCollectibleBatch(sendEmail: $sendEmail) {
      batch {
        id
      }
      link {
        shorturl
        redirectValue
      }
    }
  }
`

export const CREATE_COLLECTIBLE_BATCH_MUTATION = /* GraphQL */ `
  mutation createCollectibleBatch(
    $nickname: String
    $partialIds: [UUID]
    $studioConfigId: UUID
    $batchId: ID
  ) {
    createCollectibleBatch(
      nickname: $nickname
      partialIds: $partialIds
      studioConfigId: $studioConfigId
      batchId: $batchId
    ) {
      link {
        shorturl
      }
      batch {
        id
      }
    }
  }
`

export const UPDATE_BATCH_RULE_MUTATION = gql`
  mutation updateBatchRules($batchId: ID!, $rule: JSONString, $interstitial: JSONString) {
    updateBatchRules(batchId: $batchId, rule: $rule, interstitial: $interstitial) {
      ok
    }
  }
`

export const DELETE_BATCH_RULES_MUTATION = gql`
  mutation deleteBatchRules(
    $ruleId: ID!
    $shouldDeleteInterstitial: Boolean = false
    $shouldDeleteRule: Boolean = false
  ) {
    deleteBatchRules(
      ruleId: $ruleId
      shouldDeleteInterstitial: $shouldDeleteInterstitial
      shouldDeleteRule: $shouldDeleteRule
    ) {
      ok
    }
  }
`

// TODO: Remove this re-export
export { SAVE_CODE_TEMPLATE as CREATE_SAVE_TEMPLATE_MUTATION } from '@dtx-company/flow-codegen/src/code/mutations'

export const UPDATE_FLOWCODE_MUTATION = /* GraphQL */ `
  mutation updateBatchFlowcode(
    $linkInput: LinkInputType
    $batchId: ID!
    $batchInput: BatchInputType
  ) {
    updateBatchLink(linkInput: $linkInput, batchId: $batchId, batchInput: $batchInput) {
      ok
      batch {
        id
        nickname
      }
    }
  }
`

export const TRANSFER_BATCH_MUTATION = /* GraphQL */ `
  mutation transferBatch($batchId: ID!, $newOwnerEmail: String!) {
    transferBatch(batchId: $batchId, newOwnerEmail: $newOwnerEmail) {
      ok
      batch {
        id
      }
    }
  }
`

export const EMAIL_BATCH_MUTATION = /* GraphQL */ `
  mutation emailBatch($toEmail: String!, $batchId: ID, $emailType: EmailType, $data: JSONString) {
    emailBatch(batchId: $batchId, toEmail: $toEmail, emailType: $emailType, data: $data) {
      ok
    }
  }
`

export const SWITCH_BATCH_MUTATION = /* GraphQL */ `
  mutation switchBatchDesign($batchId: String!, $newConfigId: String!, $partialIds: [UUID]) {
    switchBatchDesign(id: $batchId, newConfigId: $newConfigId, partialIds: $partialIds) {
      ok
    }
  }
`

export const EDIT_BATCH_CONFIG_MUTATION = /* GraphQL */ `
  mutation editBatchDesign(
    $batchId: String!
    $newConfig: JSONString!
    $partialIds: [UUID]
    $isAutoDesign: Boolean
  ) {
    editBatchDesign(
      id: $batchId
      newConfig: $newConfig
      partialIds: $partialIds
      isAutoDesign: $isAutoDesign
    ) {
      ok
    }
  }
`

export const UPDATE_BATCH_DIRECTORY_MUTATION = /* GraphQL */ `
  mutation updateBatchDirectory(
    $batchId: ID!
    $moveToDirectory: ID
    $removeDirectory: Boolean = false
  ) {
    updateBatchDirectory(
      batchId: $batchId
      moveToDirectory: $moveToDirectory
      removeDirectory: $removeDirectory
    ) {
      ok
    }
  }
`

export const BULK_UPDATE_BATCH_DIRECTORY_MUTATION = /* GraphQL */ `
  mutation bulkUpdateBatchDirectory(
    $batchIds: [ID]!
    $moveToDirectory: ID
    $removeDirectory: Boolean
  ) {
    bulkUpdateBatchDirectory(
      batchIds: $batchIds
      moveToDirectory: $moveToDirectory
      removeDirectory: $removeDirectory
    ) {
      ok
    }
  }
`

export const BULK_UPDATE_DIRECTORIES_MUTATION = gql`
  mutation bulkUpdateDirectories(
    $directoryIds: [ID]!
    $newParentId: ID
    $shouldMoveToRoot: Boolean
  ) {
    bulkUpdateDirectories(
      directoryIds: $directoryIds
      newParentId: $newParentId
      shouldMoveToRoot: $shouldMoveToRoot
    ) {
      ok
    }
  }
`

export const UPDATE_FOLDER_MUTATION = /* GraphQL */ `
  mutation updateDirectory(
    $directoryId: ID!
    $name: String
    $parentId: ID
    $shouldMoveToRoot: Boolean = false
  ) {
    updateDirectory(
      directoryId: $directoryId
      name: $name
      parentId: $parentId
      shouldMoveToRoot: $shouldMoveToRoot
    ) {
      ok
    }
  }
`

export const UPDATE_FOLDER_MUTATION_WITH_DESCRIPTION = /* GraphQL */ `
  mutation updateDirectory(
    $directoryId: ID!
    $name: String
    $description: String
    $parentId: ID
    $shouldMoveToRoot: Boolean = false
  ) {
    updateDirectory(
      directoryId: $directoryId
      name: $name
      description: $description
      parentId: $parentId
      shouldMoveToRoot: $shouldMoveToRoot
    ) {
      ok
    }
  }
`

export const ARCHIVE_BATCH_MUTATION = /* GraphQL */ `
  mutation archiveBatch(
    $batchId: ID!
    $deactivateRedirectService: Boolean
    $archiveReason: String
  ) {
    archiveBatch(
      batchId: $batchId
      deactivateRedirectService: $deactivateRedirectService
      archiveReason: $archiveReason
    ) {
      ok
    }
  }
`

export const BULK_ARCHIVE_BATCHES_MUTATION = /* GraphQL */ `
  mutation bulkArchiveBatches($batchIds: [ID]!, $archiveReason: String) {
    bulkArchiveBatches(batchIds: $batchIds, archiveReason: $archiveReason) {
      ok
    }
  }
`

export const RESTORE_BATCH_MUTATION = /* GraphQL */ `
  mutation restoreBatch($batchId: ID!) {
    restoreBatch(batchId: $batchId) {
      ok
    }
  }
`

export const CREATE_FOLDER_MUTATION = /* GraphQL */ `
  mutation createDirectory($name: String!, $parentId: ID) {
    createDirectory(name: $name, parentId: $parentId) {
      ok
      directory {
        id
      }
    }
  }
`

export const DELETE_FOLDER_MUTATION = /* GraphQL */ `
  mutation deleteBatch($folderId: ID!) {
    deleteDirectory(directoryId: $folderId) {
      ok
    }
  }
`

export const BULK_DELETE_DIRECTORIES_MUTATION = /* GraphQL */ `
  mutation bulkDeleteDirectories($directoryIds: [ID]!) {
    bulkDeleteDirectories(directoryIds: $directoryIds) {
      ok
    }
  }
`

export const COLLECT_BATCH_MUTATION = /* GraphQL */ `
  mutation collectBatch($batchId: ID, $flowcodeId: ID, $codeInput: CollectBatchCodeInputType) {
    collectBatch(batchId: $batchId, flowcodeId: $flowcodeId, codeInput: $codeInput) {
      ok
    }
  }
`

export const PRESET_COLLECT_BATCH_MUTATION = /* GraphQL */ `
  mutation collectBatch($batchId: ID, $flowcodeId: ID) {
    collectBatch(batchId: $batchId, flowcodeId: $flowcodeId) {
      ok
      claimedPageId
      batchOwner {
        batch {
          activeLink {
            redirectValue
          }
        }
      }
    }
  }
`

export const UNSUBSCRIBE_HUBSPOT_MUTATION = /* GraphQL */ `
  mutation unsubscribeHubspot($email: String!) {
    unsubscribeHubspot(email: $email) {
      ok
    }
  }
`

export const CREATE_PLACEMENT_TEMPLATE_DOWNLOAD_DATA = /* GraphQL */ `
  mutation createPlacementTemplateDownloadData(
    $templateGenerationDetails: String!
    $flowcodeId: String!
    $templateId: ID!
    $actionType: String!
  ) {
    createPlacementTemplateDownloadData(
      templateGenerationDetails: $templateGenerationDetails
      flowcodeId: $flowcodeId
      templateId: $templateId
      actionType: $actionType
    ) {
      ok
    }
  }
`
