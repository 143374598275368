import { DEFAULT_DPI } from './generateFlowcode.constant'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-types/src'

const getFlowcodeGeneratorRootUrl = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `https://stg-generator.flowcode.com`
    case 'preprod':
      return `https://preprod-generator.flowcode.com`
    case 'production':
    default:
      return `https://generator.flowcode.com`
  }
}

export const getFlowcodeUrl = (
  flowcodeOptions: FcGeneratorOptions,
  targetFileType = 'png',
  dpi: string = DEFAULT_DPI
): string => {
  const encodedOptions = encodeURIComponent(JSON.stringify(flowcodeOptions))
  return `${getFlowcodeGeneratorRootUrl()}/v1/flowcode?opts=${encodedOptions}&density=${dpi}&imageType=${targetFileType}`
}
