import { DownloadFileType } from '../types/DownloadFlowcode.types'
import { FlowcodeDownloadOptionMimeType } from '@dtx-company/inter-app/src/types/flowcode'
import { FlowcodeDownloadOptionType } from '@app/common/src/types/createFlowcode.types'

export const CMYK_CODE_DOWNLOAD_OPTIONS_WITH_TITLE: DownloadFileType[] = [
  {
    label: 'JPG',
    value: FlowcodeDownloadOptionMimeType.JPG,
    title: "Small file size, doesn't support transparency"
  },
  {
    label: 'EPS',
    value: FlowcodeDownloadOptionMimeType.EPS,
    title: 'High quality postscript format'
  }
]

export const RGB_CODE_DOWNLOAD_OPTIONS_WITH_TITLE: DownloadFileType[] = [
  {
    label: 'JPG',
    value: FlowcodeDownloadOptionMimeType.JPG,
    title: "Small file size, doesn't support transparency"
  },
  {
    label: 'PNG',
    value: FlowcodeDownloadOptionMimeType.PNG,
    title: 'High quality image, great for print and digital'
  },
  {
    label: 'SVG',
    value: FlowcodeDownloadOptionMimeType.SVG,
    title: 'Sharp vector graphics at any size, great for sharing with vendors'
  }
]

export const PDF_CODE_DOWNLOAD_OPTION_WITH_TITLE: DownloadFileType = {
  label: 'PDF',
  value: FlowcodeDownloadOptionMimeType.PDF,
  title: 'High quality document file, can be used for email and print'
}

export const RGB_CODE_DEFAULT_DOWNLOAD_OPTIONS: FlowcodeDownloadOptionType[] = [
  {
    mimeType: FlowcodeDownloadOptionMimeType.PNG,
    label: '.PNG',
    value: 'png'
  },
  {
    mimeType: FlowcodeDownloadOptionMimeType.JPG,
    label: '.JPG',
    value: 'jpg'
  },
  {
    mimeType: FlowcodeDownloadOptionMimeType.SVG,
    label: '.SVG',
    value: 'svg'
  }
]

export const EPS_CODE_DEFAULT_DOWNLOAD_OPTION = {
  mimeType: FlowcodeDownloadOptionMimeType.EPS,
  label: '.EPS',
  value: FlowcodeDownloadOptionMimeType.EPS
}

export const CMYK_CODE_DEFAULT_DOWNLOAD_OPTION: FlowcodeDownloadOptionType[] = [
  {
    mimeType: FlowcodeDownloadOptionMimeType.JPG,
    label: '.JPG',
    value: FlowcodeDownloadOptionMimeType.JPG
  },
  EPS_CODE_DEFAULT_DOWNLOAD_OPTION
]

export const PDF_CODE_DEFAULT_DOWNLOAD_OPTION: FlowcodeDownloadOptionType = {
  label: '.PDF',
  value: FlowcodeDownloadOptionMimeType.PDF,
  mimeType: FlowcodeDownloadOptionMimeType.PDF
}
