import { FC } from 'react'
import { default as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton'

export type SkeletonProps = Pick<
  MuiSkeletonProps,
  'animation' | 'children' | 'height' | 'sx' | 'variant' | 'width'
> & { 'data-testid'?: string }

/**
 * Display a placeholder preview of your content before the data gets loaded
 * to reduce load-time frustration.
 *
 * Docs: [https://mui.com/material-ui/react-skeleton/]
 */

export const Skeleton: FC<SkeletonProps> = (props: SkeletonProps) => <MuiSkeleton {...props} />
