import { ContextMenuAction, Option, Types, theme } from '@dtx-company/shared-components/src'
import {
  MemberType,
  TeamMemberType,
  TeamRoleEnum,
  TeamType
} from '@dtx-company/true-common/src/types/org'
import { SortDirections } from '@dtx-company/true-common/src/types/generic'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'

export enum SortField {
  NAME = 'name',
  FIRST_NAME = 'firstName',
  ROLE = 'role',
  MEMBERS = 'members',
  TEAM_NAME = 'teamName',
  MEMBER_COUNT = 'memberCount',
  SUBTEAMS_COUNT = 'directChildrenCount',
  ORG_ROLE = 'orgRole',
  ORG_NAME = 'orgName',
  ORG_PLAN = 'OrgPlan',
  PRIMARY_CONTACT_EMAIL = 'primaryContactEmail',
  SEAT_COUNT = 'seatCount',
  INVITE_DATE = 'createdAt',
  SUBTEAM_COUNT = 'subteamCount',
  LAST_LOGIN = 'lastLogin',
  ASSET_TYPE = 'assetType',
  ASSET_NAME = 'assetName',
  LAST_MODIFIED = 'lastModified'
}

export enum TeamMembersSortField {
  NAME = 'name',
  ROLE = 'role'
}

export const getRequestHeadersWithAuthHeader = (
  jwtToken?: string,
  ithacaId?: string
): Record<string, string> => {
  return {
    Accept: 'application/json',
    ...(Boolean(jwtToken) && { authorization: `Bearer ${jwtToken}` }),
    ...(Boolean(ithacaId) && { 'x-ithaca-id': ithacaId })
  }
}

/* MEMBERS */
export const findMemberById = (
  ithacaId: string,
  members?: (TeamMemberType | MemberType)[]
): TeamMemberType | MemberType | undefined => {
  if (!members) return undefined
  return members.find(member => member.ithacaId === ithacaId)
}

export const getMemberName = (member: MemberType | TeamMemberType): string => {
  const displayName = `${member?.firstName} ${member?.lastName}`.trim()
  return displayName || member?.email || ''
}

export function formatMembersForAutocomplete(members?: MemberType[]): Option[] {
  if (!members) return []
  return members.map(member => {
    return {
      label: getMemberName(member),
      value: member.ithacaId
    } as Option
  })
}

export const formatExtraRolesForContextMenu = (
  extras: string[][] | undefined,
  selectedExtras: string[],
  onAction: (selectedExtras: string[]) => void
): ContextMenuAction[] => {
  return (
    extras?.map(extra => {
      const item: ContextMenuAction = {
        id: extra[0],
        label: extra[1],
        action: () => {
          const selectedExtrasCopy = [...selectedExtras]
          const index = selectedExtrasCopy.findIndex(se => se === extra[0])
          index >= 0 ? selectedExtrasCopy.splice(index, 1) : selectedExtrasCopy.push(extra[0])
          onAction(selectedExtrasCopy.length ? selectedExtrasCopy : [''])
        }
      }
      const isChecked = selectedExtras?.some(se => se === extra[0])
      if (isChecked) {
        item.endImg = {
          src: '/icons/checkmark-black.svg',
          alt: 'selected'
        }
      }
      return item
    }) || []
  )
}

export const getRoleFromType = (type: string): string => {
  return capitalize(type?.split(':')[1]?.split('_').join(' '))
}

export const updateLocalOrgMember = (
  ithacaId: string,
  members: MemberType[],
  updatedProps: Record<string, unknown>
): MemberType[] => {
  const updatedMembers = [...members]
  const memberIndex = updatedMembers.findIndex(
    member => member.ithacaId === ithacaId || member.id === ithacaId
  )
  updatedMembers[memberIndex] = { ...updatedMembers[memberIndex], ...updatedProps }
  return updatedMembers
}

export const deleteLocalOrgMember = (id: string, members: MemberType[]): MemberType[] => {
  const updatedMembers = [...members]
  const memberIndex = updatedMembers.findIndex(member => member.ithacaId === id || member.id === id)
  if (memberIndex >= 0) {
    updatedMembers.splice(memberIndex, 1)
  }
  return updatedMembers
}

export const sortMembersByFirstname = (
  members: MemberType[],
  direction?: SortDirections
): MemberType[] => {
  return members.sort((a, b) => {
    return direction === SortDirections.ASC
      ? b?.firstName?.localeCompare(a?.firstName)
      : a?.firstName?.localeCompare(b?.firstName)
  })
}

export const sortMembersByInviteDate = (
  members: MemberType[],
  direction?: SortDirections
): MemberType[] => {
  return members.sort((a, b) => {
    const aDate = a?.createdAt || ''
    const bDate = b?.createdAt || ''
    return direction === SortDirections.ASC
      ? bDate?.localeCompare(aDate)
      : aDate?.localeCompare(bDate)
  })
}

export const sortMembersByRole = (
  members: MemberType[],
  direction?: SortDirections
): MemberType[] => {
  return members.sort((a, b) => {
    return direction === SortDirections.ASC
      ? b?.orgRole?.localeCompare(a?.orgRole)
      : a?.orgRole?.localeCompare(b?.orgRole)
  })
}

/* TEAMS */
export const findTeamById = (id: string, teams: TeamType[]): TeamType | undefined => {
  return teams.find((team: TeamType) => team.teamId === id)
}

export const searchTeams = (query: string, teams: TeamType[]): TeamType[] | undefined => {
  if (!query || query.length < 2) return teams
  const searchedTeams = teams.filter(team => team.name.toLowerCase().includes(query.toLowerCase()))
  return searchedTeams.length ? searchedTeams : undefined
}

export const updateLocalTeam = (team: TeamType, teams: TeamType[]): TeamType[] => {
  const updatedTeams = [...teams]
  const teamIndex = teams.findIndex(t => t.teamId === team.teamId)
  updatedTeams[teamIndex] = team
  return updatedTeams
}

export const deleteLocalTeam = (teamId: string, teams: TeamType[]): TeamType[] => {
  const updatedTeams = [...teams]
  const teamIndex = teams.findIndex(t => t.teamId === teamId)
  if (teamIndex >= 0) {
    updatedTeams.splice(teamIndex, 1)
  }
  return updatedTeams
}

export const addLocalTeamMember = (
  member: TeamMemberType,
  members: TeamMemberType[],
  addToBeginning?: boolean
): TeamMemberType[] => {
  const updatedMembers = [...members]
  const memberIndex = updatedMembers.findIndex(m => m.ithacaId === member.ithacaId)
  if (memberIndex < 0) {
    if (addToBeginning) {
      updatedMembers.unshift(member)
    } else {
      updatedMembers.push(member)
    }
  }
  return updatedMembers
}

export const updateLocalTeamMember = (
  ithacaId: string,
  members: TeamMemberType[],
  updatedProps: Record<string, unknown>
): TeamMemberType[] => {
  const updatedMembers = [...members]
  const memberIndex = updatedMembers.findIndex(member => member.ithacaId === ithacaId)
  updatedMembers[memberIndex] = { ...updatedMembers[memberIndex], ...updatedProps }
  return updatedMembers
}

export const deleteLocalTeamMember = (
  ithacaId: string,
  members: TeamMemberType[]
): TeamMemberType[] => {
  const updatedMembers = [...members]
  const memberIndex = updatedMembers.findIndex(member => member.ithacaId === ithacaId)
  if (memberIndex >= 0) {
    updatedMembers.splice(memberIndex, 1)
  }
  return updatedMembers
}

export function formatMembersForTeams(members: TeamMemberType[]): {
  ithacaId: string
  role: TeamRoleEnum
}[] {
  return members?.map(member => {
    return {
      ithacaId: member.ithacaId,
      role: member.teamRole || TeamRoleEnum.VIEWER
    }
  })
}

export const prepareTeamMembersForPatch = (
  originalMembers: TeamMemberType[],
  currentMembers: TeamMemberType[]
): {
  newUsers: TeamMemberType[]
  changedUsers: TeamMemberType[]
  deletedUsers: string[]
} => {
  const convertedCurrentMembers = currentMembers.map(mem => {
    return {
      ithacaId: mem.ithacaId,
      role: mem.teamRole
    }
  })
  const convertedOriginalMembers = originalMembers.map(mem => {
    return {
      ithacaId: mem.ithacaId,
      role: mem.teamRole
    }
  })

  const newUsers = convertedCurrentMembers.filter(
    cm => !convertedOriginalMembers.some(om => cm.ithacaId === om.ithacaId)
  )
  const deletedUsers = convertedOriginalMembers
    .filter(cm => !convertedCurrentMembers.some(om => cm.ithacaId === om.ithacaId))
    .map(deletedMember => deletedMember.ithacaId)
  const changedUsers = convertedOriginalMembers.filter(cm =>
    convertedCurrentMembers.some(om => cm.ithacaId === om.ithacaId)
  )

  return {
    newUsers,
    changedUsers,
    deletedUsers
  }
}

/* MISC */
export const RANDOM_BACKGROUND_COLORS = [
  theme.colors.masterWeb.purple1,
  theme.colors.masterWeb.orange2,
  theme.colors.masterWeb.jade2,
  theme.colors.masterWeb.blue1,
  theme.colors.masterWeb.raspberry1,
  theme.colors.masterWeb.pink1
]

export const getRandomBackgroundColor = (name: string): string => {
  const charCode = name.toLowerCase().charCodeAt(2) - 96
  const bgl = RANDOM_BACKGROUND_COLORS.length
  const range = Math.round(26 / bgl)
  const num = Math.floor(charCode / range)
  const index = num >= 0 ? num : RANDOM_BACKGROUND_COLORS.length - 1
  return RANDOM_BACKGROUND_COLORS[index < bgl ? index : index - 1]
}

export function addColorToTeams(teams: TeamType[]): TeamType[] {
  return teams.map(team => {
    if (team.color) return team
    return { ...team, color: getRandomBackgroundColor(team.name) }
  })
}

export const getSortDirections = (
  currentField: SortField | TeamMembersSortField,
  selectedDir: SortDirections,
  selectedField?: SortField | TeamMembersSortField
): {
  type?: Types
  initialSortDirection?: SortDirections
  controlledSortDirection?: SortDirections
} => {
  if (currentField === selectedField) {
    return {
      type: 'sort',
      initialSortDirection: selectedDir,
      controlledSortDirection: selectedDir
    }
  }
  return {}
}
