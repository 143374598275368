import { ElementRef, ElementType, forwardRef } from 'react'
import {
  default as MuiFormControl,
  FormControlProps as MuiFormControlProps
} from '@mui/material/FormControl'
import { OverrideProps } from '../../types/mui.types'

export interface FormControlTypeMap<P = Record<string, never>, D extends ElementType = 'div'> {
  props: Omit<P, 'style'> &
    Pick<
      MuiFormControlProps,
      | 'children'
      | 'color'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'fullWidth'
      | 'margin'
      | 'required'
      | 'size'
      | 'variant'
    >
  defaultComponent: D
}

export type FormControlProps<
  D extends ElementType = FormControlTypeMap['defaultComponent'],
  P = Record<string, unknown>
> = OverrideProps<FormControlTypeMap<P, D>, D>

export const FormControl = forwardRef<
  ElementRef<FormControlTypeMap['defaultComponent']>,
  FormControlProps
>((props, ref) => <MuiFormControl ref={ref} {...props} />)
FormControl.displayName = 'FormControl'
