const black = '#000000'
export const colors = {
  primary: {
    main: black,
    light: '#FBFBFB',
    flowBlue: '#033DDD',
    grey: '#5B5E63',
    black: '#111111',
    skyBlue: '#90D7FF',
    white: '#FFFFFF',
    actionDark: '#017C96'
  },
  secondary: {
    main: black,
    border: '#DEDFE0',
    placeholder: '#ADADAD',
    backgroundLight: '#FAFAFA',
    backgroundDark: '#F0F2F5',
    disabled: '#888888'
  },
  status: {
    errorDark: '#F4134F',
    errorLight: '#FDD8E2',
    infoDark: '#008EE0',
    infoLight: '#D6F0FF',
    successDark: '#25D086',
    successLight: '#DCF9ED',
    warningDark: '#FFBA2D',
    warningLight: '#FFF1D6'
  },
  builder: {
    black: '#000000',
    blueDark: '#015BBF',
    blueLight: '#027AFF',
    brownDark: '#7B4B3E',
    brownLight: '#A46453',
    coralDark: '#BC383B',
    coralLight: '#FB4B4E',
    flowblueDark: '#022EA5',
    flowblueLight: '#033DDD',
    fuchsiaDark: '#A124AB',
    fuchsiaLight: '#E326CA',
    goldDark: '#BF8C22',
    goldLight: '#FFBA2D',
    grapeDark: '#6C2681',
    grapeLight: '#9132AC',
    greenDark: '#1B9A3E',
    greenLight: '#24CD53',
    greyDark: '#8F8F8F',
    greyLight: '#BFBFBF',
    indigoDark: '#591CB7',
    indigoLight: '#7625F4',
    jadeDark: '#1C9C64',
    jadeLight: '#22BF7B',
    kiwiDark: '#6DA423',
    kiwiLight: '#91DA2F',
    lemonDark: '#9BB000',
    lemonLight: '#C6E000',
    lilacDark: '#8868BB',
    lilacLight: '#B58BF9',
    limeDark: '#0DA513',
    limeLight: '#12DC1A',
    magentaDark: '#AC2974',
    magentaLight: '#E6379A',
    mintDark: '#58A099',
    mintLight: '#75D6CC',
    navyDark: '#0C0879',
    navyLight: '#100BA1',
    orangeDark: '#BF7000',
    orangeLight: '#FF9500',
    peachLight: '#FF8A58',
    peachDark: '#BF6842',
    pinkDark: '#BF7493',
    pinkLight: '#FF9AC4',
    pumpkinDark: '#BF5106',
    pumpkinLight: '#FF6C08',
    purpleDark: '#532390',
    purpleLight: '#6F2FC0',
    raspberryDark: '#B70E3B',
    raspberryLight: '#F4134F',
    redDark: '#BF0000',
    redLight: '#FF0000',
    roseDark: '#BE355E',
    roseLight: '#FF477E',
    rustDark: '#BF5106',
    rustLight: '#FF6C08',
    skyDark: '#2687BF',
    skyLight: '#33B4FF',
    slateDark: '#383D49',
    slateLight: '#4B5261',
    starfishDark: '#BF6842',
    starfishLight: '#FF8A58',
    tanDark: '#B89E5D',
    tanLight: '#F5D37C',
    turquoiseDark: '#1AADAD',
    turquoiseLight: '#18DCDC',
    violetDark: '#833CA6',
    violetLight: '#AF52DE',
    watermelonDark: '#BE355E',
    watermelonLight: '#FF477E',
    yellowDark: '#BF9900',
    yellowLight: '#FFCC00'
  },
  masterWeb: {
    black: '#000000',
    blue1: '#D7E1FE',
    blue2: '#4A7AFC',
    blue3: '#033DDD',
    blue4: '#022DA1',
    blue5: '#021C64',
    brown1: '#EADBD6',
    brown2: '#C89E92',
    brown3: '#A46453',
    brown4: '#6D4237',
    brown5: '#36211B',
    flowblue1: '#D7E1FE',
    flowblue2: '#4A7AFC',
    flowblue3: '#033DDD',
    flowblue4: '#022DA1',
    flowblue5: '#021C64',
    fuchsia1: '#FADBF6',
    fuchsia2: '#EC6FDB',
    fuchsia3: '#E326CA',
    fuchsia4: '#901380',
    fuchsia5: '#5A0C50',
    jade1: '#DCF9ED',
    jade2: '#97EDC8',
    jade3: '#22BF7B',
    jade4: '#1C9C64',
    jade5: '#0F5738',
    gold1: '#FFF1D6',
    gold2: '#FFD685',
    gold3: '#FFBA2D',
    gold4: '#E09600',
    gold5: '#664400',
    grey1: '#FAFAFA',
    grey2: '#F0F2F5',
    grey3: '#DEDFE0',
    grey4: '#ADADAD',
    grey5: '#888888',
    grey6: '#5B5E63',
    grey7: '#31343D',
    grey8: '#1E1F23',
    grey9: '#111111',
    lemon1: '#F9FFD7',
    lemon2: '#E9FE72',
    lemon3: '#C6E000',
    lemon4: '#9BB000',
    lemon5: '#566501',
    orange1: '#FFF1D6',
    orange2: '#FFD685',
    orange3: '#FFBA2D',
    orange4: '#E09600',
    orange5: '#664400',
    pink1: '#FADBF6',
    pink2: '#EC6FDB',
    pink3: '#E326CA',
    pink4: '#901380',
    pink5: '#5A0C50',
    pumpkin1: '#FFE7D6',
    pumpkin2: '#FFA970',
    pumpkin3: '#FF6C08',
    pumpkin4: '#B84D00',
    pumpkin5: '#662B03',
    purple1: '#E9DEF7',
    purple2: '#B28CE3',
    purple3: '#6F2FC0',
    purple4: '#4B2083',
    purple5: '#251042',
    raspberry1: '#FDD8E2',
    raspberry2: '#F97799',
    raspberry3: '#F4134F',
    raspberry4: '#AF0835',
    raspberry5: '#61051D',
    red1: '#FDD8E2',
    red2: '#F97799',
    red3: '#F4134F',
    red4: '#AF0835',
    red5: '#61051D',
    sky1: '#D6F0FF',
    sky2: '#90D7FF',
    sky3: '#33B4FF',
    sky4: '#008EE0',
    sky5: '#004166',
    torqoise1: '#DAFBFB',
    torqoise2: '#91F3F3',
    torqoise3: '#23E7E7',
    torqoise4: '#12A5A5',
    torqoise5: '#0A5C5C',
    white: '#FFFFFF',
    yellow1: '#F9FFD7',
    yellow2: '#E9FE72',
    yellow3: '#CEEA00',
    yellow4: '#9BB602',
    yellow5: '#566501'
  },
  masterBuilder: {
    black1: '#ADADAD',
    black2: '#999999',
    black3: '#5B5E63',
    black4: '#5E5D5A',
    black5: '#4B5261',
    black6: '#282828',
    black7: '#434343',
    black8: '#001427',
    black9: '#31343D',
    black10: '#363636',
    black11: '#1E1F23',
    black12: '#282828',
    black13: '#101010',
    black14: '#111111',
    black15: '#7A6C5D',
    black16: '#363636',
    blue1: '#55BAD2',
    blue2: '#02A4C6',
    blue3: '#78C4E8',
    blue4: '#1CA0F0',
    blue5: '#008EE0',
    blue6: '#0094D3',
    blue7: '#0072BC',
    blue8: '#5685FF',
    blue9: '#4A7AFC',
    blue10: '#3F68CF',
    blue11: '#4350AF',
    blue12: '#0046BD',
    blue13: '#3439B7',
    blue14: '#4452FF',
    blue15: '#0B00EF',
    blue16: '#4122FF',
    blue17: '#0046BD',
    blue18: '#0E057F',
    blue19: '#003D87',
    blue20: '#002951',
    blue21: '#8AA8E2',
    blue22: '#8E73D6',
    blue23: '#9786FF',
    blue24: '#7862FF',
    blue25: '#5551FF',
    blue26: '#5A1FFF',
    blue27: '#4940BB',
    blue28: '#623CB0',
    blue29: '#9444F5',
    blue30: '#5F52AC',
    blue31: '#AE8FCB',
    blue32: '#955BA9',
    blue33: '#9132AC',
    blue34: '#7C0797',
    blue35: '#7446A3',
    blue36: '#642890',
    blue37: '#401E82',
    blue38: '#4909AE',
    blue39: '#280561',
    blue40: '#021C64',
    blue41: '#233C5B',
    blue42: '#392651',
    blue43: '#392651',
    blue44: '#483864',
    blue45: '#300F62',
    blue46: '#011758',
    blue47: '#023166',
    blue48: '#0F5A7C',
    blue49: '#154866',
    blue50: '#5B0F51',
    green1: '#87DCAD',
    green2: '#A7D1B8',
    green3: '#67E784',
    green4: '#A6DD66',
    green5: '#98C05C',
    green6: '#7DCD85',
    green7: '#95D133',
    green8: '#AACF3C',
    green9: '#6CC551',
    green10: '#A5AA52',
    green11: '#75BA1A',
    green12: '#7FB285',
    green13: '#29CB40',
    green14: '#1CD760',
    green15: '#13CEC3',
    green16: '#2EC4B6',
    green17: '#06B5C1',
    green18: '#1FB4A2',
    green19: '#43AA8B',
    green20: '#4C9A29',
    green21: '#14825B',
    green22: '#429588',
    green23: '#117C75',
    green24: '#598773',
    green25: '#077D3D',
    green26: '#006A4E',
    green27: '#017C8C',
    green28: '#0E5C5C',
    green29: '#0F5738',
    green30: '#034D26',
    green31: '#566501',
    green32: '#515E01',
    green33: '#395A66',
    green34: '#085809',
    green35: '#414A3D',
    green36: '#01363F',
    red1: '#F97799',
    red2: '#E586AA',
    red3: '#FA8071',
    red4: '#E56363',
    red5: '#CE5D5C',
    red6: '#FB4B4E',
    red7: '#EE293A',
    red8: '#F23314',
    red9: '#E01E1E',
    red10: '#CE2029',
    red11: '#960118',
    red12: '#7C0800',
    red13: '#B62902',
    red15: '#D73864',
    red16: '#E0105F',
    red17: '#D82085',
    red18: '#B20679',
    red19: '#B02C67',
    red20: '#AB697B',
    red21: '#BF4386',
    red22: '#EC9DE1',
    red23: '#C92DE0',
    red24: '#B43AC2',
    red25: '#901380',
    red26: '#BB5973',
    red27: '#500000',
    red28: '#620820',
    red29: '#64303D',
    red30: '#653E4D',
    red31: '#7A4C56',
    red32: '#FF477E',
    yellow1: '#FFC300',
    yellow2: '#E09600',
    yellow3: '#EFAA2E',
    yellow4: '#F7B14F',
    yellow5: '#F19D38',
    yellow6: '#FB8B24',
    yellow7: '#FF8A58',
    yellow8: '#F66300',
    yellow9: '#EA5418',
    yellow10: '#CC4F40',
    yellow11: '#FC5B2D',
    yellow12: '#FD805D',
    yellow13: '#FC440F',
    yellow14: '#D25100',
    yellow15: '#FD7F46',
    yellow16: '#FF9500',
    yellow17: '#F5D37C',
    yellow18: '#F9C74F',
    yellow19: '#D2C891',
    yellow20: '#DDC499',
    yellow21: '#D9B086',
    yellow22: '#E3A587',
    yellow23: '#C69688',
    yellow24: '#C9ADA7',
    yellow25: '#845642',
    yellow26: '#75564B',
    yellow27: '#925431',
    yellow28: '#664A12',
    yellow29: '#422821',
    yellow30: '#562F2B',
    yellow31: '#662B03',
    yellow32: '#6D4237'
  }
}
