import { linkTypes } from '@dtx-company/flowcode-utils/src'

export const HTTPS_PATTERN = /https:\/\//i
export const HTTP_PATTERN = /http:\/\//i

// deep links
export const FB_DEEP_LINK_PATTERN = /fb:\/\/(profile|page)\/.{1,}/
export const TWITTER_DEEP_LINK_PATTERN = /twitter:\/\/user\?screen_name=.{1,}/
export const GOOGLE_DEEP_LINK_PATTERN = /gplus:\/\/plus\.google\.com\/.{1,}/
export const PINTEREST_DEEP_LINK_PATTERN = /pinterest:\/\/user\/.{1,}/
export const INSTAGRAM_DEEP_LINK_PATTERN = /instagram:\/\/user\?username=.{1,}/
export const YOUTUBE_DEEP_LINK_PATTERN = /vnd\.youtube:\/\/user\/.{1,}/
export const YELP_DEEP_LINK_PATTERN = /yelp:\/\/biz\/.{1,}/
export const LINKEDIN_DEEP_LINK_PATTERN = /linkedin:\/\/profile\?id=.{1,}/
export const SNAPCHAT_DEEP_LINK_PATTERN = /snapchat:\/\/add\/.{1,}/
export const TUMBLR_DEEP_LINK_PATTERN = /tumblr:\/\/x-callback-url\/.{1,}/
export const VENMO_DEEP_LINK_PATTERN = /venmo:\/\/paycharge\?.{1,}/

// websites
export const REVIEW_APP_HOST_PATTERN = /flow-app-\d+\.reviewapps\.flowcode\.com/gi
export const GOOGLE_DOCS_PATTERN = /^(https?:\/\/)?(docs\.)?google.com\/.{1,}/
const LINKEDIN_PROFILE_PATTERN =
  /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(in|profile|pub|school|company)\/(?<title>[A-z0-9_-]+)\/?/
const LINKEDIN_POST_PATTERN =
  /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(feed|posts)\/(?<id>[A-z0-9_-]+)\/?.*$/

// Facebook
const FACEBOOK_POST_PATTERN =
  /^(http(s)?:\/\/)?(www\.)?(?<id>facebook|fb)\.com\/(photo.php|permalink.php|media|questions|notes)(\?)?.*$/i
const FACEBOOK_PROFILE_PATTERN =
  /^(http(s)?:\/\/)?(www\.)?(facebook|fb)\.com\/(?<title>[A-z0-9_-]+)\/?/i
const FACEBOOK_VIDEO_PATTERN =
  /^(http(s)?:\/\/)?(www\.)?(facebook|fb)\.com\/(?<title>[A-z0-9_]+)\/videos\/(?<id>[A-z 0-9 _ -]+)\/?.*$/i
const FACEBOOK_LINK_PATTERN = /^(http(s)?:\/\/)?(www\.)?facebook\.com?.*$/i

export const LINKEDIN_PATTERNS = [
  LINKEDIN_PROFILE_PATTERN,
  LINKEDIN_POST_PATTERN,
  LINKEDIN_DEEP_LINK_PATTERN
]

export const FACEBOOK_PATTERNS = [
  FACEBOOK_POST_PATTERN,
  FACEBOOK_PROFILE_PATTERN,
  FACEBOOK_VIDEO_PATTERN,
  FACEBOOK_LINK_PATTERN,
  FB_DEEP_LINK_PATTERN
]

export const YOUTUBE_PATTERNS = [
  /^(http(s)?:\/\/)?(www\.)?(m.youtube.com|youtube.com)\/watch\?v=(?<id>[A-z0-9_-]+)\/?.*$/i,
  /^(http(s)?:\/\/)?(www\.)?(youtu.be|yt.be)\/(?<id>[A-z0-9_-]+)\/?.*$/i,
  /^(http(s)?:\/\/)?(www\.)?(m.youtube.com|youtube.com)\/((user|channel\/)|@)?(?<id>[A-z0-9_-]+)\/?.*$/i
]

export const INSTAGRAM_PATTERNS = linkTypes
  .filter(linkType => linkType.provider === 'instagram')
  .map(linkType => linkType.pattern)

export const TIKTOK_PATTERNS = linkTypes
  .filter(linkType => linkType.provider === 'tiktok')
  .map(linkType => linkType.pattern)

export const TWITTER_PATTERNS = linkTypes
  .filter(linkType => linkType.provider === 'twitter')
  .map(linkType => linkType.pattern)

export const SNAPCHAT_PATTERNS = linkTypes
  .filter(linkType => linkType.provider === 'snapchat')
  .map(linkType => linkType.pattern)

export const SPOTIFY_PATTERNS = linkTypes
  .filter(linkType => linkType.provider === 'spotify')
  .map(linkType => linkType.pattern)

// flowpage
export const FLOWPAGE_SLUG_PATTERN = /^[A-Za-z0-9\-._]*$/
