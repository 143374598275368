import { IthacaJwt } from '@dtx-company/ithaca-sdk/src'
import { ParamsType } from '../constants/charts'

interface DataType {
  click?: number
  scans?: number
  visits?: number
}

export interface ApiConnectionArgs {
  params: ParamsType | URLSearchParams
  token: IthacaJwt | null
}

export interface AnalyticsClientApiConnectionArgs {
  params: ParamsType
  token: IthacaJwt | null
}

export interface SummaryDayType {
  click?: number
  scans?: number
  visit?: number
  data?: number
  percent?: number
  isPositive?: boolean
}

export interface SummaryType {
  product_id?: number
  owner_id?: string
  today?: SummaryDayType
  yesterday?: SummaryDayType
  this_week?: SummaryDayType
  last_week?: SummaryDayType
  this_month?: SummaryDayType
  last_month?: SummaryDayType
  this_year?: SummaryDayType
  last_year?: SummaryDayType
  all_time?: SummaryDayType
}

export interface SummaryOutputType {
  flowpage: SummaryType
  flowcode: SummaryType
  all: SummaryType
}

export interface TimeOfDayType {
  product_id: number
  owner_id: string
  [key: number]: DataType
}

export interface TimeOfDayOutputType {
  time: string
  flowcodeValue: number
  flowpageValue: number
}

export interface DayOfWeekType {
  product_id: number
  owner_id: string
  Monday: DataType
  Tuesday: DataType
  Wednesday: DataType
  Thursday: DataType
  Friday: DataType
  Saturday: DataType
  Sunday: DataType
}

export interface DeviceTypeType {
  product_id: number
  owner_id: string
  Apple: DataType
  Android: DataType
  Other: DataType
}

export interface LocationRecordType {
  postal_code: string
  city: string
  country: string
  visit: number
  click: number
  scans: number
}

export interface LocationType {
  product_id: number
  owner_id: string
  records: LocationRecordType[]
}
export interface LocationOutputType {
  flowcode: LocationRecordType[]
  flowpage: LocationRecordType[]
}

export interface DailyConnectionsType {
  start_time?: string
  end_time?: string
  visit?: number
  click?: number
  scans?: number
}

export interface PublicSectionsPropsType {
  summary: {
    flowcode: SummaryType | null
    flowpage: SummaryType | null
  }
  dailyConnections: {
    flowcode: DailyConnectionsType[] | null
    flowpage: DailyConnectionsType[] | null
  }
  displaySummary?: boolean
  displayDailyConnectionCount?: boolean
  displayHeading?: boolean
}

export interface ProSectionsPropsType {
  isGated?: boolean
  dayOfWeek: {
    flowcode: DayOfWeekType | null
    flowpage: DayOfWeekType | null
  }
  deviceType: {
    flowcode: DeviceTypeType | null
    flowpage: DeviceTypeType | null
  }
  timeOfDay: {
    flowcode: TimeOfDayType | null
    flowpage: TimeOfDayType | null
  }
}

interface TimePeriodsType {
  start: string
  end: string
}

export interface GetDatesReturnType {
  today: string
  yesterday: TimePeriodsType
  lastWeek: TimePeriodsType
  lastMonth: TimePeriodsType
  lastYear: TimePeriodsType
  last30days: TimePeriodsType
  last50days: TimePeriodsType
  last90days: TimePeriodsType
  last180days: TimePeriodsType
}

export enum HeapCodeType {
  SCRATCH = 'scratch',
  AUTO_DESIGN = 'auto_design',
  SAVED_DESIGN = 'saved_design',
  PREMADE_TEMPLATE = 'premate_template'
}
